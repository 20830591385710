import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  FormCheck,
  Container,
  InputGroup,
  Image,
} from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Routes } from '../../config/routes';
import { logo } from '../../assets/img';
import { CustomToast, Loader } from '../../components/common';
import { useLoginMutation } from '../../features/auth/auth.api';
import { useDispatch } from 'react-redux';
import { authAdded } from '../../features/auth/auth.slice';

export default () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [loginUser, { isLoading }] = useLoginMutation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const clearFields = () => {
    setEmail('');
    setPassword('');
  };

  async function handleLogin(e) {
    e.stopPropagation();
    e.preventDefault();

    if (email === '' || password === '') {
      toast.error('Please fill in all the fields');
    } else {
      await loginUser({ email, password })
        .unwrap()
        .then((payload) => {
          dispatch(authAdded(payload.user));
          localStorage.setItem('accessToken', payload.tokens.access.token);
          localStorage.setItem('refreshToken', payload.tokens.refresh.token);
          localStorage.setItem('userId', payload.user.id);
          clearFields();
          history.push(Routes.Home.path);
        });
    }
  }

  return (
    <main className='bg-white'>
      <CustomToast />
      <Loader loaded={!isLoading}>
        <section className='d-flex align-items-center my-5 mt-lg-3 mb-lg-5'>
          <Container>
            <Row
              noGutters
              className='justify-content-between align-items-center'
            >
              <Col
                xs={12}
                className='bg-white headerImageSignUp'
              >
                <Image
                  src={logo}
                  alt='logo'
                  className='bg-white'
                  rounded
                />
              </Col>
            </Row>
            <Row className='justify-content-between align-items-center'>
              <Col
                xs={12}
                md={6}
                className='mb-xs-4'
              >
                <Card>
                  <Card.Body>
                    <Card.Text className='text-black h6 text-justify'>
                      The Mungo is figurative to the river that brings together
                      Cameroon's Anglophone and Francophone regions. <br />
                      <br /> It is a citizen journalism framework that
                      crowdsources and mapps conflict incidents, mainly in the
                      North West and South West regions, for rapid response.
                      <br />
                      <br /> Moreover, we publish bi-weekly situational analysis
                      reports highlighting Cameroon's state of affairs and are
                      intended to give an early warning for relevant, effective
                      responses. For you as our partner, we would like to
                      foresee these reports helping you plan and inform your
                      community activities. <br />
                      <br />
                      We'd like to invite you to sign up, and we'll email you
                      the status of your request; if your request is
                      successfully approved, you can access The Mungo.
                      <br />
                      <br /> Feel free to give us feedback; we would appreciate
                      it!
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col
                xs={12}
                md={6}
                className='d-flex align-items-center justify-content-center'
              >
                <div className='bg-tertiary shadow-soft border rounded border-light px-4 py-2 py-lg-4 px-lg-5 w-100'>
                  <div className='text-center mb-4 mt-md-0'>
                    <h3 className='mb-0'>Sign in to our platform</h3>
                  </div>
                  <Form className='mt-4'>
                    <Form.Group
                      id='email'
                      className='mb-4'
                    >
                      <Form.Label>Your Email</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faEnvelope} />
                        </InputGroup.Text>
                        <Form.Control
                          autoFocus
                          required
                          type='email'
                          placeholder='example@company.com'
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Group
                        id='password'
                        className='mb-4'
                      >
                        <Form.Label>Your Password</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faUnlockAlt} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type='password'
                            placeholder='Password'
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </InputGroup>
                      </Form.Group>
                      <div className='d-flex justify-content-between align-items-center mb-4'>
                        <Form.Check type='checkbox'>
                          <FormCheck.Input
                            id='defaultCheck5'
                            className='me-2'
                          />
                          <FormCheck.Label
                            htmlFor='defaultCheck5'
                            className='mb-0'
                          >
                            Remember me
                          </FormCheck.Label>
                        </Form.Check>
                        <Card.Link
                          as={Link}
                          to={Routes.ForgotPassword.path}
                          className='small text-end'
                        >
                          Lost password?
                        </Card.Link>
                      </div>
                    </Form.Group>
                    <Button
                      variant='primary'
                      type='submit'
                      className='w-100'
                      onClick={handleLogin}
                    >
                      Sign in
                    </Button>
                  </Form>

                  <div className='d-flex justify-content-center align-items-center mt-4'>
                    <span className='fw-normal'>
                      Not registered?
                      <Card.Link
                        as={Link}
                        to={Routes.Signup.path}
                        className='fw-bold'
                      >
                        {` Create account `}
                      </Card.Link>
                    </span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Loader>
    </main>
  );
};
