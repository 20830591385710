import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Modal,
  InputGroup,
} from '@themesberg/react-bootstrap';
import { useSelector } from 'react-redux';
import { CustomToast, Loader } from '../../components/common';
import { Routes } from '../../config/routes';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../utils/reusableFunctions';
import {
  useGetUsersQuery,
  SelectAdvancedUsers,
} from '../../features/users/users.api';
import {
  useDeleteReportMutation,
  useGetSingleReportQuery,
  useUpdateReportMutation,
} from '../../features/reports/reports.api';

const EditReport = () => {
  const { id } = useParams();
  let history = useHistory();
  const { data: report } = useGetSingleReportQuery({ id });
  const [updateReport, { isLoading: updating }] = useUpdateReportMutation();
  const [deleteReport, { isLoading: deleting }] = useDeleteReportMutation();
  const { users } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => ({
      users: data ? data.results : [],
    }),
  });
  const authors = useSelector(SelectAdvancedUsers);

  const [title, setTitle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [featuredImage, setFeaturedImage] = useState(null);
  const [datePublished, setDatePublished] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleUpdateReport(e) {
    e.stopPropagation();
    e.preventDefault();

    const body = {
      title: title ?? report.title,
      author: author ?? report.author === null ? 'unknown' : report.author.id,
      pdf: pdf ?? report.pdf,
      featuredImage: featuredImage ?? report.featuredImage,
      datePublished: datePublished ?? report.datePublished,
    };

    await updateReport({ id, body });
  }

  async function handleDeleteReport() {
    handleClose();
    history.push(Routes.ReportsTable.path);
    await deleteReport({ id });
  }

  const CheckDelete = () => (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you want to delete {report.title}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deleting a report is an irreversible process. You cannot recover the
        information once a report is deleted
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          variant='secondary'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={handleDeleteReport}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (!report || updating || deleting || !users.length || !authors.length)
    return <Loader />;

  return (
    <>
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <CustomToast />
        <CheckDelete />
        <Card.Body>
          <Form>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='title'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter the report title'
                    defaultValue={report.title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='author'>
                  <Form.Label>Author</Form.Label>
                  <Form.Select
                    defaultValue={
                      report.author === null ? 'unknown' : report.author.id
                    }
                    onChange={(e) => setAuthor(e.target.value)}
                  >
                    {authors.map((author) => (
                      <option value={author.id}>{author.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center mb-3'>
              <Col md={6}>
                <Form.Group id='datePublished'>
                  <Form.Label>Date Published</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={setDatePublished}
                    initialValue={formatDate(report.datePublished, '/')}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          type='text'
                          value={formatDate(datePublished, '/')}
                          placeholder='mm/dd/yyyy'
                          onFocus={openCalendar}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group id='pdf'>
                  <Form.Label>PDF Link</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='www.reportone.com'
                    defaultValue={report.pdf}
                    onChange={(e) => setPdf(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center mb-3'>
              <Form.Group id='pdf'>
                <Form.Label>Featured Image Link</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='www.featuredImage.com'
                  defaultValue={report.featuredImage}
                  onChange={(e) => setFeaturedImage(e.target.value)}
                />
              </Form.Group>
            </Row>
            <Row className='align-items-center justify-content-between'>
              <Col className='text-start'>
                <Button
                  className='mt-3 me-2'
                  size='sm'
                  variant='danger'
                  type='button'
                  onClick={handleShow}
                >
                  Delete Report
                </Button>
              </Col>
              <Col className='ps-md-0 text-end'>
                <Button
                  className='mt-3 me-2'
                  size='sm'
                  variant='primary'
                  type='submit'
                  onClick={handleUpdateReport}
                >
                  Update Report
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditReport;
