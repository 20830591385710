import { api } from '../../app';

const apiWithSummaryTags = api.enhanceEndpoints({ addTagTypes: ['Summary'] });

const summaryApi = apiWithSummaryTags.injectEndpoints({
  endpoints: (builder) => ({
    createSummary: builder.mutation({
      query: (body) => ({
        url: 'summary',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Summary'],
    }),
    getSummaries: builder.query({
      query: (params) => ({
        url: 'summary',
        method: 'GET',
        params,
      }),
      providesTags: (data) =>
        data && data.results
          ? [
              ...data.results.map(({ id }) => ({ type: 'Summary', id })),
              { type: 'Summary', id: 'PARTIAL-SUMMARY-LIST' },
            ]
          : [{ type: 'Summary', id: 'PARTIAL-SUMMARY-LIST' }],
    }),
    getSingleSummary: builder.query({
      query: ({ id }) => ({
        url: `summary/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'Summary', id: result.id }] : ['Summary'],
    }),
    getSummaryByDate: builder.query({
      query: ({ year, month }) => ({
        url: `group/${year}/${month}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'Summary', id: result.id }] : ['Summary'],
    }),
    updateSummary: builder.mutation({
      query: ({ id, body }) => ({
        url: `summary/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Summary', id: arg.id },
        { type: 'Summary', id: 'PARTIAL-SUMMARY-LIST' },
      ],
    }),
    deleteSummary: builder.mutation({
      query: ({ id }) => ({
        url: `summary/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Summary', id: arg.id },
        { type: 'Summary', id: 'PARTIAL-SUMMARY-LIST' },
      ],
    }),
  }),
});

export const {
  useCreateSummaryMutation,
  useDeleteSummaryMutation,
  useGetSummariesQuery,
  useGetSingleSummaryQuery,
  useUpdateSummaryMutation,
  useLazyGetSummariesQuery,
  useGetSummaryByDateQuery,
} = summaryApi;
export default summaryApi;
