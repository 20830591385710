import { Col, Row } from '@themesberg/react-bootstrap';
import React, { useEffect } from 'react';
import { Layout } from '../components/layout';
import { MapWithFilters, MapSideBar } from '../components/incidents';
import { useDispatch } from 'react-redux';
import { categoryFilterChanged } from '../features/incidents/incidents.filter.slice';
import {
  initialDateFilterChanged,
  finalDateFilterChanged,
} from '../features/incidents/incidents.dateFilters.slice';
import { subtractMonths } from '../utils/reusableFunctions';

const Map = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const now = Date.now();
    dispatch(categoryFilterChanged('All'));
    dispatch(initialDateFilterChanged(subtractMonths(now, 6)));
    dispatch(finalDateFilterChanged(now));
  }, [dispatch]);

  return (
    <Layout>
      <Row
        className='bg-white w-100 p-0 m-0'
        noGutters
      >
        <Col
          lg={8}
          className='p-0'
        >
          <MapWithFilters />
        </Col>
        <Col
          lg={4}
          className='p-0'
        >
          <MapSideBar />
        </Col>
      </Row>
    </Layout>
  );
};

export default Map;
