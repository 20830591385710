import React from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import { useParams } from 'react-router-dom';
import { Layout } from '../../components/layout';
import { ReportList } from '../../components/reports';
import { PDFViewer } from '../../components/PDF';
import { useGetSingleReportQuery } from '../../features/reports/reports.api';
import { Loader } from '../../components/common';

const SingleReport = () => {
  const { id } = useParams();
  const { data: report } = useGetSingleReportQuery({ id });

  return (
    <Loader loaded={report}>
      <Layout>
        <Row
          noGutters
          className='bg-white'
        >
          <Col
            xs={12}
            md={8}
            className='px-0'
          >
            <PDFViewer report={report} />
          </Col>
          <Col
            xs={12}
            md={4}
            className='px-0'
          >
            <ReportList />
          </Col>
        </Row>
      </Layout>
    </Loader>
  );
};

export default SingleReport;
