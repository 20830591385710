import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Modal,
} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import {
  ChoosePhotoWidget,
  CustomToast,
  Loader,
} from '../../components/common';
import { avatar } from '../../assets/img';
import { Routes } from '../../config/routes';
import {
  useApproveUserMutation,
  useDeleteUserMutation,
  useGetSingleUserQuery,
  useUpdateUserMutation,
  useBlacklistUserMutation,
  useChangeUserRoleMutation,
} from '../../features/users/users.api';

const EditUser = () => {
  const { id } = useParams();
  let history = useHistory();
  const { data: user } = useGetSingleUserQuery({ id });
  const [updateUser, { isLoading: updating }] = useUpdateUserMutation();
  const [deleteUser, { isLoading: deleting }] = useDeleteUserMutation();
  const [approveUser, { isLoading: approving }] = useApproveUserMutation();
  const [blacklistUser, { isLoading: blacklisting }] =
    useBlacklistUserMutation();
  const [changeUserRole, { isLoading: changingRole }] =
    useChangeUserRoleMutation();

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [password, setPassword] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleUserUpdate(e) {
    e.stopPropagation();
    e.preventDefault();

    const body = {
      name: name ?? user.name,
      email: email ?? user.email,
      ...(password && {
        password,
      }),
    };

    await updateUser({ id, body });
  }

  async function handleApproveUser() {
    await approveUser({ id });
  }

  async function handleBlacklistUser() {
    await blacklistUser({ id });
  }

  async function handleDeleteUser() {
    handleClose();
    history.push(Routes.UsersTable.path);
    await deleteUser({ id });
  }

  async function handleChangeRole(e) {
    e.stopPropagation();
    e.preventDefault();

    const body = {
      role: role ?? user.role,
    };

    await changeUserRole({ id, body });
  }

  const CheckDelete = () => (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Are you sure you want to delete {user.name}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deleting a user is an irreversible process. You cannot recover their
        information once a user is deleted
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          variant='secondary'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={handleDeleteUser}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (
    !user ||
    updating ||
    deleting ||
    approving ||
    blacklisting ||
    changingRole
  )
    return <Loader />;

  return (
    <Card
      border='light'
      className='bg-white shadow-sm mb-4'
    >
      <CustomToast />
      <CheckDelete />
      <Card.Body>
        <Card.Text className='text-gray mb-4'>
          {user.email}{' '}
          {user.approved && (
            <FontAwesomeIcon
              icon={faCheck}
              className='me-1 text-success'
            />
          )}
        </Card.Text>
        <Form>
          <Row className='align-items-center'>
            <Col
              md={6}
              className='mb-3'
            >
              <ChoosePhotoWidget
                title='Select profile photo'
                photo={user.avatar ? user.avatar : avatar}
              />
            </Col>
            <Col
              md={6}
              className='mb-3'
            >
              <Form.Group id='firstName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter your name'
                  defaultValue={user.name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col
              md={6}
              className='mb-3'
            >
              <Form.Group id='email'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='name@company.com'
                  defaultValue={user.email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col
              md={6}
              className='mb-3'
            >
              <Form.Group id='email'>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Password'
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className='mt-3'>
            <Button
              variant='primary'
              type='submit'
              onClick={handleUserUpdate}
            >
              Update User Details
            </Button>
          </div>
        </Form>
        <h2 className='text-primary my-3 text-center fw-bold'>
          Admin Operations
        </h2>
        <Form>
          <Row className='align-items-end justify-content-center'>
            <Col
              md={6}
              className='mb-3'
            >
              <Form.Group id='role'>
                <Form.Label>Role</Form.Label>
                <Form.Select
                  defaultValue={user.role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value='user'>User</option>
                  <option value='author'>Author</option>
                  <option value='editor'>Editor</option>
                  <option value='admin'>Admin</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col
              md={6}
              className='mb-3 text-end'
            >
              <Button
                variant='primary'
                onClick={handleChangeRole}
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className='me-1'
                />{' '}
                Change Role
              </Button>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-between mt-3'>
            <Col
              md={4}
              className='mb-3'
            >
              <h4 className={'h6 text-black'}>
                User Approval Status:{' '}
                <strong
                  className={`text-${
                    user.approved === false ? 'danger' : 'success'
                  }`}
                >
                  {user.approved.toString()}
                </strong>
              </h4>
            </Col>
            <Col
              md={4}
              className='mb-3 text-center'
            >
              <Button
                variant='primary'
                onClick={handleApproveUser}
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className='me-1'
                />{' '}
                Approve User
              </Button>
            </Col>
            <Col
              md={4}
              className='mb-3 text-right'
            >
              <Button
                variant='warning'
                onClick={handleBlacklistUser}
              >
                <FontAwesomeIcon
                  icon={faUser}
                  className='me-1'
                />{' '}
                Blacklist User
              </Button>
            </Col>
          </Row>
          <div className='mt-3'>
            <Button
              variant='danger'
              type='button'
              onClick={handleShow}
            >
              Delete User
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditUser;
