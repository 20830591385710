import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
  Modal,
} from '@themesberg/react-bootstrap';
import { useSelector } from 'react-redux';
import { CustomToast, Loader } from '../../components/common';
import { Routes } from '../../config/routes';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../utils/reusableFunctions';
import {
  useDeleteIncidentMutation,
  useGetSingleIncidentQuery,
  useUpdateIncidentMutation,
  useUpdatePublicationMutation,
} from '../../features/incidents/incidents.api';
import {
  useGetUsersQuery,
  SelectAdvancedUsers,
} from '../../features/users/users.api';
import { useGetCategoriesQuery } from '../../features/categories/categories.api';

const EditIncident = () => {
  const { id } = useParams();
  let history = useHistory();
  const [updateIncident, { isLoading: updating }] = useUpdateIncidentMutation();
  const [publishIncident, { isLoading: publishing }] =
    useUpdatePublicationMutation();
  const [deleteIncident, { isLoading: deleting }] = useDeleteIncidentMutation();

  const { data: incident } = useGetSingleIncidentQuery({ id });
  const { users } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => ({
      users: data ? data.results : [],
    }),
  });
  const authors = useSelector(SelectAdvancedUsers);
  const { categories } = useGetCategoriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      categories: data ? data.results : [],
    }),
  });

  const [title, setTitle] = useState(null);
  const [author, setAuthor] = useState(null);
  const [description, setDescription] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [links, setLinks] = useState(null);
  const [category, setCategory] = useState(null);
  const [incidentDate, setIncidentDate] = useState(null);
  const [locationName, setLocationName] = useState(null);
  const [deaths, setDeaths] = useState(null);
  const [wounded, setWounded] = useState(null);
  const [peopleInvolved, setPeopleInvolved] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleUpdateIncident(e) {
    e.stopPropagation();
    e.preventDefault();

    const body = {
      title: title ?? incident.title,
      author: author ?? incident.author.id,
      description: description ?? incident.description,
      location: {
        type: 'Point',
        coordinates: [
          longitude ?? incident.location.coordinates[0],
          latitude ?? incident.location.coordinates[1],
        ],
      },
      links: links ?? incident.links,
      category: category ?? incident.category.id,
      incidentDate: incidentDate ?? incident.incidentDate,
      locationName: locationName ?? incident.locationName,
      deaths: deaths ?? incident.deaths,
      wounded: wounded ?? incident.wounded,
      peopleInvolved: peopleInvolved ?? incident.peopleInvolved,
    };

    await updateIncident({ id, body });
  }

  async function changePublicationStatus() {
    await publishIncident({ id });
  }

  async function handleDeleteIncident() {
    handleClose();
    history.push(Routes.IncidentsTable.path);
    await deleteIncident({ id });
  }

  const CheckDelete = () => (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you want to delete {incident.title}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deleting an incident is an irreversible process. You cannot recover the
        information once an incident is deleted
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          variant='secondary'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={handleDeleteIncident}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (
    !incident ||
    updating ||
    deleting ||
    publishing ||
    !users.length ||
    !categories.length ||
    !authors.length
  )
    return <Loader />;

  return (
    <>
      <CustomToast />
      <CheckDelete />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Header>
          <Row className='justify-content-between align-items-center'>
            <Col className='ps-md-0 text-end'>
              <Button
                variant='primary'
                size='sm'
                className='me-2'
                onClick={changePublicationStatus}
              >
                {incident.isPublished ? 'Unpublish' : 'Publish'}
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <h5 className='mb-4'>Edit Incident</h5>
          <Form>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='title'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={incident.title}
                    placeholder='Enter the incident title'
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='author'>
                  <Form.Label>Author</Form.Label>
                  <Form.Select
                    defaultValue={incident.author.id}
                    onChange={(e) => setAuthor(e.target.value)}
                  >
                    {authors.map((author) => (
                      <option value={author.id}>{author.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center'>
              <Col className='mb-3'>
                <Form.Group id='description'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as='textarea'
                    defaultValue={incident.description}
                    placeholder='Describe the incident'
                    style={{ height: '20vh' }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center mb-3'>
              <Form.Group id='pdf'>
                <Form.Label>Links</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue={incident.links.join(', ')}
                  placeholder='www.incidentOne.com'
                  onChange={(e) => setLinks(e.target.value.split(','))}
                />
              </Form.Group>
            </Row>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='incidentDate'>
                  <Form.Label>Incident Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={setIncidentDate}
                    initialValue={formatDate(incident.incidentDate, '/')}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          type='text'
                          value={formatDate(incidentDate ?? incident.incidentDate, '/')}
                          placeholder='mm/dd/yyyy'
                          onFocus={openCalendar}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='category'>
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    defaultValue={incident.category.id}
                    onChange={(e) => setCategory(e.target.value)}
                  >
                    {categories.map((cat) => (
                      <option value={cat.id}>{cat.title}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <h2 className='text-primary text-center h6'>Casualties</h2>
            <Row className='align-items-center'>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='wounded'>
                  <Form.Label>Wounded</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={incident.wounded}
                    placeholder='Number of wounded casualties'
                    onChange={(e) => setWounded(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='deaths'>
                  <Form.Label>Deaths</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={incident.deaths}
                    placeholder='Number of dead casualties'
                    onChange={(e) => setDeaths(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='peopleInvolved'>
                  <Form.Label>People Involved</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={incident.peopleInvolved || 0}
                    placeholder='Number of dead casualties'
                    onChange={(e) => setPeopleInvolved(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <h2 className='text-primary text-center h6'>Location</h2>
            <Row>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='longitude'>
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={incident.location.coordinates[0]}
                    placeholder='Incident location longitude'
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='latitude'>
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={incident.location.coordinates[1]}
                    placeholder='Incident location latitude'
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='locationName'>
                  <Form.Label>Location Name</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={incident.locationName}
                    placeholder='Name of the location where incident happened'
                    onChange={(e) => setLocationName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row className='align-items-center justify-content-between'>
            <Col className='text-start'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='danger'
                type='button'
                onClick={handleShow}
              >
                Delete Incident
              </Button>
            </Col>
            <Col className='ps-md-0 text-end'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='primary'
                type='submit'
                onClick={handleUpdateIncident}
              >
                Update Incident
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </>
  );
};

export default EditIncident;
