import React from 'react';
import { Col, Container, ListGroup, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { getWords } from '../../utils/reusableFunctions';
import { Loader } from '../common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config/routes';
import { useGetIncidentsQuery } from '../../features/incidents/incidents.api';

const SmallIncidentList = ({ length = 5 }) => {
  const { incidents } = useGetIncidentsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      incidents: data ? data.results : [],
    }),
  });
  const incidentList = incidents.slice(0, length);

  const Incident = (props) => {
    const { title, description, id } = props;

    return (
      <ListGroup.Item className='px-0 bg-tertiary'>
        <Container>
          <Col className='col-auto'>
            <h4 className='h6 mb-0 text-primary'>
              <FontAwesomeIcon
                icon={faShieldAlt}
                className='me-2 text-secondary'
              />
              <Link to={`${Routes.Incidents.path}/${id}`}>
                {getWords({ str: title, num: 5 })}
              </Link>
            </h4>
            <small className='text-black'>
              {getWords({ str: description, num: 5 })}...
            </small>
            <br></br>
          </Col>
        </Container>
      </ListGroup.Item>
    );
  };

  return (
    <Loader loaded={!!incidents.length}>
      <Card
        border='light'
        className='shadow-sm'
      >
        <Card.Header className='border-bottom d-flex justify-content-between bg-secondary text-black'>
          <h4 className='h6 mb-0'>Recent Incidents</h4>
        </Card.Header>
        <Card.Body className='bg-tertiary'>
          <ListGroup className='list-group-flush list my--3'>
            {incidentList.map((i) => (
              <Incident
                key={i.id}
                {...i}
              />
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </Loader>
  );
};

export default SmallIncidentList;
