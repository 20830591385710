import axios from 'axios';
import config from '../config/config';

export const API_URL = config.ApiKey;

export const cancelTokenSource = axios.CancelToken.source()
class Api {
    constructor() {
        this.instance =  axios.create({
            baseURL: API_URL,
            timeout: 9000,
            cancelToken: cancelTokenSource.token
        })

        // Also Check whether there's a token before sending a request
        this.instance.interceptors.request.use(request => {
            if (request.url.includes('auth') || request.url.includes('sentiment')) {
                return request; 
            } else if (this.getToken()) {
                request.headers['Authorization'] = `Bearer ${this.getToken()}`
                return request;
            } else {
                cancelTokenSource.cancel();
            }
        }, error => {
            return Promise.reject(error)
        })

        //Intercepting the response, if its okay then do nothing but if there's a 401
        //then resend it.
        this.instance.interceptors.response.use(response => {
            return response
        }, err => {
            return new Promise((resolve, reject) => {
                const originalReq = err.config;
                if (err.response !== undefined) {
                    //To stop the refresh token from being sent when logging in
                    if (this.getRefreshToken() != null) {
                        if (err.response.status === 401 && err.config) {
                            let res = fetch(`${API_URL}auth/refresh-tokens`, {
                                method: 'POST',
                                mode: 'cors',
                                cache: 'no-cache',
                                credentials: 'same-origin',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Token': `${this.getToken()}`
                                },
                                redirect: 'follow',
                                referrer: 'no-referrer',
                                body: JSON.stringify({
                                    refreshToken: `${this.getRefreshToken()}`
                                }),
                            }).then(res => res.json())
                            .then(res => {
                                localStorage.setItem('jwtToken', res.access.token)
                                localStorage.setItem('refreshToken', res.refresh.token)

                                originalReq.headers['Authorization'] = `Bearer ${res.access.token}`

                                return axios(originalReq)
                            })
                            resolve(res)
                            //If the response is 404 or 400 then just return the error
                            //It will be displayed in a pop up notification
                        } else if(err.response.status === 404 || err.response.status === 400) {
                            reject(err)
                        }
                    } else {
                        reject(err)
                    }
            }
                return Promise.reject(err, "Interceptor function Error!!")
            })
        })
    }

    getToken() {
        return localStorage.getItem('jwtToken')
    }

    getRefreshToken() {
        return localStorage.getItem('refreshToken')
    }

    getParam(query) {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(query)
    }

    getUrl(lengthBeforeToken) {
        const urlHash = window.location.hash
        let params = urlHash.substr(lengthBeforeToken).split('&').reduce(function (res, item) {
            var parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});
        return params.token
    }

    auth() {
        return {
            login: (data) => this.instance.post(`auth/login/`, data),
            logout: (data) => this.instance.post(`auth/logout/`, data),
            registerUser: (data) => this.instance.post(`auth/register/`, data),
            forgotPassword: (data) => this.instance.post(`auth/forgot-password/`, data),
            resetPassword: (data) => this.instance({
                method: 'POST',
                url: 'auth/reset-password/',
                params: {
                    token: `${this.getUrl(17)}`
                },
                data,
            }),
            verifyEmail: () => this.instance({
                method: 'POST',
                url: 'auth/verify-email/',
                params: {
                    token: `${this.getUrl(15)}`
                },
            }),
        }
    }

    VerificationEmail() {
        if (this.getToken()){
            return {
                send: () => this.instance.post(`/auth/send-verification-email`),
            }
        }
    }

    User() {
        if (this.getToken()) {
            return {
                getUser: (id) => this.instance.get(`users/${id}`),
                getAllUsers: () => this.instance({
                    method: 'GET',
                    url: 'users/',
                }),
                createUser: (data) => this.instance.post(`users/`, data),
                updateUser: (id, data) => this.instance.patch(`users/${id}`, data),
                deleteUser: (id) => this.instance.delete(`users/${id}`),
                approveUser: (id) => this.instance.post(`approve/${id}`),
                changeRole: (id, data) => this.instance.put(`approve/${id}`, data),
                blacklist: (id) => this.instance.patch(`approve/${id}`),
            }
        }
    }

    Categories(){
        if (this.getToken()) {
            return {
                createCategory: (data) => this.instance.post(`categories/`, data),
                getAllCategories: () => this.instance({
                    method: 'GET',
                    url: 'categories/',
                }),
                getCategory: (id) => this.instance.get(`categories/${id}`),
                updateCategory: (id, data) => this.instance.patch(`categories/${id}`, data),
                deleteCategory: (id) => this.instance.delete(`categories/${id}`),
            }
        }
    }

    Incidents() {
        if (this.getToken()) {
            return {
                createIncident: (data) => this.instance.post(`incidents/`, data),
                getAllIncidents: () => this.instance({
                    method: 'GET',
                    url: 'incidents/',
                    params: {
                        populate: 'author category',
                        sortBy: '_id:desc'
                    }
                }),
                getIncident: (id) => this.instance.get(`incidents/${id}`),
                updateIncident: (id, data) => this.instance.patch(`incidents/${id}`, data),
                deleteIncident: (id) => this.instance.delete(`incidents/${id}`),
                updatePublication: (id) => this.instance.patch(`publish/${id}`),
            }
        }
    }

    Reports() {
        if (this.getToken()) {
            return {
                createReport: (data) => this.instance.post(`reports/`, data),
                getAllReports: () => this.instance({
                    method: 'GET',
                    url: 'reports/',
                    params: {
                        populate: 'author',
                        sortBy: 'datePublished:desc'
                    }
                }),
                getReport: (id) => this.instance.get(`reports/${id}`),
                updateReport: (id, data) => this.instance.patch(`reports/${id}`, data),
                deleteReport: (id) => this.instance.delete(`reports/${id}`),
            }
        }
    }

    Regions() {
        if (this.getToken()) {
            return {
                createRegion: (data) => this.instance.post(`regions/`, data),
                getAllRegions: () => this.instance({
                    method: 'GET',
                    url: 'regions/',
                    params: {
                        type: 'Feature',
                    },
                }),
                getRegion: (id) => this.instance.get(`regions/${id}`),
                updateRegion: (id, data) => this.instance.patch(`regions/${id}`, data),
                deleteRegion: (id) => this.instance.delete(`regions/${id}`),
            }
        }
    }

    Tilesets() {
        if (this.getToken()) {
            return {
                updateTilesetSource: () => this.instance.post(`tilesets/`),
                createTileset: (id, data) => this.instance.post(`tilesets/${id}`, data),
                updateRecipe: (id, data) => this.instance.patch(`tilesets/${id}`, data),
                publishTileset: (id) => this.instance.put(`tilesets/${id}`),
            }
        }
    }

    Sentiment() {
        return {
            analyze: (words) => this.instance.get(`sentiment?words=${words}`),
            getWordFrequencies: (params) => this.instance({
                method: 'GET',
                url: 'search/',
                params: {
                    sortBy: 'frequency:desc',
                    ...params,
                },
            })
        }
    }
}

export default Api;
