import React, { useEffect } from 'react';
import Loader from '../common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
  SelectFlattenedIncidentsByDateRange,
  SelectIncidentsByDateRange,
  useLazyGetIncidentsQuery,
} from '../../features/incidents/incidents.api';
import {
  selectFinalDate,
  selectInitialDate,
  initialDateFilterChanged,
  finalDateFilterChanged,
} from '../../features/incidents/incidents.dateFilters.slice';
import {
  generateChartData,
  subtractMonths,
} from '../../utils/reusableFunctions';
import IncidentChart from './IncidentChart';
import { Container } from '@themesberg/react-bootstrap';
import {
  selectCategory,
  categoryFilterChanged,
} from '../../features/incidents/incidents.filter.slice';

const LineChart = () => {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const category = useSelector(selectCategory);
  const [getIncidents] = useLazyGetIncidentsQuery();
  const initialDate = useSelector(selectInitialDate);
  const finalDate = useSelector(selectFinalDate);
  const filteredIncidents = useSelector(
    category === 'All'
      ? SelectIncidentsByDateRange
      : SelectFlattenedIncidentsByDateRange
  );
  const chartData = generateChartData(
    filteredIncidents,
    'category',
    initialDate,
    finalDate
  );

  useEffect(() => {
    const now = Date.now();
    dispatch(categoryFilterChanged('All'));
    dispatch(initialDateFilterChanged(subtractMonths(now, 6)));
    dispatch(finalDateFilterChanged(now));
  }, [dispatch]);

  useEffect(() => {
    if (filteredIncidents.length === 0) getIncidents();
  }, [filteredIncidents.length, getIncidents]);

  return (
    <Loader loaded={!!filteredIncidents.length}>
      <Container className='bg-white'>
        <IncidentChart data={chartData} />
        <h4 className='h6 text-left text-center mb-3 text-black fs-italic'>
          Months for the year {currentYear}
        </h4>
      </Container>
    </Loader>
  );
};

export default LineChart;
