import React from 'react';
import { Col, Container, Row } from '@themesberg/react-bootstrap';
import { chartMetaData, ActiveChartWithLabel } from '.';

const Chartlist = ({ data, hideActiveShape }) => {
  const metaKeys = Object.keys(chartMetaData);
  return (
    <Container
      className='mx-2'
      style={{ minHeight: '100vh' }}
    >
      {metaKeys.map((key, index) => (
        <div
          key={index}
          className='text-black'
        >
          <h5 className='mb-3 text-center'>{chartMetaData[key].label}</h5>
          <Row className='align-items-center justify-content-between'>
            <Col
              xs={12}
              md={6}
            >
              <ActiveChartWithLabel
                data={data[key].stats}
                chartId={`${chartMetaData[key].id}`}
                label={chartMetaData[key].label}
                inverseLikert={key === 'believability' || key === 'factualness'}
                noLabel={true}
                hideActiveShape={hideActiveShape}
                width={600}
                height={400}
                innerRadius={60}
                outerRadius={120}
              />
            </Col>
            <Col
              xs={12}
              md={6}
            >
              <p>{data[key].description}</p>
            </Col>
          </Row>
        </div>
      ))}
    </Container>
  );
};

export default Chartlist;
