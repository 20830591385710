import React, { useState } from 'react';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Container, Button, Col } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../components/common';
import { Routes } from '../../config';
import moment from 'moment';
import { useGetSummaryByDateQuery } from '../../features/summary/summary.api';
import { ChartlistWithInfo } from '../../components/summaryCharts';

const Summary = () => {
  const now = new Date();
  const [month, setMonth] = useState(now.getMonth());
  const [year, setYear] = useState(now.getFullYear());
  const [showPDF, setShowPDF] = useState(false);
  const [hideActiveShape, setHideActiveShape] = useState(false);
  const { data, isLoading } = useGetSummaryByDateQuery({ year, month });

  console.log(data);

  if (!data && isLoading) return <Loader />;
  return (
    <Container
      className='mx-2 bg-white p-4 text-black'
      style={{ minHeight: '100vh' }}
    >
      <Row className='my-4 align-items-center justify-content-between'>
        <Col>
          <Datetime
            dateFormat='YYYY-MM'
            value={moment({ year, month })}
            timeFormat={false}
            onChange={(date) => {
              setMonth(date.month());
              setYear(date.year());
            }}
            renderInput={(props, openCalendar, closeCalendar) => (
              <Button
                onClick={openCalendar}
                className='primary'
                size='sm'
              >
                {`Click to select month and year. Current results are for: ${moment(
                  { year, month }
                ).format('MMMM YYYY')}`}
              </Button>
            )}
          />
        </Col>
        <Col className='text-end'>
          <Button
            variant='primary'
            size='sm'
            className=''
            as={Link}
            to={Routes.CreateSummary.path}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className='me-2'
            />
            Create Report
          </Button>
        </Col>
      </Row>
      {!data || data.type.count === 0 ? (
        <p className='text-black'>
          Sorry. There is no monitoring report for{' '}
          {moment({ year, month }).format('MMMM YYYY')}
        </p>
      ) : (
        <div className='my-4'>
          <h2 className='mt-5 h4 text-center'>
            Summary Statistics for {moment({ year, month }).format('MMMM YYYY')}
          </h2>
          <Row className='my-4 justify-content-between align-items-center'>
            <Col className='text-start'>
              <Button
                size='sm'
                onClick={() => setShowPDF((prev) => !prev)}
              >
                {showPDF ? 'Show Charts' : 'Show PDF'}
              </Button>
            </Col>
            <Col className='text-center'>
              <Button
                variant='primary'
                size='sm'
                onClick={() => setHideActiveShape((prev) => !prev)}
              >
                Toggle Active Chart
              </Button>
            </Col>
            <Col className='ps-md-0 text-end'>
              <Button
                size='sm'
                as={Link}
                to={`${Routes.Summary.path}/${data.id}`}
              >
                Edit
                <FontAwesomeIcon
                  icon={faEdit}
                  className='ms-2'
                />
              </Button>
            </Col>
          </Row>
          {showPDF ? (
            <Row className='my-4'>
              {data.pdf ? (
                <iframe
                  src={data.pdf}
                  title={`Summary Statistics for ${moment({
                    year,
                    month,
                  }).format('MMMM YYYY')}`}
                  style={{ width: '100%', height: '1000px' }}
                ></iframe>
              ) : (
                <p>
                  There is no pdf file for{' '}
                  {moment({ year, month }).format('MMMM YYYY')}
                </p>
              )}
            </Row>
          ) : (
            <ChartlistWithInfo
              data={data}
              hideActiveShape={hideActiveShape}
            />
          )}
        </div>
      )}
    </Container>
  );
};

export default Summary;
