import {
  createSlice,
  createSelector,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  search,
  sanitizeFilters,
  filterByEnum,
} from '../../utils/reusableFunctions';
import Api from '../../utils/network';

const api = new Api();

export const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState({
  loading: false,
  loaded: false,
});

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const res = await api.User().getAllUsers();
  return res.data.results;
});

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    userAdded: usersAdapter.addOne,
    userDeleted: usersAdapter.removeOne,
    userUpdated: usersAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        usersAdapter.setAll(state, action.payload);
        state.loading = false;
        state.loaded = true;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { userAdded, userDeleted, userUpdated } = userSlice.actions;

export default userSlice.reducer;

export const { selectAll: selectUsers, selectById: selectUserById } =
  usersAdapter.getSelectors((state) => state.users);

export const SelectFilteredUsers = createSelector(
  selectUsers,
  (state) => state.userFilters,
  (users, filters) => search(users, sanitizeFilters(filters))
);

export const SelectApprovedUsers = createSelector(selectUsers, (users) =>
  search(users, { approved: true })
);

export const SelectAdvancedUsers = createSelector(
  SelectApprovedUsers,
  (users) => filterByEnum(users, ['author', 'editor', 'admin'], 'role')
);

export const SelectAuthors = createSelector(selectUsers, (users) =>
  search(users, { role: 'author', approved: true })
);

export const selectUsersLoading = (state) => state.users.loading;
export const selectUsersLoaded = (state) => state.users.loaded;
