import { createSelector } from '@reduxjs/toolkit';

import { api } from '../../app';

const apiWithRegionTags = api.enhanceEndpoints({ addTagTypes: ['Region'] });

const regionApi = apiWithRegionTags.injectEndpoints({
  endpoints: (builder) => ({
    createRegion: builder.mutation({
      query: (body) => ({
        url: 'regions',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Region'],
    }),
    getRegions: builder.query({
      query: (extraParams) => ({
        url: 'regions',
        method: 'GET',
        params: {
          ...extraParams,
          type: 'Feature',
        },
      }),
      providesTags: (data) =>
        data && data.results
          ? [...data.results.map(({ id }) => ({ type: 'Region', id })), { type: 'Region', id: 'PARTIAL-REGION-LIST' }]
          : [{ type: 'Region', id: 'PARTIAL-REGION-LIST' }],
    }),
    getSingleRegion: builder.query({
      query: ({ id }) => ({
        url: `regions/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Region', id: result.id }] : ['Region']),
    }),
    updateRegion: builder.mutation({
      query: ({ id, body }) => ({
        url: `regions/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Region', id: arg.id },
        { type: 'Region', id: 'PARTIAL-REGION-LIST' },
      ],
    }),
    deleteRegion: builder.mutation({
      query: ({ id }) => ({
        url: `regions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Region', id: arg.id },
        { type: 'Region', id: 'PARTIAL-REGION-LIST' },
      ],
    }),
  }),
});

export const {
    useCreateRegionMutation,
    useGetRegionsQuery,
    useGetSingleRegionQuery,
    useUpdateRegionMutation,
    useDeleteRegionMutation,
} = regionApi;
export default regionApi;

export const primarySelector = regionApi.endpoints.getRegions.select();
const emptyRegions = [];
export const selectRegions = createSelector(primarySelector, response => response.data?.results ?? emptyRegions);
export const selectRegionById = (id) => regionApi.endpoints.getSingleRegion.select({ id });
export const selectRegionFromList = (id) =>
  createSelector(selectRegions, (response) => response.data?.results.find((region) => region.id === id));
