import React, { useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Card, Button, Form, Row, Col } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StepWizard from 'react-step-wizard';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import {
  Step,
  InputStep,
  PreviewStep,
  DictionaryStep,
} from '../../components/wizard';
import {
  useGetSingleSummaryQuery,
  useUpdateSummaryMutation,
} from '../../features/summary/summary.api';
import { CustomToast, Loader } from '../../components/common';
import { SummaryPDF } from '../../components/PDF';
import { chartMetaData } from '../../components/summaryCharts';
import { convertChartToImage, uploadToCloudinary } from '../../utils';
import { pdf } from '@react-pdf/renderer';
import { deleteEmptyStringProps } from '../../utils/reusableFunctions';

const EditSummary = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSingleSummaryQuery({ id });
  const [updateSummary] = useUpdateSummaryMutation();

  const now = new Date();
  const month = now.getMonth();
  const year = now.getFullYear();
  const [introduction, setIntroduction] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [dictionary, setDictionary] = useState([]);

  // descriptions
  const [typeDescription, setTypeDescription] = useState('');
  const [viralityDescription, setViralityDescription] = useState('');
  const [believabilityDescription, setBelievabilityDescription] = useState('');
  const [factualnessDescription, setFactualnessDescription] = useState('');
  const [
    likelihoodToCauseHarmDescription,
    setLikelihoodToCauseHarmDescription,
  ] = useState('');
  const [languageDescription, setLanguageDescription] = useState('');
  const [platformDescription, setPlatformDescription] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');

  // charts
  const [typeChart, setTypeChart] = useState(null);
  const [believabilityChart, setBelievabilityChart] = useState(null);
  const [viralityChart, setViralityChart] = useState(null);
  const [likelihoodToCauseHarmChart, setLikelihoodToCauseHarmChart] =
    useState(null);
  const [factualnessChart, setFactualnessChart] = useState(null);
  const [languageChart, setLanguageChart] = useState(null);
  const [platformChart, setPlatformChart] = useState(null);

  const [isLastStep, setIsLastStep] = useState(false);

  const PDFPreview = () => (
    <SummaryPDF
      typeChart={typeChart}
      believabilityChart={believabilityChart}
      likelihoodToCauseHarmChart={likelihoodToCauseHarmChart}
      factualnessChart={factualnessChart}
      viralityChart={viralityChart}
      languageChart={languageChart}
      platformChart={platformChart}
      year={year}
      month={month}
      introduction={introduction}
      conclusion={conclusion}
      additionalInformation={additionalInformation}
      dictionary={dictionary}
      typeDescription={typeDescription}
      viralityDescription={viralityDescription}
      likelihoodToCauseHarmDescription={likelihoodToCauseHarmDescription}
      factualnessDescription={factualnessDescription}
      believabilityDescription={believabilityDescription}
      platformDescription={platformDescription}
      languageDescription={languageDescription}
    />
  );

  const updateType = async () => {
    const chart = await convertChartToImage(chartMetaData.type.descId);
    setTypeChart(chart);
  };

  const updateBelievability = async () => {
    const chart = await convertChartToImage(chartMetaData.believability.descId);
    setBelievabilityChart(chart);
  };

  const updateVirality = async () => {
    const chart = await convertChartToImage(chartMetaData.virality.descId);
    setViralityChart(chart);
  };

  const updateFactualness = async () => {
    const chart = await convertChartToImage(chartMetaData.factualness.descId);
    setFactualnessChart(chart);
  };

  const updateLikelihoodToCauseHarm = async () => {
    const chart = await convertChartToImage(
      chartMetaData.likelihoodToCauseHarm.descId
    );
    setLikelihoodToCauseHarmChart(chart);
  };

  const updatePlatform = async () => {
    const chart = await convertChartToImage(chartMetaData.platform.descId);
    setPlatformChart(chart);
  };

  const updateLanguage = async () => {
    const chart = await convertChartToImage(chartMetaData.language.descId);
    setLanguageChart(chart);
  };

  const updateDictionary = (word, meaning) =>
    setDictionary([...dictionary, { word, meaning }]);

  const removeFromDictionary = (word) =>
    setDictionary(dictionary.filter((item) => item.word !== word));

  async function handleUpdateSummary(e) {
    e.stopPropagation();
    e.preventDefault();

    const pdfBlob = await pdf(<PDFPreview />).toBlob();
    const cloudinaryUrl = await uploadToCloudinary(
      pdfBlob,
      `Monitoring report for ${moment({ year, month }).format('MMMM YYYY')}`
    );

    const body = deleteEmptyStringProps({
      month,
      year,
      introduction,
      conclusion,
      typeDescription,
      believabilityDescription,
      viralityDescription,
      factualnessDescription,
      likelihoodToCauseHarmDescription,
      platformDescription,
      languageDescription,
      additionalInformation,
      dictionary,
      pdf: cloudinaryUrl ?? '',
    });
    await updateSummary({ id: data.id, body });
  }

  if (!data && isLoading) return <Loader />;

  if (!data)
    return (
      <div className='bg-white text-black m-4 p-4'>
        <p>No summary found</p>
      </div>
    );
  return (
    <>
      <CustomToast />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Body>
          <h5 className='mb-4'>
            Edit Monitoring Report for{' '}
            {moment({ year, month }).format('MMMM YYYY')}
          </h5>
          <Form>
            <StepWizard>
              <InputStep
                value={data.introduction}
                setValue={setIntroduction}
                placeholder='Introduction statement'
                label='Introduction'
                step={1}
                setIsLastStep={setIsLastStep}
                required={false}
              />
              <Step
                description={data.type.description}
                setDescription={setTypeDescription}
                beforeNext={updateType}
                data={data.type.stats}
                chartId={chartMetaData.type.descId}
                label={chartMetaData.type.label}
                step={2}
                required={false}
              />
              <Step
                description={data.virality.description}
                setDescription={setViralityDescription}
                beforeNext={updateVirality}
                data={data.virality.stats}
                chartId={chartMetaData.virality.descId}
                label={chartMetaData.virality.label}
                step={3}
                required={false}
              />
              <Step
                description={data.believability.description}
                setDescription={setBelievabilityDescription}
                beforeNext={updateBelievability}
                data={data.believability.stats}
                chartId={chartMetaData.believability.descId}
                label={chartMetaData.believability.label}
                inverseLikert={true}
                step={4}
                required={false}
              />
              <Step
                description={data.factualness.description}
                setDescription={setFactualnessDescription}
                beforeNext={updateFactualness}
                data={data.factualness.stats}
                chartId={chartMetaData.factualness.descId}
                label={chartMetaData.factualness.label}
                inverseLikert={true}
                step={5}
                required={false}
              />
              <Step
                description={data.likelihoodToCauseHarm.description}
                setDescription={setLikelihoodToCauseHarmDescription}
                beforeNext={updateLikelihoodToCauseHarm}
                data={data.likelihoodToCauseHarm.stats}
                chartId={chartMetaData.likelihoodToCauseHarm.descId}
                label={chartMetaData.likelihoodToCauseHarm.label}
                step={6}
                required={false}
              />
              <Step
                description={data.language.description}
                setDescription={setLanguageDescription}
                beforeNext={updateLanguage}
                data={data.language.stats}
                chartId={chartMetaData.language.descId}
                label={chartMetaData.language.label}
                step={7}
                required={false}
              />
              <Step
                description={data.platform.description}
                setDescription={setPlatformDescription}
                beforeNext={updatePlatform}
                data={data.platform.stats}
                chartId={chartMetaData.platform.descId}
                label={chartMetaData.platform.label}
                step={8}
                required={false}
              />
              <InputStep
                value={data.conclusion}
                setValue={setConclusion}
                placeholder='Conclusion statement'
                label='Conclusion'
                step={9}
                setIsLastStep={setIsLastStep}
                required={false}
              />
              <DictionaryStep
                step={10}
                dictionary={data.dictionary}
                updateDictionary={updateDictionary}
                removeFromDictionary={removeFromDictionary}
                setIsLastStep={setIsLastStep}
              />
              <InputStep
                value={data.additionalInformation}
                setValue={setAdditionalInformation}
                placeholder='Any additional information'
                label='Additional Information'
                step={11}
                required={false}
                setIsLastStep={setIsLastStep}
              />
              <PreviewStep
                step={12}
                preview={<PDFPreview />}
              />
            </StepWizard>
            <Row className='my-4'>
              <Col className='text-start'>
                <Button
                  variant='primary'
                  className='me-2'
                  type='submit'
                  disabled={!isLastStep}
                  onClick={handleUpdateSummary}
                >
                  Save{' '}
                  <FontAwesomeIcon
                    icon={faSave}
                    className='ms-2'
                  />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default EditSummary;
