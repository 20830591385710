import { api } from '../../app';

const apiWithSightingTags = api.enhanceEndpoints({ addTagTypes: ['Sighting'] });

const sightingApi = apiWithSightingTags.injectEndpoints({
  endpoints: (builder) => ({
    getSightings: builder.query({
      query: (params) => ({
        url: 'sightings',
        method: 'GET',
        params: {
          sortBy: 'frequency:desc',
          ...params,
        },
      }),
      providesTags: (data) =>
        data && data.results
          ? [...data.results.map(({ id }) => ({ type: 'Sighting', id })), { type: 'Sighting', id: 'PARTIAL-SIGHTING-LIST' }]
          : [{ type: 'Sighting', id: 'PARTIAL-SIGHTING-LIST' }],
    }),
    getSighting: builder.query({
        query: ({ id }) => ({
          url: `sightings/${id}`,
          method: 'GET',
        }),
        providesTags: (result) => (result ? [{ type: 'Sighting', id: result.id }] : ['Sighting']),
      }),
  }),
});

export const {
    useGetSightingsQuery,
    useGetSightingQuery,
} = sightingApi;
export default sightingApi;
