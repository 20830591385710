const config = {
  mapBoxToken: process.env.REACT_APP_MAPBOX_TOKEN,
  TilesetID: `${process.env.REACT_APP_MAPBOX_USERNAME}.incidentMappingTileset`,
  ApiKey: process.env.REACT_APP_API_KEY,
  hotspotUrl: process.env.REACT_APP_HOTSPOTS_IFRAME_SOURCE,
  cloudinary: {
    cloudName: process.env.REACT_APP_CLOUDNAME,
    uploadPreset: process.env.REACT_APP_UPLOAD_PRESET,
  },
  siteUrl: process.env.REACT_APP_SITE_URL,
};

export default config;
