// =========================================================
// * The Mungo
// =========================================================

import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

// core styles
import './scss/volt.scss';
import './App.css';

// vendor styles
import '@fortawesome/fontawesome-free/css/all.css';
import 'leaflet/dist/leaflet.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-toastify/dist/ReactToastify.css';

import { store } from './app/store';
import { Provider } from 'react-redux';
import { AppRouter } from './config';
import { ScrollToTop } from './components/common';

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <ScrollToTop />
      <AppRouter />
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);
