import { createSelector } from '@reduxjs/toolkit';

import { api } from '../../app';
import {
  searchAndSort,
  deleteEmptyProps,
  sortArrayUsingDate,
} from '../../utils/reusableFunctions';

const apiWithReportTags = api.enhanceEndpoints({ addTagTypes: ['Report'] });

const reportApi = apiWithReportTags.injectEndpoints({
  endpoints: (builder) => ({
    createReport: builder.mutation({
      query: (body) => ({
        url: 'reports',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Report'],
    }),
    getReports: builder.query({
      query: (extraParams) => ({
        url: 'reports',
        method: 'GET',
        params: {
          ...extraParams,
          populate: 'author',
          sortBy: 'updatedAt:desc',
        },
      }),
      providesTags: (data) =>
        data && data.results
          ? [
              ...data.results.map(({ id }) => ({ type: 'Report', id })),
              { type: 'Report', id: 'PARTIAL-REPORT-LIST' },
            ]
          : [{ type: 'Report', id: 'PARTIAL-REPORT-LIST' }],
    }),
    getSingleReport: builder.query({
      query: ({ id }) => ({
        url: `reports/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'Report', id: result.id }] : ['Report'],
    }),
    updateReport: builder.mutation({
      query: ({ id, body }) => ({
        url: `reports/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Report', id: arg.id },
        { type: 'Report', id: 'PARTIAL-REPORT-LIST' },
      ],
    }),
    deleteReport: builder.mutation({
      query: ({ id }) => ({
        url: `reports/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Report', id: arg.id },
        { type: 'Report', id: 'PARTIAL-REPORT-LIST' },
      ],
    }),
  }),
});

export const {
  useCreateReportMutation,
  useGetReportsQuery,
  useGetSingleReportQuery,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useLazyGetReportsQuery,
} = reportApi;
export default reportApi;

export const primarySelector = reportApi.endpoints.getReports.select();
const emptyReports = [];
export const selectReports = createSelector(
  primarySelector,
  (response) => response.data?.results ?? emptyReports
);
export const selectReportById = (id) =>
  reportApi.endpoints.getSingleReport.select({ id });
export const selectReportFromList = (id) =>
  createSelector(selectReports, (response) =>
    response.data?.results.find((report) => report.id === id)
  );

export const SelectFilteredreports = createSelector(
  selectReports,
  (state) => state.reportFilters,
  (state) => state.reportSorting,
  (reports, reportFilters, reportSorting) =>
    searchAndSort(reports, deleteEmptyProps(reportFilters), reportSorting)
);

export const SelectSortedReports = createSelector(selectReports, (reports) =>
  sortArrayUsingDate(reports, 'createdAt', { ascending: false })
);
