import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import {
  ChoosePhotoWidget,
  CustomToast,
  Loader,
} from '../../components/common';
import { avatar } from '../../assets/img';
import { toast } from 'react-toastify';
import { Routes } from '../../config/routes';
import { useCreateUserMutation } from '../../features/users/users.api';

const NewUser = () => {
  let history = useHistory();
  const [createUser, { isLoading: creating }] = useCreateUserMutation();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');

  async function handleCreateUser(e) {
    e.stopPropagation();
    e.preventDefault();

    if (name === '' || email === '' || password === '' || role === '') {
      toast.error('Please fill in all the fields');
    } else {
      const body = { name, email, role, password };
      await createUser(body);
      history.push(Routes.UsersTable.path);
    }
  }

  return (
    <Loader loaded={!creating}>
      <CustomToast />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Body>
          <h5 className='mb-4'>Add User</h5>
          <Form>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <ChoosePhotoWidget
                  title='Select profile photo'
                  photo={avatar}
                />
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='firstName'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Enter your name'
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center'>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='email'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type='email'
                    placeholder='name@company.com'
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='role'>
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    defaultValue='user'
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value='user'>User</option>
                    <option value='author'>Author</option>
                    <option value='editor'>Editor</option>
                    <option value='admin'>Admin</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='email'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    type='password'
                    placeholder='Password'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='mt-3'>
              <Button
                variant='primary'
                type='submit'
                onClick={handleCreateUser}
              >
                Create
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Loader>
  );
};

export default NewUser;
