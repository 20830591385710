import React, { useState } from 'react';
import { Card, Form, Button, Row, Col } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import { uploadToCloudinary } from '../../utils';
import { useCreateCampaignMutation } from '../../features/frames/campaigns.api';

const CreateTemplate = () => {
  const [createCampaign] = useCreateCampaignMutation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);

  async function handleCreateTemplate(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!title) {
      toast.error('Please fill all the fields');
    } else {
      if (uploadedImage) {
        uploadToCloudinary(uploadedImage).then(async (banner) => {
          await createCampaign({ title, description, banner });
        });
      } else {
        toast.error('Please upload a frame image');
      }
    }
  }
  return (
    <Card
      border='light'
      className='bg-white shadow-sm mb-4 text-black'
    >
      <Card.Body>
        <h5 className='mb-4'>Add New Template</h5>
        <Form>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <label
                htmlFor='frame'
                className='rounded w-100 position-relative '
                style={{
                  display: 'block',
                  zIndex: 0,
                  height: 400,
                  cursor: 'pointer',
                  border: '1px dashed black',
                }}
              >
                <input
                  id='cover'
                  type='file'
                  name='cover'
                  className='h-100 w-100 position-absolute'
                  onChange={(e) => {
                    if (e.target.files) {
                      setUploadedImage(e.target.files[0]);
                    }
                  }}
                  style={{
                    top: 0,
                    left: 0,
                    opacity: 0,
                    cursor: 'pointer',
                    zIndex: 15,
                  }}
                />
                <div
                  className='align-items-center justify-content-between px-5 position-absolute'
                  style={{ inset: 0, display: 'flex', zIndex: 10 }}
                >
                  <div
                    className='align-items-center justify-content-center w-100 h-75'
                    style={{ display: 'flex', gap: '1.25rem' }}
                  >
                    {uploadedImage ? (
                      <div
                        className='h-100 w-50 rounded justify-content-center align-items-center bg-white'
                        style={{ display: 'flex' }}
                      >
                        <img
                          src={URL.createObjectURL(uploadedImage)}
                          alt='frame'
                          className='h-100 rounded'
                        />
                      </div>
                    ) : (
                      <p className='mb-0'>
                        Upload a frame image. Max size 10MB
                      </p>
                    )}
                  </div>
                </div>
              </label>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <Form.Group id='title'>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder='Add campaign title'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <Form.Group id='description'>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  required
                  as='textarea'
                  placeholder='Describe the campaign'
                  rows={3}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className='mt-3'>
            <Button
              variant='primary'
              type='submit'
              size='sm'
              onClick={handleCreateTemplate}
            >
              Create
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CreateTemplate;
