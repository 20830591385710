import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Routes } from './routes';
import { useGetSingleUserQuery } from '../features/users/users.api';
import { CustomToast, Loader } from '../components/common';
import { Sidebar, Navbar, Footer } from '../components/layout';

const RouteWithSidebar = ({ component: Component, restrictedTo, ...rest }) => {
  const id = localStorage.getItem('userId');
  const { data: user, isFetching } = useGetSingleUserQuery({ id });

  const resize = () => {
    var resize = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 10);
    setTimeout(function () {
      clearInterval(resize);
    }, 301);
  };

  const localStorageIsContracted = () => {
    return localStorage.getItem('sidebarContracted') === 'false' ? false : true;
  };

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true;
  };

  const [contracted, setContracted] = useState(localStorageIsContracted());
  const [contractSidebar, setContractSidebar] = useState(
    localStorageIsContracted()
  );
  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleMouseOver = () => {
    if (contracted) {
      setContractSidebar(!contractSidebar);
    }
    resize();
  };

  const toggleContracted = () => {
    setContracted(!contracted);
    setContractSidebar(!contracted);
    localStorage.setItem('sidebarContracted', !contracted);
    resize();
  };

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  };

  if (isFetching) return <Loader />;

  if (!isFetching && user === undefined)
    return <Redirect to={Routes.Signin.path} />;

  if (!isFetching && user !== undefined && !restrictedTo.includes(user.role)) {
    return <Redirect to={Routes.Forbidden.path} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Sidebar
            contracted={contractSidebar}
            onMouseEnter={toggleMouseOver}
            onMouseLeave={toggleMouseOver}
          />

          <main
            className='content bg-white flex-column justify-content-between'
            style={{ minHeight: '100vh', display: 'flex' }}
          >
            <div>
              <CustomToast />
              <Navbar toggleContracted={toggleContracted} />
              <div className='px-5'>
                <Component {...props} />
              </div>
            </div>
            <Footer
              toggleSettings={toggleSettings}
              showSettings={showSettings}
            />
          </main>
        </>
      )}
    />
  );
};

export default RouteWithSidebar;
