import React from 'react';
import CategoryFilter from './Category.Filter';
import InitialDateFilter from './InitialDate.Filter';
import FinalDateFilter from './FinalDate.Filter';
import { Col, Row } from '@themesberg/react-bootstrap';

const CombinedFilters = () => (
    <Row className="align-items-center justify-content-lg-between justify-content-center">
      <Col xs={12} md={4} className="text-center mb-xs-4 justify-content-center">
        <CategoryFilter />
      </Col>
      <Col xs={12} md={4} className="text-center mb-xs-4 justify-content-center">
        <InitialDateFilter />
      </Col>
      <Col xs={12} md={4} className="text-center justify-content-center">
        <FinalDateFilter />
      </Col>
    </Row>
);

export default CombinedFilters;
