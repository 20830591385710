import React from 'react';
import { Card, Col, Row } from '@themesberg/react-bootstrap';
import { useParams } from 'react-router-dom';
import { Layout } from '../../components/layout';
import {
  SingleIncidentSidebar,
  SingleIncidentMap,
} from '../../components/incidents';
import { useGetSingleIncidentQuery } from '../../features/incidents/incidents.api';
import { Loader } from '../../components/common';
import { formatDate } from '../../utils/reusableFunctions';

const SingleIncident = () => {
  const { id } = useParams();
  const { data: incident } = useGetSingleIncidentQuery({ id });

  if (!incident) return <Loader />;

  return (
    <Loader loaded={incident}>
      <Layout>
        <Row
          noGutters
          className='bg-white'
        >
          <Col
            xs={12}
            md={8}
            className='px-0'
          >
            <Card className='px-5 pt-5 pb-5'>
              <Card.Title>
                <h4 className='h6 mb-0 text-primary'>{incident.title}</h4>
              </Card.Title>
              <Card.Subtitle>
                <div className='d-flex justify-content-between mb-3'>
                  <small className='text-black'>
                    Author:{' '}
                    {incident.author === null
                      ? 'unknown'
                      : incident.author.name}
                  </small>
                  <small className='text-black'>
                    Category:{' '}
                    {incident.category === null
                      ? 'unknown'
                      : incident.category.title}
                  </small>
                  <small className='text-black'>
                    Date: {formatDate(incident.incidentDate, ' ')}
                  </small>
                </div>
              </Card.Subtitle>
              <Card.Text>
                <p className='text-black'>{incident.description}</p>
                <Row>
                  <SingleIncidentMap
                    location={incident.location}
                    locationName={incident.locationName}
                    category={incident.category}
                  />
                </Row>
              </Card.Text>
              <Card.Footer>
                <h4 className='h5 fw-bold text-primary'>Related Links</h4>
                {incident.links.map((link) => (
                  <h4>
                    <Card.Link
                      href={link}
                      className='h6'
                    >
                      {link}
                    </Card.Link>
                  </h4>
                ))}
              </Card.Footer>
            </Card>
          </Col>
          <Col
            xs={12}
            md={4}
            className='px-0'
          >
            <SingleIncidentSidebar />
          </Col>
        </Row>
      </Layout>
    </Loader>
  );
};

export default SingleIncident;
