import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Button, Table } from '@themesberg/react-bootstrap';
import { CustomToast, Loader, MyPagination } from '../../components/common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config/routes';
import { paginateList } from '../../utils/reusableFunctions';
import { useGetCategoriesQuery } from '../../features/categories/categories.api';

const CategoriesTable = () => {
  const { categories } = useGetCategoriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      categories: data ? data.results : [],
    }),
  });
  const categoriesPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  let categoryList = paginateList(categories, currentPage, categoriesPerPage);

  const handlePageClicked = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(categories.length / categoriesPerPage);

  const Header = () => (
    <div className='table-settings mb-4 mt-4'>
      <Row className='justify-content-between align-items-center'>
        <Col className='ps-md-0 text-end'>
          <Button
            variant='primary'
            size='sm'
            className='me-2'
            as={Link}
            to={Routes.NewCategory.path}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className='me-2'
            />
            New Category
          </Button>
        </Col>
      </Row>
    </div>
  );

  const TableRow = (props) => {
    const { id, title, description } = props;

    return (
      <>
        <tr>
          <td>
            <span className='fw-normal'>{title}</span>
          </td>
          <td>
            <span className='fw-normal'>{description}</span>
          </td>
          <td>
            <Button
              variant='primary'
              type='submit'
              as={Link}
              to={`${Routes.CategoryEdit.path}/${id}`}
            >
              <FontAwesomeIcon
                icon={faEdit}
                className='me-2'
              />{' '}
              Edit
            </Button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <Loader loaded={!!categories.length}>
      <CustomToast />
      <Header />
      <MyPagination
        totPages={totalPages}
        currentPage={currentPage}
        pageClicked={(page) => {
          handlePageClicked(page);
        }}
      >
        <Card
          border='light'
          className='table-wrapper table-responsive shadow-sm'
        >
          <Card.Body className='pt-0'>
            <Table
              hover
              className='user-table align-items-center text-black'
            >
              <thead>
                <tr>
                  <th className='border-bottom'>Title</th>
                  <th className='border-bottom'>Description</th>
                  <th className='border-bottom'>Action</th>
                </tr>
              </thead>
              <tbody>
                {categoryList.map((r) => (
                  <TableRow
                    key={`category-${r.id}`}
                    {...r}
                  />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </MyPagination>
    </Loader>
  );
};

export default CategoriesTable;
