import React from 'react';
import { Container } from '@themesberg/react-bootstrap';
import { Header, PublicFooter } from '.';

const Layout = ({ children }) => {
  return (
    <Container
      fluid
      className='p-0 bg-white'
      style={{ overflow: 'hidden' }}
    >
      <Header />
      <main>{children}</main>
      <PublicFooter />
    </Container>
  );
};

export default Layout;
