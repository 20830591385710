import {
  createSlice,
  createSelector,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  filterByDateRange,
  search,
  sanitizeFilters,
  flatten,
  sortArrayUsingDate,
} from '../../utils/reusableFunctions';
import Api from '../../utils/network';

const api = new Api();

const incidentsAdapter = createEntityAdapter();

const initialState = incidentsAdapter.getInitialState({
  loading: false,
  loaded: false,
});

export const fetchIncidents = createAsyncThunk(
  'incidents/fetchIncidents',
  async () => {
    const res = await api.Incidents().getAllIncidents();
    return res.data.results;
  }
);

const incidentSlice = createSlice({
  name: 'incidents',
  initialState,
  reducers: {
    incidentAdded: incidentsAdapter.addOne,
    incidentDeleted: incidentsAdapter.removeOne,
    incidentUpdated: incidentsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncidents.pending, (state, action) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchIncidents.fulfilled, (state, action) => {
        incidentsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.loaded = true;
      })
      .addCase(fetchIncidents.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { incidentAdded, incidentDeleted, incidentUpdated } =
  incidentSlice.actions;

export default incidentSlice.reducer;

export const { selectAll: selectIncidents, selectById: selectIncidentById } =
  incidentsAdapter.getSelectors((state) => state.incidents);

export const selectPublishedIncidents = createSelector(
  selectIncidents,
  (incidents) => search(incidents, { isPublished: true })
);

export const SelectFilteredIncidents = createSelector(
  selectPublishedIncidents,
  (state) => state.incidentFilters,
  (incidents, incidentFilters) =>
    search(incidents, sanitizeFilters(incidentFilters))
);

export const SelectFlattenedIncidents = createSelector(
  selectPublishedIncidents,
  (incidents) => flatten(incidents)
);

export const SelectFilteredFlattenedIncidents = createSelector(
  SelectFlattenedIncidents,
  (state) => state.incidentFilters,
  (incidents, incidentFilters) =>
    search(incidents, sanitizeFilters(incidentFilters))
);

export const SelectIncidentsByDateRange = createSelector(
  SelectFilteredIncidents,
  (state) => state.incidentDateFilter,
  (incidents, incidentDateFilter) =>
    filterByDateRange(incidents, incidentDateFilter)
);

export const SelectFlattenedIncidentsByDateRange = createSelector(
  SelectFilteredFlattenedIncidents,
  (state) => state.incidentDateFilter,
  (incidents, incidentDateFilter) =>
    filterByDateRange(incidents, incidentDateFilter)
);

export const SelectSortedFlattenedIncidents = createSelector(
  SelectFlattenedIncidentsByDateRange,
  (incidents) => sortArrayUsingDate(incidents)
);

export const selectIncidentLoading = (state) => state.incidents.loading;
export const selectIncidentLoaded = (state) => state.incidents.loaded;
