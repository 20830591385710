import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, Link } from 'react-router-dom';
import { faDatabase, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Nav, Image, Dropdown } from '@themesberg/react-bootstrap';
import { useGetSingleUserQuery } from '../../features/users/users.api';
import { Loader, CustomToast } from '../common';
import { Routes } from '../../config';
import { useLogoutMutation } from '../../features/auth/auth.api';
import { resetAuth } from '../../app/api';
import { avatar } from '../../assets/img';

export default ({ Header }) => {
  const id = localStorage.getItem('userId');
  const { data: user } = useGetSingleUserQuery({ id });
  const [logoutUser] = useLogoutMutation();
  let history = useHistory();

  async function logout() {
    await logoutUser({
      refreshToken: localStorage.getItem('refreshToken'),
    }).then(() => {
      history.push(Routes.Signin.path);
      resetAuth();
    });
  }

  return (
    <Loader loaded={user !== undefined}>
      <CustomToast />
      <Dropdown
        as={Nav.Item}
        className='text-primary'
      >
        <Dropdown.Toggle
          as={Nav.Link}
          className='pt-1 px-0'
        >
          <div className='media d-flex align-items-center'>
            <Image
              src={user.avatar ? user.avatar : avatar}
              className='user-avatar md-avatar rounded-circle'
            />
            <div className='media-body ms-2 text-dark align-items-center d-none d-lg-block'>
              <span className='mb-0 h6 fw-bold'>{user.name}</span>
            </div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className='user-dropdown dropdown-menu-right mt-2 bg-tertiary'>
          <Dropdown.Item
            className='fw-bold'
            as={Link}
            to={Routes.Settings.path}
          >
            <FontAwesomeIcon
              icon={faUserCircle}
              className='me-2'
            />{' '}
            My Profile
          </Dropdown.Item>
          {Header && (
            <Dropdown.Item
              className='fw-bold'
              as={Link}
              to={Routes.DashboardOverview.path}
            >
              <FontAwesomeIcon
                icon={faDatabase}
                className='me-2'
              />{' '}
              Dashboard
            </Dropdown.Item>
          )}
          <Dropdown.Divider />

          <Dropdown.Item
            className='fw-bold'
            onClick={logout}
          >
            <FontAwesomeIcon
              icon={faSignOutAlt}
              className='text-danger me-2'
            />{' '}
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Loader>
  );
};
