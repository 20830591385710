import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, ListGroup, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import styles from '../../css/icons.module.css';
import { Loader, MyPagination } from '../common';
import { Routes } from '../../config';
import { paginateList } from '../../utils/reusableFunctions';
import { useGetReportsQuery } from '../../features/reports/reports.api';

const ReportList = ({ dashboard }) => {
  const { reports } = useGetReportsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      reports: data ? data.results : [],
    }),
  });

  const itemsPerPage = dashboard ? 4 : 15;
  const [currentPage, setCurrentPage] = useState(1);
  let itemList = paginateList(reports, currentPage, itemsPerPage);
  const totalPages = Math.ceil(reports.length / itemsPerPage);

  const handlePageClicked = (page) => {
    setCurrentPage(page);
  };

  const Report = (props) => {
    const { title, id } = props;

    return (
      <ListGroup.Item className='px-0 w-90'>
        <Container className='justify-content-center px-0'>
          <Col className='col-auto me-2 mb-2'>
            <h4 className='h6 mb-0 text-primary'>
              <Link to={`${Routes.Reports.path}/${id}`}>{title}</Link>
            </h4>
            <Link to={`${Routes.Reports.path}/${id}`}>
              <h4 className='h6 mb-0 text-black'>
                View Report
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={`ms-2 me-2 text-black ${styles.longIcon}`}
                  size='xs'
                />
              </h4>
            </Link>
          </Col>
          <div
            className='bg-primary'
            style={{ height: '0.1vh' }}
          ></div>
        </Container>
      </ListGroup.Item>
    );
  };

  return (
    <Loader loaded={!!reports.length}>
      <Card
        border='light'
        className='shadow-sm'
      >
        {dashboard && (
          <Card.Header className='border-bottom d-flex justify-content-between bg-secondary text-black'>
            <h4 className='h6 mb-0'>Recent Reports</h4>
          </Card.Header>
        )}
        <Card.Body>
          <MyPagination
            totPages={totalPages}
            currentPage={currentPage}
            pageClicked={(page) => {
              handlePageClicked(page);
            }}
          >
            <ListGroup className='list-group-flush list my--3 align-items-start'>
              {itemList.map((report) => (
                <Report
                  key={report.id}
                  {...report}
                />
              ))}
            </ListGroup>
          </MyPagination>
        </Card.Body>
      </Card>
    </Loader>
  );
};

ReportList.defaultProps = {
  // reports: [],
  dashboard: false,
};

ReportList.propTypes = {
  // reports: PropTypes.array.isRequired,
  dashboard: PropTypes.bool,
};

export default ReportList;
