import React, { useState } from 'react';
import { Container, Button, Row, Col } from '@themesberg/react-bootstrap';
import { Loader, CustomToast } from '../../components/common';
import { Routes } from '../../config';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useVerifyEmailMutation } from '../../features/auth/auth.api';
import getToken from '../../utils/getToken';

export default () => {
  let history = useHistory();
  const [verifyEmail, { isLoading }] = useVerifyEmailMutation();

  const [emailVerified, setEmailVerified] = useState(false);

  async function handleVerifyEmail() {
    await verifyEmail({ token: `${getToken(15)}` })
      .unwrap()
      .then(() => {
        setEmailVerified(true);
        history.push(Routes.Signin.path);
        toast.success(
          'Email Verified Successfully. Check your email for confirmation or click on the link below to login'
        );
      });
  }

  return (
    <Loader loaded={!isLoading}>
      <CustomToast />
      <Container
        fluid
        className='p-0 bg-white'
      >
        <Row className='h100'>
          <Col className='align-self-center'>
            {!emailVerified && (
              <Row>
                <Col className='text-center'>
                  <Button
                    variant='primary'
                    type='submit'
                    onClick={handleVerifyEmail}
                  >
                    One final step - click here to verify
                  </Button>
                </Col>
              </Row>
            )}
            <div className='d-flex justify-content-center align-items-center text-primary h6 fw-bold'>
              <Link
                to={Routes.Signin.path}
                className='fw-bold'
              >
                Go Back to Login
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Loader>
  );
};
