import React from 'react';
import { Button } from '@themesberg/react-bootstrap';
import Datetime from 'react-datetime';
import { useSelector, useDispatch } from 'react-redux';
import {
  finalDateFilterChanged,
  selectFinalDate,
} from '../../features/incidents/incidents.dateFilters.slice';
import { formatDate } from '../../utils/reusableFunctions';

const FinalDateFilter = () => {
  const dispatch = useDispatch();
  const finalDate = useSelector(selectFinalDate);

  return (
    <Datetime
      timeFormat={false}
      onChange={(date) => dispatch(finalDateFilterChanged(date))}
      value={formatDate(finalDate, '-')}
      initialValue={formatDate(finalDate, '-')}
      renderInput={(props, openCalendar, closeCalendar) => (
        <Button
          onClick={openCalendar}
          className='primary p-3'
        >
          <small>{`TO: ${formatDate(finalDate, '-')}`}</small>
        </Button>
      )}
    />
  );
};

export default FinalDateFilter;
