import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Modal,
} from '@themesberg/react-bootstrap';
import { CustomToast, Loader } from '../../components/common';
import { Routes } from '../../config/routes';
import {
  useDeleteCategoryMutation,
  useGetSingleCategoryQuery,
  useUpdateCategoryMutation,
} from '../../features/categories/categories.api';

const EditCategory = () => {
  const { id } = useParams();
  const { data: category } = useGetSingleCategoryQuery({ id });
  const [updateCategory] = useUpdateCategoryMutation();
  const [deleteCategory] = useDeleteCategoryMutation();
  let history = useHistory();

  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleUpdateCategory(e) {
    e.stopPropagation();
    e.preventDefault();

    const body = {
      title: title ?? category.title,
      description: description ?? category.description,
    };
    await updateCategory({ id, body });
  }

  async function handleDeleteCategory() {
    handleClose();
    history.push(Routes.CategoriesTable.path);
    await deleteCategory({ id });
  }

  const CheckDelete = () => (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you want to delete {category.title}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deleting a category is an irreversible process. You cannot recover the
        information once a category is deleted
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          variant='secondary'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={handleDeleteCategory}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (!category) return <Loader />;

  return (
    <Card
      border='light'
      className='bg-white shadow-sm mb-4'
    >
      <CustomToast />
      <CheckDelete />
      <Card.Body>
        <Form>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <Form.Group id='title'>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter category title'
                  defaultValue={category.title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <Form.Group id='description'>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder='Describe the category'
                  style={{ height: '20vh' }}
                  defaultValue={category.description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-between'>
            <Col className='text-start'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='danger'
                type='button'
                onClick={handleShow}
              >
                Delete Category
              </Button>
            </Col>
            <Col className='ps-md-0 text-end'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='primary'
                type='submit'
                onClick={handleUpdateCategory}
              >
                Update Category
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditCategory;
