import React from 'react';
import { Container } from '@themesberg/react-bootstrap';
import { HeaderAlt, FooterAlt } from '.';

const LayoutAlt = ({ children }) => {
  return (
    <Container
      fluid
      className='p-0 bg-white text-black  flex-column justify-content-between sm-p-1'
      style={{ overflow: 'hidden', minHeight: '100vh', display: 'flex' }}
    >
      <div>
        <HeaderAlt />
        <main style={{ marginTop: 100 }}>{children}</main>
      </div>
      <FooterAlt />
    </Container>
  );
};

export default LayoutAlt;
