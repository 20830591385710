import React, { useState } from 'react';
import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import { CustomToast } from '../../components/common';
import { useCreateKeywordMutation } from '../../features/keywords/keywords.api';

const NewKeyword = () => {
  const [createKeyword] = useCreateKeywordMutation();

  const [text, setText] = useState('');

  async function handleCreateKeyword(e) {
    e.stopPropagation();
    e.preventDefault();

    if (text === '') {
      toast.error('Please fill in all the fields');
    } else {
      await createKeyword({ text });
    }
  }

  return (
    <>
      <CustomToast />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Body>
          <h5 className='mb-4'>Add Keyword</h5>
          <Form>
            <Row className='align-items-center'>
              <Col className='mb-3'>
                <Form.Group id='text'>
                  <Form.Label>Text</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Enter keyword text'
                    onChange={(e) => setText(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='mt-3'>
              <Button
                variant='primary'
                type='submit'
                onClick={handleCreateKeyword}
              >
                Create
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default NewKeyword;
