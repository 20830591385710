import React from 'react';
import { ListGroup, Card } from '@themesberg/react-bootstrap';

const Legend = () => {
    const data = [
        {
            name: 'Very High',
            value: '#ff0000'
        },
        {
            name: 'High',
            value: '#ffff00'
        },
        {
            name: 'Average',
            value: '#a9a9a9'
        },
        {
            name: 'Low',
            value: '#e1e1e1'
        },
        {
            name: 'Very Low',
            value: '#ffffff'
        }
    ];

    const LegendItem = ({ name, value }) => (
        <ListGroup.Item>
            <div>
                <span style={{ backgroundColor: value }} className="legend-key"></span>
                <span className="h6 text-primary">{name}</span>
            </div>
        </ListGroup.Item>
    );

    return (
        <Card border="light" className="shadow-sm">
          <Card.Header className="border-bottom d-flex justify-content-between bg-secondary text-black">
            <h4 className="h6 mb-0">Legend</h4>
          </Card.Header>
          <Card.Body>
            <ListGroup className="list-group-flush list my--3">
              {data.map(i => <LegendItem key={i.name} {...i} />)}
            </ListGroup>
          </Card.Body>
        </Card>
    )
}

export default Legend
