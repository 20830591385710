import { createSelector } from '@reduxjs/toolkit';

import { api } from '../../app';
import {
  search,
  sanitizeFilters,
  filterByDateRange,
  sortArrayUsingDate,
  flatten,
} from '../../utils/reusableFunctions';

const apiWithIncidentTags = api.enhanceEndpoints({ addTagTypes: ['Incident'] });

const incidentApi = apiWithIncidentTags.injectEndpoints({
  endpoints: (builder) => ({
    createIncident: builder.mutation({
      query: (body) => ({
        url: 'incidents',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Incident'],
    }),
    getIncidents: builder.query({
      query: (extraParams) => ({
        url: 'incidents',
        method: 'GET',
        params: {
          ...extraParams,
          populate: 'author category',
          sortBy: 'createdAt:desc',
        },
      }),
      providesTags: (data) =>
        data && data.results
          ? [
              ...data.results.map(({ id }) => ({ type: 'Incident', id })),
              { type: 'Incident', id: 'PARTIAL-INCIDENT-LIST' },
            ]
          : [{ type: 'Incident', id: 'PARTIAL-INCIDENT-LIST' }],
    }),
    getSingleIncident: builder.query({
      query: ({ id }) => ({
        url: `incidents/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'Incident', id: result.id }] : ['Incident'],
    }),
    updateIncident: builder.mutation({
      query: ({ id, body }) => ({
        url: `incidents/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Incident', id: arg.id },
        { type: 'Incident', id: 'PARTIAL-INCIDENT-LIST' },
      ],
      onQueryStarted({ id, body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          incidentApi.util.updateQueryData(
            'getSingleIncident',
            { id },
            (draft) => {
              Object.assign(draft, body);
            }
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    updatePublication: builder.mutation({
      query: ({ id }) => ({
        url: `publish/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Incident', id: arg.id },
        { type: 'Incident', id: 'PARTIAL-INCIDENT-LIST' },
      ],
      onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          incidentApi.util.updateQueryData(
            'getSingleIncident',
            { id },
            (draft) => {
              Object.assign(draft, { isPublished: !draft.isPublished });
            }
          )
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    deleteIncident: builder.mutation({
      query: ({ id }) => ({
        url: `incidents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Incident', id: arg.id },
        { type: 'Incident', id: 'PARTIAL-INCIDENT-LIST' },
      ],
    }),
  }),
});

export const {
  useCreateIncidentMutation,
  useGetIncidentsQuery,
  useGetSingleIncidentQuery,
  useUpdateIncidentMutation,
  useUpdatePublicationMutation,
  useDeleteIncidentMutation,
  useLazyGetIncidentsQuery,
} = incidentApi;
export default incidentApi;

export const primarySelector = incidentApi.endpoints.getIncidents.select();
const emptyIncidents = [];
export const selectIncidents = createSelector(
  primarySelector,
  (response) => response.data?.results ?? emptyIncidents
);
export const selectIncidentById = (id) =>
  incidentApi.endpoints.getSingleIncident.select({ id });
export const selectIncidentFromList = (id) =>
  createSelector(selectIncidents, (response) =>
    response.data?.results.find((incident) => incident.id === id)
  );

export const selectPublishedIncidents = createSelector(
  selectIncidents,
  (incidents) => search(incidents, { isPublished: true })
);

export const SelectFilteredIncidents = createSelector(
  selectPublishedIncidents,
  (state) => state.incidentFilters,
  (incidents, incidentFilters) =>
    search(incidents, sanitizeFilters(incidentFilters))
);

export const SelectFlattenedIncidents = createSelector(
  selectPublishedIncidents,
  (incidents) => flatten(incidents)
);

export const SelectFilteredFlattenedIncidents = createSelector(
  SelectFlattenedIncidents,
  (state) => state.incidentFilters,
  (incidents, incidentFilters) =>
    search(incidents, sanitizeFilters(incidentFilters))
);

export const SelectIncidentsByDateRange = createSelector(
  SelectFilteredIncidents,
  (state) => state.incidentDateFilter,
  (incidents, incidentDateFilter) =>
    filterByDateRange(incidents, incidentDateFilter)
);

export const SelectFlattenedIncidentsByDateRange = createSelector(
  SelectFilteredFlattenedIncidents,
  (state) => state.incidentDateFilter,
  (incidents, incidentDateFilter) =>
    filterByDateRange(incidents, incidentDateFilter)
);

export const SelectSortedFlattenedIncidents = createSelector(
  SelectFlattenedIncidentsByDateRange,
  (incidents) => sortArrayUsingDate(incidents)
);
