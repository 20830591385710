import { createSelector } from '@reduxjs/toolkit';
import { api } from '../../app';

const apiWithCategoryTags = api.enhanceEndpoints({ addTagTypes: ['Category'] });

const categoryApi = apiWithCategoryTags.injectEndpoints({
  endpoints: (builder) => ({
    createCategory: builder.mutation({
      query: (body) => ({
        url: 'categories',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Category'],
    }),
    getCategories: builder.query({
      query: (params) => ({
        url: 'categories',
        method: 'GET',
        params,
      }),
      providesTags: (data) =>
        data && data.results
          ? [...data.results.map(({ id }) => ({ type: 'Category', id })), { type: 'Category', id: 'PARTIAL-CATEGORY-LIST' }]
          : [{ type: 'Category', id: 'PARTIAL-CATEGORY-LIST' }],
    }),
    getSingleCategory: builder.query({
      query: ({ id }) => ({
        url: `categories/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Category', id: result.id }] : ['Category']),
    }),
    updateCategory: builder.mutation({
      query: ({ id, body }) => ({
        url: `categories/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Category', id: arg.id },
        { type: 'Category', id: 'PARTIAL-CATEGORY-LIST' },
      ],
    }),
    deleteCategory: builder.mutation({
      query: ({ id }) => ({
        url: `categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Category', id: arg.id },
        { type: 'Category', id: 'PARTIAL-CATEGORY-LIST' },
      ],
    }),
  }),
});

export const {
    useCreateCategoryMutation,
    useDeleteCategoryMutation,
    useGetCategoriesQuery,
    useGetSingleCategoryQuery,
    useUpdateCategoryMutation,
    useLazyGetCategoriesQuery
} = categoryApi;
export default categoryApi;

const empty = [];

export const primarySelector = categoryApi.endpoints.getCategories.select();
export const selectCategories = createSelector(primarySelector, response => response.data?.results ?? empty);
export const selectCategoryById = (id) => categoryApi.endpoints.getSingleCategory.select({ id });