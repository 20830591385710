import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Modal,
} from '@themesberg/react-bootstrap';
import { CustomToast, Loader } from '../../components/common';
import { Routes } from '../../config/routes';
import {
  useDeleteRegionMutation,
  useGetSingleRegionQuery,
  useUpdateRegionMutation,
} from '../../features/regions/regions.api';

const EditRegion = () => {
  const { id } = useParams();
  let history = useHistory();
  const { data: region } = useGetSingleRegionQuery({ id });
  console.log(region);
  const [updateRegion, { isLoading: updating }] = useUpdateRegionMutation();
  const [deleteRegion, { isLoading: deleting }] = useDeleteRegionMutation();

  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [incidenceLevel, setIncidenceLevel] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleUpdateRegion(e) {
    e.stopPropagation();
    e.preventDefault();

    const body = {
      type: type ?? region.type,
      properties: {
        name: name ?? region.properties.name,
        incidenceLevel: incidenceLevel ?? region.properties.incidenceLevel,
      },
    };

    await updateRegion({ id, body });
  }

  async function handleDeleteRegion() {
    handleClose();
    history.push(Routes.RegionsTable.path);
    await deleteRegion({ id });
  }

  const CheckDelete = () => (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you want to delete {region.properties.name}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deleting a region is an irreversible process. You cannot recover the
        information once a region is deleted
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          variant='secondary'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={handleDeleteRegion}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (!region || updating || deleting) return <Loader />;

  return (
    <Card
      border='light'
      className='bg-white shadow-sm mb-4'
    >
      <CustomToast />
      <CheckDelete />
      <Card.Body>
        <Form>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <Form.Group id='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue={region.properties.name}
                  placeholder='Enter region name'
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col
              md={6}
              className='mb-3'
            >
              <Form.Group id='type'>
                <Form.Label>Type</Form.Label>
                <Form.Select
                  onChange={(e) => setType(e.target.value)}
                  defaultValue={region.type}
                >
                  <option value='Feature'>Feature</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col
              md={6}
              className='mb-3'
            >
              <Form.Group id='incidenceLevel'>
                <Form.Label>ID</Form.Label>
                <Form.Select
                  onChange={(e) => setIncidenceLevel(e.target.value)}
                  defaultValue={region.properties.incidenceLevel}
                >
                  <option value='very high'>Very High</option>
                  <option value='high'>High</option>
                  <option value='average'>Average</option>
                  <option value='low'>Low</option>
                  <option value='very low'>Very Low</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-between'>
            <Col className='text-start'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='danger'
                type='button'
                onClick={handleShow}
              >
                Delete Region
              </Button>
            </Col>
            <Col className='ps-md-0 text-end'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='primary'
                type='submit'
                onClick={handleUpdateRegion}
              >
                Update Region
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditRegion;
