import { api } from '../../app';

const apiWithTweetTags = api.enhanceEndpoints({ addTagTypes: ['Tweet'] });

const tweetApi = apiWithTweetTags.injectEndpoints({
  endpoints: (builder) => ({
    getTweets: builder.query({
      query: (params) => ({
        url: 'tweets',
        method: 'GET',
        params: {
            sortBy: 'createdAt:desc',
            ...params,
        },
      }),
      providesTags: (data) =>
        data && data.results
          ? [...data.results.map(({ id }) => ({ type: 'Tweet', id })), { type: 'Tweet', id: 'PARTIAL-TWEET-LIST' }]
          : [{ type: 'Tweet', id: 'PARTIAL-TWEET-LIST' }],
    }),
    getSingleTweet: builder.query({
      query: ({ id }) => ({
        url: `tweets/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Tweet', id: result.id }] : ['Tweet']),
    }),
    getRules: builder.query({
        query: () => ({
            url: 'rules',
            method: 'GET',
        }),
    }),
  }),
});

export const {
    useGetTweetsQuery,
    useGetSingleTweetQuery,
    useGetRulesQuery,
} = tweetApi;
export default tweetApi;
