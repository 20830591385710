import React from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { chartMetaData } from '../summaryCharts';
import { dhnLogo, civicWatchLogo } from '../../assets/img';
import { ChartSection, styles } from '.';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const SummaryPDF = ({
  typeChart,
  believabilityChart,
  viralityChart,
  likelihoodToCauseHarmChart,
  factualnessChart,
  languageChart,
  platformChart,
  year,
  month,
  introduction,
  conclusion,
  dictionary,
  typeDescription,
  likelihoodToCauseHarmDescription,
  viralityDescription,
  factualnessDescription,
  believabilityDescription,
  platformDescription,
  languageDescription,
  additionalInformation,
}) => {
  return (
    <Document title={`Summary statistics for ${months[month]} ${year}`}>
      <Page
        size='A4'
        style={styles.centerPage}
      >
        <View style={styles.centerSection}>
          <Text
            style={{
              fontSize: 65,
              fontWeight: 900,
              color: '#00a936',
              margin: 10,
            }}
          >
            Monitoring Report
          </Text>
          <Text
            style={{
              fontSize: 30,
              fontWeight: 600,
              color: 'black',
              margin: 10,
            }}
          >
            {`${months[month].toUpperCase()} ${year}`}
          </Text>
          <Text
            style={{
              fontSize: 18,
              fontWeight: 600,
              color: '#00a936',
              margin: 10,
              textAlign: 'center',
            }}
          >
            «Compiled and edited by{' '}
            <Text style={styles.boldText}>Civic Watch</Text> for the{' '}
            <Text style={styles.boldText}>#defyhatenow_Cameroon</Text> Project»
          </Text>
        </View>
        <View style={styles.centerBetweenSection}>
          <Image
            src={dhnLogo}
            style={styles.logo}
          />
          <Image
            src={civicWatchLogo}
            style={styles.logo}
          />
        </View>
      </Page>
      <Page
        size='A4'
        style={styles.page}
      >
        <View style={styles.section}>
          <Text style={styles.title}>Introduction</Text>
          <Text style={styles.text}>{introduction}</Text>
        </View>
      </Page>
      {dictionary.length > 0 && (
        <Page
          size='A4'
          style={styles.page}
        >
          <View style={styles.section}>
            <Text style={styles.title}>Definition of Terms</Text>
            {dictionary.map((item, index) => (
              <Text
                key={index}
                style={{
                  fontWeight: 900,
                  fontSize: 11,
                  color: 'black',
                  margin: 5,
                }}
              >
                {item.word}
                <Text
                  style={{
                    fontWeight: 300,
                    fontSize: 11,
                    color: '#5A5A5A',
                    marginLeft: 5,
                  }}
                >
                  : {item.meaning}
                </Text>
              </Text>
            ))}
          </View>
        </Page>
      )}
      {typeChart && (
        <ChartSection
          chart={typeChart}
          label={chartMetaData.type.label}
          description={typeDescription}
        />
      )}
      {likelihoodToCauseHarmChart && (
        <ChartSection
          chart={likelihoodToCauseHarmChart}
          label={chartMetaData.likelihoodToCauseHarm.label}
          description={likelihoodToCauseHarmDescription}
        />
      )}
      {viralityChart && (
        <ChartSection
          chart={viralityChart}
          label={chartMetaData.virality.label}
          description={viralityDescription}
        />
      )}
      {believabilityChart && (
        <ChartSection
          chart={believabilityChart}
          label={chartMetaData.believability.label}
          description={believabilityDescription}
        />
      )}
      {factualnessChart && (
        <ChartSection
          chart={factualnessChart}
          label={chartMetaData.factualness.label}
          description={factualnessDescription}
        />
      )}
      {platformChart && (
        <ChartSection
          chart={platformChart}
          label={chartMetaData.platform.label}
          description={platformDescription}
        />
      )}
      {languageChart && (
        <ChartSection
          chart={languageChart}
          label={chartMetaData.language.label}
          description={languageDescription}
        />
      )}
      <Page
        size='A4'
        style={styles.page}
      >
        <View style={styles.section}>
          <Text style={styles.title}>Conclusion</Text>
          <Text style={styles.text}>{conclusion}</Text>
        </View>
      </Page>
      {additionalInformation && (
        <Page
          size='A4'
          style={styles.page}
        >
          <View style={styles.section}>
            <Text style={styles.title}>Additional Information</Text>
            <Text style={styles.text}>{additionalInformation}</Text>
          </View>
        </Page>
      )}
    </Document>
  );
};

export default SummaryPDF;
