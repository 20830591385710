import React from "react";
import Chartist from "react-chartist";
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import ChartistAxisTitle from 'chartist-plugin-axistitle';

const IncidentChart = (props) => {
  const { data } = props;
  const labels = data.labels;
  const series = data.series;

  const options = {
    low: 0,
    showArea: false,
    fullWidth: true,
    chartPadding: {
      right: 40,
      left: 40,
    },
    axisX: {
      position: 'end',
      showGrid: false
    },
    axisY: {
      showGrid: true,
      showLabel: true,
      labelInterpolationFnc: value => value,
      onlyInteger: true,
    }
  };

  const plugins = [
    ChartistTooltip(),
    ChartistAxisTitle({
      axisX: {
        axisTitle: 'Time (months)',
        axisClass: 'ct-axis-title',
        offset: {
          x: 10,
          y: 50
        },
        textAnchor: 'middle'
      },
      axisY: {
        axisTitle: 'Number of Incidents',
        axisClass: 'ct-axis-title',
        offset: {
          x: 10,
          y: 0
        },
        textAnchor: 'middle',
        flipTitle: false
      }
    })
  ]

  return (
    <Chartist data={{ series, labels }} options={{...options, plugins}} type="Line" className="ct-major-tenth p-5" />
  );
};

export default IncidentChart;
