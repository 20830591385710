import React, { useState } from 'react';
import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import { CustomToast } from '../../components/common';
import { useCreateCategoryMutation } from '../../features/categories/categories.api';

const NewCategory = () => {
  const [createCategory] = useCreateCategoryMutation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  async function handleCreateCategory(e) {
    e.stopPropagation();
    e.preventDefault();

    if (title === '' || description === '') {
      toast.error('Please fill in all the fields');
    } else {
      await createCategory({ title, description });
    }
  }

  return (
    <>
      <CustomToast />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Body>
          <h5 className='mb-4'>Add Category</h5>
          <Form>
            <Row className='align-items-center'>
              <Col className='mb-3'>
                <Form.Group id='title'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Enter category title'
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center'>
              <Col className='mb-3'>
                <Form.Group id='description'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    required
                    as='textarea'
                    placeholder='Describe the category'
                    style={{ height: '20vh' }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='mt-3'>
              <Button
                variant='primary'
                type='submit'
                onClick={handleCreateCategory}
              >
                Create
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default NewCategory;
