import React from 'react';
import { Layout } from '../components/layout';
import { Row, Col, Card, Container } from '@themesberg/react-bootstrap';
import { ReportImageList } from '../components/reports';
import { Loader } from '../components/common';
import { LineChart, SmallIncidentList } from '../components/incidents';
import { bg } from '../assets/img';
import { useGetIncidentsQuery } from '../features/incidents/incidents.api';
import { useGetReportsQuery } from '../features/reports/reports.api';
import { useGetCategoriesQuery } from '../features/categories/categories.api';

const Home = () => {
  const { isSuccess: incidentsLoaded } = useGetIncidentsQuery();
  const { isSuccess: reportsLoaded } = useGetReportsQuery();
  const { isSuccess: categoriesLoaded } = useGetCategoriesQuery();

  return (
    <>
      <Loader loaded={incidentsLoaded && reportsLoaded && categoriesLoaded}>
        <Layout>
          <Container
            fluid
            className='p-0 bg-white'
          >
            <Card>
              <Card.Img
                src={bg}
                alt='about image'
                className='hero'
              />
              <Card.ImgOverlay className='text-white aboutImage text-justify align-items-center hero'>
                <Card.Text
                  as='div'
                  className='h-100 flex justify-content-center'
                >
                  <Row className='h-100 w-80 sm-w-100 p-3 sm-p-1'>
                    <Col className='align-self-center'>
                      <div className='bg-secondary fw-bolder text-black p-3 mb-0 text-center rounded-top sm-p-1 '>
                        <h2 style={{ fontSize: '3rem' }}>The Mungo</h2>
                        <h4>Early Warning Early Response</h4>
                      </div>
                      <p className='text-black bg-white p-4 px-5 rounded-bottom sm-p-1 sm-px-1'>
                        The Mungo is figurative to the river that brings
                        together the Anglophone and Francophone regions of
                        Cameroon. It' s a citizen journalism framework that
                        crowdsources and mapps conflict incidents mostly in the
                        North West and South West regions for rapid response.
                        Moreover we publish bi-weekly situational analysis
                        reports highlighting the state of affairs in Cameroon
                        and is intended to give an early warning for relevant
                        effective responses. For you as our partner we foresee
                        this reports aiding your planning and informing your
                        community activities.
                      </p>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.ImgOverlay>
            </Card>
            <Row
              lg={10}
              className='justify-content-center align-items-end mt-md-5 mb-md-5 mx-md-5'
              noGutters
            >
              <Col
                lg={4}
                xs={12}
              >
                <SmallIncidentList length={6} />
              </Col>
              <Col lg={8}>
                <h1 className='fw-bold text-primary text-center mt-3 mb-5 h3'>
                  Incidents Chart Overview
                </h1>
                <LineChart />
              </Col>
            </Row>
            <Row
              lg={8}
              className='mb-0 bg-quaternary py-5'
            >
              <h1 className='fw-bold text-primary text-center my-3 h3'>
                Published Reports
              </h1>
              <ReportImageList />
            </Row>
          </Container>
        </Layout>
      </Loader>
    </>
  );
};

export default Home;
