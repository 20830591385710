import React from 'react';
import WizardFooter from './WizardFooter';
import { PDFViewer } from '@react-pdf/renderer';

const PreviewStep = ({
  preview,
  previousStep,
  nextStep,
  step,
  totalSteps,
  setIsLastStep,
}) => {
  const goToNext = () => {
    setIsLastStep(step === totalSteps - 1);
    nextStep();
  };
  return (
    <div className='my-4 bg-white text-black'>
      <h6>Preview</h6>
      <PDFViewer
        height='800px'
        width='100%'
      >
        {preview}
      </PDFViewer>
      <WizardFooter
        previousStep={previousStep}
        nextStep={goToNext}
        step={step}
        totalSteps={totalSteps}
      />
    </div>
  );
};

export default PreviewStep;
