import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Button, Table } from '@themesberg/react-bootstrap';
import { CustomToast, Loader, MyPagination } from '../../components/common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config/routes';
import { formatDate, paginateList } from '../../utils/reusableFunctions';
import { useGetReportsQuery } from '../../features/reports/reports.api';

const ReportsTable = () => {
  const { reports } = useGetReportsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      reports: data ? data.results : [],
    }),
  });
  const reportsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  let reportList = paginateList(reports, currentPage, reportsPerPage);

  const handlePageClicked = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(reports.length / reportsPerPage);

  const Header = () => (
    <div className='table-settings mb-4 mt-4'>
      <Row className='justify-content-between align-items-center'>
        <Col className='ps-md-0 text-end'>
          <Button
            variant='primary'
            size='sm'
            className='me-2'
            as={Link}
            to={Routes.NewReport.path}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className='me-2'
            />
            New Report
          </Button>
        </Col>
      </Row>
    </div>
  );

  const TableRow = (props) => {
    const { id, title, author, updatedAt } = props;

    return (
      <>
        <tr>
          <td>
            <span className='fw-normal'>{title}</span>
          </td>
          <td>
            <span className='fw-normal'>
              {author === null ? 'unknown' : author.name}
            </span>
          </td>
          <td>
            <span className={`fw-normal`}>{formatDate(updatedAt, '/')}</span>
          </td>
          <td>
            <Button
              variant='primary'
              type='submit'
              as={Link}
              to={`${Routes.ReportEdit.path}/${id}`}
            >
              <FontAwesomeIcon
                icon={faEdit}
                className='me-2'
              />{' '}
              Edit
            </Button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <Loader loaded={!!reports.length}>
      <CustomToast />
      <Header />
      <MyPagination
        totPages={totalPages}
        currentPage={currentPage}
        pageClicked={(page) => {
          handlePageClicked(page);
        }}
      >
        <Card
          border='light'
          className='table-wrapper table-responsive shadow-sm'
        >
          <Card.Body className='pt-0'>
            <Table
              hover
              className='user-table align-items-center text-black'
            >
              <thead>
                <tr>
                  <th className='border-bottom'>Title</th>
                  <th className='border-bottom'>Author</th>
                  <th className='border-bottom'>Last Updated</th>
                  <th className='border-bottom'>Action</th>
                </tr>
              </thead>
              <tbody>
                {reportList.map((r) => (
                  <TableRow
                    key={`report-${r.id}`}
                    {...r}
                  />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </MyPagination>
    </Loader>
  );
};

export default ReportsTable;
