import React, { useState } from 'react';
import {
  Col,
  Form,
  Row,
  InputGroup,
  Card,
  Button,
  Table,
} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { Routes } from '../../config';
import { useGetCampaignsQuery } from '../../features/frames/campaigns.api';
import { Loader, MyPagination } from '../../components/common';

const CampaignList = () => {
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState('');
  const [limit, setLimit] = useState(10);
  const [timeoutID, setTimeoutID] = useState(0);
  const [skip, setSkip] = useState(false);
  const { data: campaigns } = useGetCampaignsQuery({ page, title }, { skip });

  const handlePageClicked = (page) => setPage(page);

  const handleKeyPress = (e) => {
    if (!skip) setSkip(true);
    if (e.key === 'Enter') {
      setSkip(false);
    } else {
      if (timeoutID) clearTimeout(timeoutID);
      setTimeoutID(() => setTimeout(() => setSkip(false), 300));
    }
  };

  if (!campaigns) return <Loader />;

  if (campaigns.totalResults === 0 && !title)
    return (
      <div className='text-black'>
        <p>
          Sorry. We couldn't find a campaign for you. Would you like to{' '}
          <Link
            to={Routes.CreateTemplate.path}
            className='text-primary'
          >
            create one
          </Link>
          ?
        </p>
      </div>
    );

  const Header = () => (
    <div className='table-settings mb-4 mt-4'>
      <Row className='justify-content-between align-items-center'>
        <Col className='ps-md-0 text-end'>
          <Button
            variant='primary'
            size='sm'
            className='me-2'
            as={Link}
            to={Routes.CreateTemplate.path}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className='me-2'
            />
            New Campaign
          </Button>
        </Col>
      </Row>
    </div>
  );

  const TableRow = ({ id, title, banner }) => {
    return (
      <tr>
        <td>
          <span className='fw-normal'>{title}</span>
        </td>
        <td>
          <span className='fw-normal'>{banner}</span>
        </td>
        <td>
          <Button
            variant='primary'
            type='submit'
            size='sm'
            as={Link}
            to={`${Routes.CampaignList.path}/${id}`}
          >
            <FontAwesomeIcon
              icon={faEdit}
              className='me-2'
            />{' '}
            Edit
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <div>
      <Header />
      <MyPagination
        totPages={campaigns.totalPages}
        currentPage={page}
        pageClicked={(page) => {
          handlePageClicked(page);
        }}
      >
        <Card
          border='light'
          className='table-wrapper table-responsive shadow-sm text-black'
        >
          <Card.Body className='py-4'>
            <Form className='mb-4'>
              <Row>
                <Col>
                  <Form.Group
                    id='campaign-title'
                    className='mx-0'
                  >
                    <Form.Label>Title of the campaign</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        type='text'
                        value={title}
                        placeholder=' Search using the title of the campaign e.g. #ThinkB4UClick'
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs='auto'>
                  <Form.Group
                    id='page-limit'
                    className='mx-0'
                  >
                    <Form.Label>Limit</Form.Label>
                    <Form.Control
                      type='number'
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Table
              hover
              className='user-table align-items-center text-black'
            >
              <thead>
                <tr>
                  <th className='border-bottom'>Title</th>
                  <th className='border-bottom'>Banner</th>
                  <th className='border-bottom'>Action</th>
                </tr>
              </thead>
              <tbody>
                {campaigns.results.map((campaign) => (
                  <TableRow
                    key={`campaign-${campaign.id}`}
                    {...campaign}
                  />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </MyPagination>
    </div>
  );
};

export default CampaignList;
