import React, { useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { renderActiveShape, renderCustomizedLabel, assignColor } from '.';

const ActiveChartWithLabel = ({
  data,
  chartId,
  label,
  noLabel = false,
  dataKey = 'frequency',
  nameKey = 'category',
  inverseLikert = false,
  height = 500,
  width = 500,
  innerRadius = 70,
  outerRadius = 200,
  hideActiveShape = false,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => setActiveIndex(index);

  return (
    <div>
      {!noLabel && (
        <h3 className='fw-bolder text-primary text-center pt-3 pb-3 h5'>
          {label}
        </h3>
      )}
      <PieChart
        width={width}
        height={height}
        id={chartId}
      >
        <Pie
          activeIndex={activeIndex}
          activeShape={hideActiveShape ? undefined : renderActiveShape}
          data={data}
          labelLine={false}
          label={renderCustomizedLabel}
          cx='50%'
          cy='50%'
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill='#180040'
          dataKey={dataKey}
          nameKey={nameKey}
          onMouseEnter={onPieEnter}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={assignColor(entry.category, index, inverseLikert)}
            />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};

export default ActiveChartWithLabel;
