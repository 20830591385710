/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Row, Col, Image } from '@themesberg/react-bootstrap';
import { dhnOldLogo, r0gLogo, civicWatchMungo } from '../../assets/img';
import { Link } from 'react-router-dom';
import { Routes } from '../../config/routes';

const PublicFooter = () => {
  return (
    <footer className='footer section py-0 pt-3  bg-tertiary'>
      <Row className='px-3 mx-5 py-5 sm-px-1 sm-mx-1'>
        <Col
          xs={12}
          md={4}
          lg={4}
          className='me-5'
        >
          <h4 className='h4 mb-3 text-primary fw-bold'>Powered By</h4>
          <Row>
            <Col className='py-3 pl-2 pr-2'>
              <a
                href='https://defyhatenow.org'
                target='_blank'
              >
                <Image src={dhnOldLogo} />
              </a>
            </Col>
            <Col className='py-3 px-2'>
              <a
                href='https://openculture.agency/'
                target='_blank'
              >
                <Image src={r0gLogo} />
              </a>
            </Col>
            <Col className='py-3 px-2'>
              <Image src={civicWatchMungo} />
            </Col>
          </Row>
        </Col>
        <Col
          xs={12}
          md={4}
          lg={4}
        >
          <h4 className='h4 mb-3 text-primary fw-bold'>More Links</h4>
          <Link to={Routes.Incidents.path}>
            <h4 className='h6 text-black'>Incidents</h4>
          </Link>
          <Link to={Routes.Reports.path}>
            <h4 className='h6 text-black'>Reports</h4>
          </Link>
          <Link to={Routes.Hotspots.path}>
            <h4 className='h6 text-black'>Hotspots</h4>
          </Link>
        </Col>
        <Col
          xs={12}
          md={3}
        >
          <h4 className='h4 mb-3 text-primary fw-bold'>Contact Us</h4>
          <a href='#!'>
            <h4 className='h6 text-black'>ewer@defyhatenow.org</h4>
          </a>
        </Col>
      </Row>
      <Row className='bg-primary'>
        <h3 className='text-lg-right text-center text-white h6 py-3 pr-lg-2 px-xs-3'>
          The contents of this website are the sole responsibility of The Mungo
          and do not necessarily reflect the view of the partners
        </h3>
      </Row>
    </footer>
  );
};

export default PublicFooter;
