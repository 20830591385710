import { toast } from 'react-toastify';
import config from '../config/config';

const uploadToCloudinary = async (file, fileName) => {
  const toastId = 'cloudinary-status';
  toast('Please wait...', {
    isLoading: true,
    type: toast.TYPE.DEFAULT,
    toastId: toastId,
  });
  try {
    let formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('upload_preset', config.cloudinary.uploadPreset);
    formData.append('cloud_name', config.cloudinary.cloudName);
    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${config.cloudinary.cloudName}/image/upload`,
      { method: 'POST', body: formData }
    );
    const data = await response.json();
    toast.update(toastId, {
      isLoading: false,
      type: toast.TYPE.SUCCESS,
      autoClose: 1000,
    });
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 1000);
    return data.secure_url;
  } catch (error) {
    console.error(error);
    toast.error(
      'It seems there is a problem with the image uploader. Contact admin'
    );
    toast.update(toastId, {
      render: error.message,
      isLoading: false,
      type: toast.TYPE.ERROR,
      autoClose: 1000,
    });
    setTimeout(() => {
      toast.dismiss(toastId);
    }, 1000);
  }
};

export default uploadToCloudinary;
