import { createSlice } from '@reduxjs/toolkit';
import { subtractMonths } from '../../utils/reusableFunctions';

const now = Date.now();

const initialState = {
  finalDate: now,
  initialDate: subtractMonths(now, 6),
  filterKey: 'incidentDate',
};

const dateRangeFilterSlice = createSlice({
  name: 'incidentDateFilter',
  initialState,
  reducers: {
    finalDateFilterChanged(state, action) {
      state.finalDate = action.payload;
    },
    initialDateFilterChanged(state, action) {
      state.initialDate = action.payload;
    },
    filterKeyChanged(state, action) {
      state.filterKey = action.payload;
    },
  },
});

export const {
  finalDateFilterChanged,
  initialDateFilterChanged,
  filterKeyChanged,
} = dateRangeFilterSlice.actions;

export default dateRangeFilterSlice.reducer;

export const selectInitialDate = (state) =>
  state.incidentDateFilter.initialDate;
export const selectFinalDate = (state) => state.incidentDateFilter.finalDate;
