import React, { useState } from 'react';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faFilter,
  faPlus,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Card,
  Accordion,
  Button,
  Table,
  InputGroup,
  Form,
} from '@themesberg/react-bootstrap';
import { formatDate, getWords } from '../../utils/reusableFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { CustomToast, Loader, MyPagination } from '../../components/common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config/routes';
import { paginateList } from '../../utils/reusableFunctions';
import {
  selectFinalDate,
  selectInitialDate,
  finalDateFilterChanged,
  initialDateFilterChanged,
} from '../../features/incidents/incidents.dateFilters.slice';
import { useGetCategoriesQuery } from '../../features/categories/categories.api';
import {
  useGetIncidentsQuery,
  useUpdatePublicationMutation,
} from '../../features/incidents/incidents.api';

const IncidentsTable = () => {
  const dispatch = useDispatch();
  const [publishIncident] = useUpdatePublicationMutation();
  const { categories } = useGetCategoriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      categories: data ? data.results : [],
    }),
  });
  const { incidents } = useGetIncidentsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      incidents: data ? data.results : [],
    }),
  });
  const incidentsPerPage = 10;
  const initialDate = useSelector(selectInitialDate);
  const finalDate = useSelector(selectFinalDate);

  const [currentPage, setCurrentPage] = useState(1);
  let incidentList = paginateList(incidents, currentPage, incidentsPerPage);

  const handlePageClicked = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(incidents.length / incidentsPerPage);

  const FilterAccordion = () => (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          <FontAwesomeIcon
            icon={faFilter}
            className='me-2'
          />
        </Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='published'>
                  <Form.Label>Published</Form.Label>
                  <Form.Select defaultValue={true}>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='category'>
                  <Form.Label>Category</Form.Label>
                  <Form.Select defaultValue={categories[0].title}>
                    {categories.map((c) => (
                      <option
                        key={c.id}
                        value={c.id}
                      >
                        {c.title}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='initialDate'>
                  <Form.Label>Initial Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type='text'
                          value={
                            initialDate ? formatDate(initialDate, '/') : ''
                          }
                          placeholder='mm/dd/yyyy'
                          onFocus={openCalendar}
                          onChange={(e) =>
                            dispatch(initialDateFilterChanged(e.target.value))
                          }
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='finalDate'>
                  <Form.Label>Final Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    value={finalDate ? formatDate(finalDate, '/') : ''}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type='text'
                          value={finalDate ? formatDate(finalDate, '/') : ''}
                          placeholder='mm/dd/yyyy'
                          onFocus={openCalendar}
                          onChange={(e) =>
                            dispatch(finalDateFilterChanged(e.target.value))
                          }
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );

  const Header = () => (
    <div className='table-settings mb-4 mt-4'>
      <Row className='justify-content-between align-items-center'>
        <Col>
          <Button
            variant='primary'
            size='sm'
            className='me-2'
            as={Link}
            to={Routes.NewIncident.path}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className='me-2'
            />
            New Incident
          </Button>
        </Col>
        <Col className='ps-md-0 text-end'>
          <FilterAccordion />
        </Col>
      </Row>
    </div>
  );

  const TableRow = (props) => {
    const { id, title, isPublished, incidentDate, updatedAt } = props;
    const isPublishedVariant = isPublished ? 'danger' : 'success';

    return (
      <tr>
        <td>
          <span className='fw-normal'>{getWords({ str: title, num: 5 })}</span>
        </td>
        <td>
          <span className='fw-normal'>{formatDate(updatedAt, '/')}</span>
        </td>
        <td>
          <span className='fw-normal'>{formatDate(incidentDate, '/')}</span>
        </td>
        <td>
          <Button
            variant={isPublishedVariant}
            size='sm'
            className='me-2'
            onClick={() => {
              publishIncident({ id });
            }}
          >
            {isPublished ? 'Unpublish' : 'Publish'}
          </Button>
        </td>
        <td>
          <Button
            variant='primary'
            type='submit'
            as={Link}
            to={`${Routes.IncidentEdit.path}/${id}`}
          >
            <FontAwesomeIcon
              icon={faEdit}
              className='me-2'
            />{' '}
            Edit
          </Button>
        </td>
      </tr>
    );
  };

  return (
    <Loader loaded={!!incidents.length && !!categories.length}>
      <CustomToast />
      <Header />
      <MyPagination
        totPages={totalPages}
        currentPage={currentPage}
        pageClicked={(page) => {
          handlePageClicked(page);
        }}
      >
        <Card
          border='light'
          className='table-wrapper table-responsive shadow-sm'
        >
          <Card.Body className='pt-0'>
            <Table
              hover
              className='user-table align-items-center text-black'
            >
              <thead>
                <tr>
                  <th className='border-bottom'>Title</th>
                  <th className='border-bottom'>Last Updated</th>
                  <th className='border-bottom'>Incident Date</th>
                  <th className='border-bottom'>Published</th>
                  <th className='border-bottom'>Action</th>
                </tr>
              </thead>
              <tbody>
                {incidentList.map((r) => (
                  <TableRow
                    key={`incident-${r.id}`}
                    {...r}
                  />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </MyPagination>
    </Loader>
  );
};

export default IncidentsTable;
