import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: 60,
  },
  centerPage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fff',
  },
  section: {
    marginBottom: 30,
  },
  centerSection: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  centerBetweenSection: {
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  parentSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    fontWeight: 900,
    color: 'black',
    textAlign: 'left',
    paddingBottom: 10,
  },
  text: {
    fontSize: 11,
    fontWeight: 400,
    color: 'black',
    textAlign: 'left',
    paddingVertical: 5,
  },
  boldText: {
    fontWeight: 900,
  },
  logo: {
    height: 100,
  },
  wrappedImage: {
    margin: 5,
    width: 400,
  },
});

export default styles;
