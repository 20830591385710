import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faPlus,
  faUpload,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Button, Table } from '@themesberg/react-bootstrap';
import { CustomToast, Loader, MyPagination } from '../../components/common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config/routes';
import { paginateList } from '../../utils/reusableFunctions';
import config from '../../config/config';
import { useGetRegionsQuery } from '../../features/regions/regions.api';
import {
  usePublishTilesetMutation,
  useUpdateTilesetSourceMutation,
} from '../../features/regions/tilesets.api';

const RegionsTable = () => {
  const [updateTilesetSource] = useUpdateTilesetSourceMutation();
  const [pubishTileset] = usePublishTilesetMutation();
  const { regions } = useGetRegionsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      regions: data ? data.results : [],
    }),
  });
  const regionsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  let regionList = paginateList(regions, currentPage, regionsPerPage);

  const handlePageClicked = (page) => {
    setCurrentPage(page);
  };

  async function handleUpdateTilesetSource() {
    await updateTilesetSource();
  }

  async function handlePublishTileset() {
    await pubishTileset({ id: config.TilesetID });
  }

  const totalPages = Math.ceil(regions.length / regionsPerPage);

  const Header = () => (
    <div className='table-settings mb-4 mt-4'>
      <Row className='justify-content-between align-items-center'>
        <Col className='text-end'>
          <Button
            variant='primary'
            size='sm'
            className='me-2'
            as={Link}
            to={Routes.NewRegion.path}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className='me-2'
            />
            New Region
          </Button>
        </Col>
      </Row>
    </div>
  );

  const TableRow = (props) => {
    const { id, properties } = props;

    return (
      <>
        <tr>
          <td>
            <span className='fw-normal'>{id}</span>
          </td>
          <td>
            <span className='fw-normal'>{properties.name}</span>
          </td>
          <td>
            <span className='fw-normal'>{properties.incidenceLevel}</span>
          </td>
          <td>
            <Button
              variant='primary'
              type='submit'
              as={Link}
              to={`${Routes.RegionEdit.path}/${id}`}
            >
              <FontAwesomeIcon
                icon={faEdit}
                className='me-2'
              />{' '}
              Edit
            </Button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <Loader loaded={!!regions.length}>
      <CustomToast />
      <Header />
      <MyPagination
        totPages={totalPages}
        currentPage={currentPage}
        pageClicked={(page) => {
          handlePageClicked(page);
        }}
      >
        <Card
          border='light'
          className='table-wrapper table-responsive shadow-sm'
        >
          <Card.Body className='pt-0'>
            <Table
              hover
              className='user-table align-items-center text-black'
            >
              <thead>
                <tr>
                  <th className='border-bottom'>OSM ID</th>
                  <th className='border-bottom'>Name</th>
                  <th className='border-bottom'>Incidence Level</th>
                  <th className='border-bottom'>Action</th>
                </tr>
              </thead>
              <tbody>
                {regionList.map((r) => (
                  <TableRow
                    key={`region-${r.id}`}
                    {...r}
                  />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </MyPagination>
      <div className='table-settings mb-4 mt-4'>
        <h4 className='text-primary fw-bold h4'>Map Actions</h4>
        <Row className='justify-content-between align-items-center mt-4'>
          <Col
            md={6}
            className='text-start'
          >
            <Button
              variant='primary'
              size='sm'
              className='me-2'
              onClick={handleUpdateTilesetSource}
            >
              <FontAwesomeIcon
                icon={faWrench}
                className='me-2'
              />
              Update Tileset Source
            </Button>
          </Col>
          <Col
            md={6}
            className='ps-md-0 text-end'
          >
            <Button
              variant='primary'
              size='sm'
              className='me-2'
              onClick={handlePublishTileset}
            >
              <FontAwesomeIcon
                icon={faUpload}
                className='me-2'
              />
              Publish Tileset
            </Button>
          </Col>
        </Row>
      </div>
    </Loader>
  );
};

export default RegionsTable;
