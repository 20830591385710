import {
  createSlice,
  createSelector,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import {
  searchAndSort,
  deleteEmptyProps,
  sortArrayUsingDate,
} from '../../utils/reusableFunctions';
import Api from '../../utils/network';

const api = new Api();

const reportsAdapter = createEntityAdapter();

const initialState = reportsAdapter.getInitialState({
  loading: false,
  loaded: false,
});

export const fetchReports = createAsyncThunk(
  'reports/fetchReports',
  async () => {
    const res = await api.Reports().getAllReports();
    return res.data.results;
  }
);

const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    reportAdded: reportsAdapter.addOne,
    reportDeleted: reportsAdapter.removeOne,
    reportUpdated: reportsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReports.pending, (state, action) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchReports.fulfilled, (state, action) => {
        reportsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.loaded = true;
      })
      .addCase(fetchReports.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { reportAdded, reportDeleted, reportUpdated } =
  reportSlice.actions;

export default reportSlice.reducer;

export const { selectAll: selectReports, selectById: selectReportById } =
  reportsAdapter.getSelectors((state) => state.reports);

export const SelectFilteredreports = createSelector(
  selectReports,
  (state) => state.reportFilters,
  (state) => state.reportSorting,
  (reports, reportFilters, reportSorting) =>
    searchAndSort(reports, deleteEmptyProps(reportFilters), reportSorting)
);

export const SelectSortedReports = createSelector(selectReports, (reports) =>
  sortArrayUsingDate(reports, 'createdAt', { ascending: false })
);

export const selectReportLoading = (state) => state.reports.loading;
export const selectReportsLoaded = (state) => state.reports.loaded;
