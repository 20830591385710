import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    role: '',
    name: '',
    isEmailVerified: 'All',
    approved: 'All',
}

const userFilterSlice = createSlice({
    name: 'userFilters',
    initialState,
    reducers: {
        roleFilterChanged(state, action){
            state.role = action.payload
        },
        nameFilterChanged(state, action){
            state.name = action.payload
        },
        isEmailVerifiedFilterChanged(state, action){
            state.isEmailVerified = action.payload
        },
        approvedFilterChanged(state, action){
            state.approved = action.payload
        },
        allFiltersChanged(state, action){
            const { nameFilter, roleFilter, isEmailVerifiedFilter, approvedFilter} = action.payload;
            state.name = nameFilter;
            state.role = roleFilter;
            state.isEmailVerified = isEmailVerifiedFilter;
            state.approved = approvedFilter;
        }
    }
})

export const { roleFilterChanged, nameFilterChanged, isEmailVerifiedFilterChanged, approvedFilterChanged, allFiltersChanged } = userFilterSlice.actions;

export default userFilterSlice.reducer;

export const selectNameFilter = state => state.userFilters.name;
export const selectRoleFilter = state => state.userFilters.role;
export const selectIsEmailVerifiedFilter = state => state.userFilters.isEmailVerified;
export const selectApprovedFilter = state => state.userFilters.approved;
