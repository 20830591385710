import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import Api from '../../utils/network';

const api = new Api();

const regionsAdapter = createEntityAdapter();

const initialState = regionsAdapter.getInitialState({
  loading: false,
  loaded: false,
});

export const fetchRegions = createAsyncThunk(
  'regions/fetchRegions',
  async () => {
    const res = await api.Regions().getAllRegions();
    return res.data.results;
  }
);

const regionSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    regionAdded: regionsAdapter.addOne,
    regionDeleted: regionsAdapter.removeOne,
    regionUpdated: regionsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state, action) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        regionsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.loaded = true;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { regionAdded, regionDeleted, regionUpdated } =
  regionSlice.actions;

export default regionSlice.reducer;

export const { selectAll: selectRegions, selectById: selectRegionById } =
  regionsAdapter.getSelectors((state) => state.regions);

export const selectRegionLoading = (state) => state.regions.loading;
export const selectRegionsLoaded = (state) => state.regions.loaded;
