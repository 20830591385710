import React from 'react';
import { Card } from '@themesberg/react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { icon } from 'leaflet';
import { selectIcon } from '../../utils/reusableFunctions';

import config from '../../config/config';

const mapConfig = {
  maxZoom: 18,
  id: 'mapbox/light-v10',
  url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${config.mapBoxToken}`,
  attribution:
    'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
  accessToken: config.mapBoxToken,
};

const SingleIncidentMap = ({ location, locationName, category }) => {
  const MapIcon = icon({
    iconUrl: selectIcon(category.id ? category.id : category),
    iconSize: [24, 24], // size of the icon
    shadowSize: [24, 24], // size of the shadow
    iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
    shadowAnchor: [12, 24], // the same for the shadow
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });

  return (
    <Card>
      <MapContainer
        id='mapbox'
        center={[location.coordinates[1], location.coordinates[0]]}
        zoom={10}
      >
        <TileLayer {...mapConfig} />
        <Marker
          key={`map-marker-${location.id}`}
          position={[location.coordinates[1], location.coordinates[0]]}
          icon={MapIcon}
        >
          <Popup>
            <Card className='card-article-wide border-0 flex-column no-gutters no-hover'>
              <Card.Body className='d-flex flex-column justify-content-between col-12 py-0'>
                <h5 className='fw-normal mb-2'>{locationName}</h5>
              </Card.Body>
            </Card>
          </Popup>
        </Marker>
      </MapContainer>
    </Card>
  );
};

export default SingleIncidentMap;
