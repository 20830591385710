import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Datetime from 'react-datetime';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomToast, Loader } from '../../components/common';
import { Routes } from '../../config/routes';
import { formatDate } from '../../utils/reusableFunctions';
import {
  useGetUsersQuery,
  SelectAdvancedUsers,
} from '../../features/users/users.api';
import { useGetCategoriesQuery } from '../../features/categories/categories.api';
import { useCreateIncidentMutation } from '../../features/incidents/incidents.api';

const NewIncident = () => {
  let history = useHistory();
  const [createIncident, { isLoading: creating }] = useCreateIncidentMutation();
  const { users } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => ({
      users: data ? data.results : [],
    }),
  });
  const authors = useSelector(SelectAdvancedUsers);
  const { categories } = useGetCategoriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      categories: data ? data.results : [],
    }),
  });

  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [links, setLinks] = useState([]);
  const [category, setCategory] = useState('');
  const [incidentDate, setIncidentDate] = useState('');
  const [locationName, setLocationName] = useState('');
  const [deaths, setDeaths] = useState(0);
  const [wounded, setWounded] = useState(0);
  const [peopleInvolved, setPeopleInvolved] = useState(0);

  async function handleCreateIncident(e) {
    e.stopPropagation();
    e.preventDefault();

    if (
      title === '' ||
      author === '' ||
      description === '' ||
      locationName === '' ||
      longitude === '' ||
      latitude === '' ||
      links === '' ||
      category === '' ||
      incidentDate === '' ||
      deaths === '' ||
      wounded === ''
    ) {
      toast.error('Please fill in all the fields');
    } else {
      const body = {
        title,
        author,
        description,
        location: {
          type: 'Point',
          coordinates: [longitude, latitude],
        },
        links,
        category,
        incidentDate,
        locationName,
        deaths,
        wounded,
        peopleInvolved,
      };

      await createIncident(body);
      history.push(Routes.IncidentsTable.path);
    }
  }

  return (
    <Loader
      loaded={
        !!users.length && !!categories.length && !!authors.length && !creating
      }
    >
      <CustomToast />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Body>
          <h5 className='mb-4'>Add Incident</h5>
          <Form>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='title'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Enter the incident title'
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='author'>
                  <Form.Label>Author</Form.Label>
                  <Form.Select onChange={(e) => setAuthor(e.target.value)}>
                    {authors.map((author) => (
                      <option value={author.id}>{author.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center'>
              <Col className='mb-3'>
                <Form.Group id='description'>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    required
                    as='textarea'
                    placeholder='Describe the incident'
                    style={{ height: '20vh' }}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center mb-3'>
              <Form.Group id='pdf'>
                <Form.Label>Links</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder='www.incidentOne.com'
                  onChange={(e) => setLinks(e.target.value.split(','))}
                />
              </Form.Group>
            </Row>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='incidentDate'>
                  <Form.Label>Incident Date</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={setIncidentDate}
                    initialValue={formatDate(Date.now(), '/')}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type='text'
                          value={formatDate(incidentDate, '/')}
                          placeholder='mm/dd/yyyy'
                          onFocus={openCalendar}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='category'>
                  <Form.Label>Category</Form.Label>
                  <Form.Select onChange={(e) => setCategory(e.target.value)}>
                    {categories.map((cat) => (
                      <option value={cat.id}>{cat.title}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <h2 className='text-primary text-center h6'>Casualties</h2>
            <Row className='align-items-center'>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='wounded'>
                  <Form.Label>Wounded</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Number of wounded casualties'
                    onChange={(e) => setWounded(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='deaths'>
                  <Form.Label>Deaths</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Number of dead casualties'
                    onChange={(e) => setDeaths(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='peopleInvolved'>
                  <Form.Label>People Involved</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='People Involved in the Incident'
                    onChange={(e) => setPeopleInvolved(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <h2 className='text-primary text-center h6'>Location</h2>
            <Row>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='longitude'>
                  <Form.Label>Longitude</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Incident location longitude'
                    onChange={(e) => setLongitude(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='latitude'>
                  <Form.Label>Latitude</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Incident location latitude'
                    onChange={(e) => setLatitude(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={4}
                className='mb-3'
              >
                <Form.Group id='locationName'>
                  <Form.Label>Location Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Name of the location where incident happened'
                    onChange={(e) => setLocationName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='mt-3'>
              <Button
                variant='primary'
                type='submit'
                onClick={handleCreateIncident}
              >
                Create
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Loader>
  );
};

export default NewIncident;
