import React from 'react';
import { Row, Col, Card } from '@themesberg/react-bootstrap';

const FooterAlt = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className='footer section mb-5 p-0 text-center'>
      <Row>
        <Col
          xs={12}
          className='mb-4 mb-lg-0'
        >
          <p className='mb-0 text-center'>
            Copyright © {`${currentYear} `}
            <Card.Link
              href='https://defyhatenow.org'
              target='_blank'
              className='text-blue text-decoration-none fw-normal'
            >
              #defyhatenow
            </Card.Link>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default FooterAlt;
