import React, { useState } from 'react';
import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import {
  ChoosePhotoWidget,
  CustomToast,
  Loader,
} from '../../components/common';
import { avatar } from '../../assets/img';
import { toast } from 'react-toastify';
import {
  useGetSingleUserQuery,
  useUpdateUserMutation,
} from '../../features/users/users.api';

export default () => {
  const [updateUser, { isLoading }] = useUpdateUserMutation();
  const id = localStorage.getItem('userId');
  const { data: user } = useGetSingleUserQuery({ id });

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');

  async function handleUserUpdate(e) {
    e.stopPropagation();
    e.preventDefault();

    if (name === '' || email === '' || password === '') {
      toast.error('Please fill in all the fields');
    } else {
      await updateUser(user.id, { name, email, password })
        .unwrap()
        .then(() => {
          toast.success('Profile was changed successfully');
        });
    }
  }

  return (
    <Loader loaded={!isLoading}>
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <CustomToast />
        <Card.Body>
          <Card.Subtitle className='fw-normal'>{user.role}</Card.Subtitle>
          <Card.Text className='text-gray mb-4'>{user.email}</Card.Text>
          <Form>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <ChoosePhotoWidget
                  title='Select profile photo'
                  photo={user.avatar ? user.avatar : avatar}
                />
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='firstName'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Enter your name'
                    defaultValue={user.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='email'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type='email'
                    placeholder='name@company.com'
                    defaultValue={user.email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='email'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    required
                    type='password'
                    placeholder='Password'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className='mt-3'>
              <Button
                variant='primary'
                type='submit'
                onClick={handleUserUpdate}
              >
                Save
              </Button>
            </div>
          </Form>
        </Card.Body>
        <Card.Footer>
          <div>
            Icons made by{' '}
            <a
              href='https://www.flaticon.com/authors/smashicons'
              title='Smashicons'
            >
              Smashicons
            </a>{' '}
            from{' '}
            <a
              href='https://www.flaticon.com/'
              title='Flaticon'
            >
              www.flaticon.com
            </a>
          </div>
        </Card.Footer>
      </Card>
    </Loader>
  );
};
