import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useGetSingleUserQuery } from '../../features/users/users.api';
import { useLocation, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faFire,
  faChartPie,
  faFlag,
  faUsers,
  faCog,
  faSignOutAlt,
  faTimes,
  faLayerGroup,
  faLocationArrow,
  faQuoteLeft,
  faDatabase,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import {
  Nav,
  Badge,
  Image,
  Button,
  Dropdown,
  Navbar,
} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { restrictions, Routes } from '../../config';
import { dhnLogoTransparent, logoYellow, avatar } from '../../assets/img';
import { useLogoutMutation } from '../../features/auth/auth.api';
import { resetAuth } from '../../app/api';

export default (props = {}) => {
  const id = localStorage.getItem('userId');
  const { data: user } = useGetSingleUserQuery({ id });
  const [logoutUser] = useLogoutMutation();
  let history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const contracted = props.contracted ? 'contracted' : '';
  const showClass = show ? 'show' : '';

  const onCollapse = () => setShow(!show);
  const onMouseEnter = () => props.onMouseEnter && props.onMouseEnter();
  const onMouseLeave = () => props.onMouseLeave && props.onMouseLeave();

  const events = isMobile ? {} : { onMouseEnter, onMouseLeave };

  async function logout() {
    await logoutUser({
      refreshToken: localStorage.getItem('refreshToken'),
    }).then(() => {
      history.push(Routes.Signin.path);
      resetAuth();
      toast.success('Logout was successful');
    });
  }

  const NavItem = (props) => {
    const {
      title,
      link,
      target,
      icon,
      image,
      badgeText,
      badgeBg,
      badgeColor = 'white',
      restrictedTo = restrictions.none,
    } = props;
    const classNames = badgeText
      ? 'd-flex justify-content-between align-items-center border-0'
      : 'border-0';
    const navItemClassName = link === pathname ? 'active' : '';

    return (
      user &&
      restrictedTo.includes(user.role) && (
        <Nav.Item
          className={navItemClassName}
          onClick={() => setShow(false)}
        >
          <Nav.Link
            as={Link}
            to={link}
            target={target}
            className={classNames}
          >
            <span>
              {icon ? (
                <span className='sidebar-icon'>
                  <FontAwesomeIcon icon={icon} />{' '}
                </span>
              ) : null}
              {image ? (
                <Image
                  src={image}
                  width={20}
                  height={20}
                  className='sidebar-icon svg-icon'
                />
              ) : null}

              {!show && contracted && !icon && !image ? (
                <span className='sidebar-text-contracted'>{title[0]}</span>
              ) : null}

              <span className='sidebar-text'>{title}</span>
            </span>
            {badgeText ? (
              <Badge
                pill
                bg={badgeBg}
                text={badgeColor}
                className='badge-md notification-count'
              >
                {badgeText}
              </Badge>
            ) : null}
          </Nav.Link>
        </Nav.Item>
      )
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant='dark'
        className='navbar-theme-primary px-4 d-md-none'
      >
        <Navbar.Brand
          as={Link}
          className='me-lg-5'
          to={Routes.DashboardOverview.path}
        >
          <Image
            src={logoYellow}
            className='navbar-brand-light'
          />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls='main-navbar'
          onClick={onCollapse}
        >
          <span className='navbar-toggler-icon' />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition
        timeout={300}
        in={show}
        classNames='sidebar-transition'
      >
        <SimpleBar
          {...events}
          className={`${contracted} collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className='sidebar-inner px-4 py-3'>
            <div className='user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4'>
              <div className='d-flex align-items-center'>
                <div className='user-avatar lg-avatar me-4'>
                  <Image
                    src={user.avatar ? user.avatar : avatar}
                    className='card-img-top rounded-circle border-white'
                  />
                </div>
                <div className='d-block'>
                  <h6>Hi, {user.name}</h6>
                  <Button
                    variant='secondary'
                    size='xs'
                    onClick={logout}
                    className='text-dark'
                  >
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className='me-2'
                    />{' '}
                    Sign Out
                  </Button>
                </div>
              </div>
              <Nav.Link
                className='collapse-close d-md-none'
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className='flex-column pt-3 pt-md-0'>
              <NavItem
                title='Go Home'
                link={Routes.Home.path}
                icon={faExternalLinkAlt}
                restrictedTo={restrictions.none}
              />

              <NavItem
                title='Portal'
                link={Routes.Sentiment.path}
                icon={faExternalLinkAlt}
                restrictedTo={restrictions.none}
              />

              <NavItem
                title='Overview'
                link={Routes.DashboardOverview.path}
                icon={faChartPie}
                restrictedTo={restrictions.none}
              />
              <NavItem
                title='Settings'
                icon={faCog}
                link={Routes.Settings.path}
                restrictedTo={restrictions.none}
              />

              <NavItem
                title='Incidents'
                icon={faFire}
                link={Routes.IncidentsTable.path}
                restrictedTo={restrictions.author}
              />
              <NavItem
                title='Reports'
                icon={faFlag}
                link={Routes.ReportsTable.path}
                restrictedTo={restrictions.author}
              />
              <NavItem
                title='Categories'
                icon={faLayerGroup}
                link={Routes.CategoriesTable.path}
                restrictedTo={restrictions.author}
              />
              <NavItem
                title='Keywords'
                icon={faQuoteLeft}
                link={Routes.KeywordsTable.path}
                restrictedTo={restrictions.author}
              />
              <NavItem
                title='Regions'
                icon={faLocationArrow}
                link={Routes.RegionsTable.path}
                restrictedTo={restrictions.author}
              />
              <NavItem
                title='Summary'
                icon={faDatabase}
                link={Routes.Summary.path}
                restrictedTo={restrictions.author}
              />
              <NavItem
                title='Campaigns'
                icon={faBullhorn}
                link={Routes.CampaignList.path}
                restrictedTo={restrictions.author}
              />

              <NavItem
                title='Users'
                icon={faUsers}
                link={Routes.UsersTable.path}
                restrictedTo={restrictions.admin}
              />

              <Dropdown.Divider className='my-3 border-indigo' />
              <NavItem
                external
                title='#defyHateNow'
                link='https://defyhatenow.org'
                target='_blank'
                image={dhnLogoTransparent}
              />
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
