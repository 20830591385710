import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Modal,
} from '@themesberg/react-bootstrap';
import { CustomToast, Loader } from '../../components/common';
import { Routes } from '../../config/routes';
import {
  useDeleteKeywordMutation,
  useGetSingleKeywordQuery,
  useUpdateKeywordMutation,
} from '../../features/keywords/keywords.api';

const EditKeyword = () => {
  const { id } = useParams();
  const { data: keyword } = useGetSingleKeywordQuery({ id });
  const [updateKeyword] = useUpdateKeywordMutation();
  const [deleteKeyword] = useDeleteKeywordMutation();
  let history = useHistory();

  const [text, setText] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleUpdateKeyword(e) {
    e.stopPropagation();
    e.preventDefault();

    const body = {
      text: text ?? keyword.text,
    };
    await updateKeyword({ id, body });
  }

  async function handleDeleteKeyword() {
    handleClose();
    history.push(Routes.KeywordsTable.path);
    await deleteKeyword({ id });
  }

  const CheckDelete = () => (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you want to delete {keyword.text}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deleting a keyword is an irreversible process. You cannot recover the
        information once a keyword is deleted
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          variant='secondary'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          onClick={handleDeleteKeyword}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (!keyword) return <Loader />;

  return (
    <Card
      border='light'
      className='bg-white shadow-sm mb-4'
    >
      <CustomToast />
      <CheckDelete />
      <Card.Body>
        <Form>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <Form.Group id='text'>
                <Form.Label>Text</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter keyword text'
                  defaultValue={keyword.text}
                  onChange={(e) => setText(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-between'>
            <Col className='text-start'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='danger'
                type='button'
                onClick={handleShow}
              >
                Delete Keyword
              </Button>
            </Col>
            <Col className='ps-md-0 text-end'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='primary'
                type='submit'
                onClick={handleUpdateKeyword}
              >
                Update Keyword
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EditKeyword;
