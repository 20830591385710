export const radian = Math.PI / 180;

export const colors = [
  '#0088FE',
  '#FCEA03',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#1B998B',
  '#ff0000',
  '#0000ff',
  '#f5b759',
  '#222222',
];

export const likertScale = {
  1: {
    color: '#005383',
    label: 'Very Low',
  },
  2: {
    color: '#0087bb',
    label: 'Low',
  },
  3: {
    color: '#5c6f7e',
    label: 'Medium',
  },
  4: {
    color: '#df3d26',
    label: 'High',
  },
  5: {
    color: '#aa0503',
    label: 'Very High',
  },
};

export const inverseLikertScale = {
  5: {
    color: '#005383',
    label: 'Very Low',
  },
  4: {
    color: '#0087bb',
    label: 'Low',
  },
  3: {
    color: '#5c6f7e',
    label: 'Medium',
  },
  2: {
    color: '#df3d26',
    label: 'High',
  },
  1: {
    color: '#aa0503',
    label: 'Very High',
  },
};

export const chartMetaData = {
  type: {
    id: 'type-chart',
    label: 'Type of Threat',
    descId: 'type-desc-chart',
  },
  believability: {
    id: 'believability-chart',
    label: 'Believability',
    descId: 'believability-desc-chart',
  },
  virality: {
    id: 'virality-chart',
    label: 'Virality',
    descId: 'virality-desc-chart',
  },
  likelihoodToCauseHarm: {
    id: 'likelihoodToCauseHarm-chart',
    label: 'Likelihood to Cause Harm',
    descId: 'likelihoodToCauseHarm-desc-chart',
  },
  factualness: {
    id: 'factualness-chart',
    label: 'Factualness',
    descId: 'factualness-desc-chart',
  },
  language: {
    id: 'language-chart',
    label: 'Language',
    descId: 'language-desc-chart',
  },
  platform: {
    id: 'platform-chart',
    label: 'Platform',
    descId: 'platform-desc-chart',
  },
};
