import React from 'react';
import { Row, Col, Card } from '@themesberg/react-bootstrap';

export default () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='footer section py-5 ps-5 sm-ps-1 sm-py-1'>
      <Row>
        <Col
          xs={12}
          lg={6}
          className='mb-4 mb-lg-0'
        >
          <p className='mb-0 text-center text-xl-left'>
            Copyright © {`${currentYear} `}
            <Card.Link
              href='https://defyhatenow.org'
              target='_blank'
              className='text-blue text-decoration-none fw-normal'
            >
              #defyhatenow
            </Card.Link>
          </p>
        </Col>
      </Row>
    </footer>
  );
};
