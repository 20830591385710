import React from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Form } from '@themesberg/react-bootstrap';
import WizardFooter from './WizardFooter';
import { LabelledChart } from '../summaryCharts';

const Step = ({
  description,
  setDescription,
  beforeNext = async () => {},
  data,
  chartId,
  label,
  inverseLikert = false,
  nextStep,
  previousStep,
  step,
  totalSteps,
  required = true,
}) => {
  const validate = async () => {
    if (description === '' && required) {
      toast.error('Please fill all form fields before proceeding!');
    } else {
      await beforeNext();
      nextStep();
    }
  };

  return (
    <div className='my-4 bg-white a'>
      <Row className='my-4 align-items-center justify-content-between'>
        <Col md={6}>
          <Form.Group id='text'>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              as='textarea'
              placeholder={`Please add a description for ${label}`}
              rows={10}
              defaultValue={description}
              onChange={(e) => setDescription(e.target.value)}
              className='text-black'
              required={required}
            />
          </Form.Group>
        </Col>
        <Col
          md={6}
          className='text-center'
        >
          <LabelledChart
            data={data}
            chartId={chartId}
            label={label}
            inverseLikert={inverseLikert}
            height={400}
            width={600}
            innerRadius={50}
            outerRadius={120}
            noLabel={true}
          />
        </Col>
      </Row>
      <WizardFooter
        previousStep={previousStep}
        nextStep={validate}
        step={step}
        totalSteps={totalSteps}
      />
    </div>
  );
};

export default Step;
