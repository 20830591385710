import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomToast, Loader } from '../../components/common';
import { useResetPasswordMutation } from '../../features/auth/auth.api';

import { Routes } from '../../config/routes';
import getToken from '../../utils/getToken';

export default () => {
  let history = useHistory();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [password, setPassword] = useState('');

  async function handleResetPassword(e) {
    e.stopPropagation();
    e.preventDefault();

    if (password === '') {
      toast.error('Please add a new password');
    } else {
      await resetPassword({
        body: { password },
        params: { token: `${getToken(15)}` },
      })
        .unwrap()
        .then(() => {
          history.push(Routes.Signin.path);
          toast.success(
            'Your change was successful. Try signing in with the new password'
          );
        });
    }
  }

  return (
    <main>
      <CustomToast />
      <Loader loaded={!isLoading}>
        <section className='bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5'>
          <Container>
            <Row className='justify-content-center'>
              <p className='text-center'>
                <Card.Link
                  as={Link}
                  to={Routes.Signin.path}
                  className='text-gray-700'
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className='me-2'
                  />{' '}
                  Back to sign in
                </Card.Link>
              </p>
              <Col
                xs={12}
                className='d-flex align-items-center justify-content-center'
              >
                <div className='bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                  <h3 className='mb-4'>Reset password</h3>
                  <Form>
                    <Form.Group
                      id='password'
                      className='mb-4'
                    >
                      <Form.Label>Your New Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type='password'
                          placeholder='Password'
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Button
                      variant='primary'
                      type='submit'
                      className='w-100'
                      onClick={handleResetPassword}
                    >
                      Reset password
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Loader>
    </main>
  );
};
