import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isPublished: true,
    category: 'All',
    author: 'All',
    editor: 'All',
    incidentDate: '',
}

const incidentFilterSlice = createSlice({
    name: 'incidentFilters',
    initialState,
    reducers: {
        isPublishedFilterChanged(state, action){
            state.isPublished = !state.isPublished
        },
        categoryFilterChanged(state, action){
            state.category = action.payload
        },
        authorFilterChanged(state, action){
            state.author= action.payload
        },
        editorFilterChanged(state, action){
            state.editor = action.payload
        },
        incidentDateFilterChanged(state, action){
            state.incidentDate = action.payload
        },
    }
})

export const { isPublishedFilterChanged, categoryFilterChanged, authorFilterChanged, editorFilterChanged, incidentDateFilterChanged } = incidentFilterSlice.actions;

export const selectCategory = state => state.incidentFilters.category;

export default incidentFilterSlice.reducer;
