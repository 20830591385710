import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { Row, Button } from '@themesberg/react-bootstrap';
import WizardFooter from './WizardFooter';

const DatetimeStep = ({
  year,
  month,
  setYear,
  setMonth,
  previousStep,
  nextStep,
  step,
  totalSteps,
}) => {
  return (
    <div>
      <Row className='mb-3'>
        <Datetime
          dateFormat='YYYY-MM'
          value={moment({ year, month })}
          timeFormat={false}
          onChange={(date) => {
            setMonth(date.month());
            setYear(date.year());
          }}
          renderInput={(props, openCalendar, closeCalendar) => (
            <Button
              onClick={openCalendar}
              className='primary p-3'
            >
              <small>{`Click to select month and year. Current date is: ${moment(
                {
                  year,
                  month,
                }
              ).format('MMMM YYYY')}`}</small>
            </Button>
          )}
        />
      </Row>
      <WizardFooter
        previousStep={previousStep}
        nextStep={nextStep}
        step={step}
        totalSteps={totalSteps}
      />
    </div>
  );
};

export default DatetimeStep;
