import { Container } from '@themesberg/react-bootstrap';
import React from 'react';
import { Layout } from '../../components/layout';
import { ReportList } from '../../components/reports';

const Reports = () => {
  return (
    <Layout>
      <Container
        fluid
        className='px-5 pb-5 px-lg-12 bg-white'
      >
        <h1 className='fw-bolder text-primary text-center pt-3 pb-3 h3'>
          Published Reports
        </h1>
        <ReportList />
      </Container>
    </Layout>
  );
};

export default Reports;
