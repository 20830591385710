import React from 'react';
import { Layout } from '../components/layout';
import { Card } from '@themesberg/react-bootstrap';
import { Legend } from '../components/incidents';

const Hotspots = () => (
  <Layout>
    <Card>
      <iframe
        width='100%'
        height='600px'
        src='https://api.mapbox.com/styles/v1/wafula-linus/ckwj0c63c62w915n00uw93clx.html?title=false&access_token=pk.eyJ1Ijoid2FmdWxhLWxpbnVzIiwiYSI6ImNrczRlNDBoZTAwaTAyb2xjbHBmam0xbzEifQ.5qaukS5ud675YR_HIv3owA&zoomwheel=false#5/6.27/12.89'
        title='Cameroon Incident Mapping Style'
        style={{ border: 'none' }}
      ></iframe>
      <div className='map-overlay'>
        <Legend />
      </div>
    </Card>
  </Layout>
);

export default Hotspots;
