import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    show: false,
}

const popupSlice = createSlice({
    name: 'popups',
    initialState,
    reducers: {
        closePopup(state, action){
            state.show = false
        },
        showPopup(state, action){
            state.show = true
        },
    }
})

export const { closePopup, showPopup } = popupSlice.actions;

export const selectPopStatus = state => state.popups.show;

export default popupSlice.reducer;
