import React, { useState } from 'react';
import { Col, Container, ListGroup, Card } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getWords } from '../../utils/reusableFunctions';
import styles from '../../css/icons.module.css';
import { Loader, MyPagination } from '../common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config';
import { paginateList } from '../../utils/reusableFunctions';
import { useGetIncidentsQuery } from '../../features/incidents/incidents.api';

const UnfilteredIncidentList = ({ len }) => {
  const { incidents } = useGetIncidentsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      incidents: data ? data.results : [],
    }),
  });

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  let itemList = paginateList(incidents, currentPage, itemsPerPage);
  const totalPages = Math.ceil(incidents.length / itemsPerPage);

  const handlePageClicked = (page) => {
    setCurrentPage(page);
  };

  const Incident = (props) => {
    const { title, description, id } = props;

    return (
      <ListGroup.Item className='px-0'>
        <Container>
          <Col className='col-auto'>
            <h4 className='h6 mb-0 text-primary'>
              <FontAwesomeIcon
                icon={faShieldAlt}
                className='me-2 text-secondary'
              />
              <Link to={`${Routes.Incidents.path}/${id}`}>{title}</Link>
            </h4>
            <small className='text-black'>
              {getWords({ str: description })}...
            </small>
            <br></br>
            <small className='mb-0 text-black'>
              Explore
              <Card.Link
                as={Link}
                to={`${Routes.Incidents.path}/${id}`}
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className={`ms-2 me-2 text-black ${styles.longIcon}`}
                  size='xs'
                />
              </Card.Link>
            </small>
          </Col>
        </Container>
      </ListGroup.Item>
    );
  };

  return (
    <Loader loaded={!!incidents.length}>
      <Card
        border='light'
        className='shadow-sm'
      >
        <Card.Header className='border-bottom d-flex justify-content-between bg-secondary text-black'>
          <h4 className='h6 mb-0'>Recent Incidents</h4>
        </Card.Header>
        <MyPagination
          totPages={totalPages}
          currentPage={currentPage}
          pageClicked={(page) => {
            handlePageClicked(page);
          }}
        >
          <Card.Body>
            <ListGroup className='list-group-flush list my--3'>
              {itemList.map((i) => (
                <Incident
                  key={i.id}
                  {...i}
                />
              ))}
            </ListGroup>
          </Card.Body>
        </MyPagination>
      </Card>
    </Loader>
  );
};

export default UnfilteredIncidentList;
