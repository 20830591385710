import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import Api from '../../utils/network';

const api = new Api();

const categoriesAdapter = createEntityAdapter();

const initialState = categoriesAdapter.getInitialState({
  loading: false,
  loaded: false,
});

export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const res = await api.Categories().getAllCategories();
    return res.data.results;
  }
);

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categoryAdded: categoriesAdapter.addOne,
    categoryDeleted: categoriesAdapter.removeOne,
    categoryUpdated: categoriesAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state, action) => {
        state.loading = true;
        state.loaded = false;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        categoriesAdapter.setAll(state, action.payload);
        state.loading = false;
        state.loaded = true;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { categoryAdded, categoryDeleted, categoryUpdated } =
  categorySlice.actions;

export default categorySlice.reducer;

export const { selectAll: selectCategories, selectById: selectCategoryById } =
  categoriesAdapter.getSelectors((state) => state.categories);

export const selectCategoryLoading = (state) => state.categories.loading;
export const selectCategoriesLoaded = (state) => state.categories.loaded;
