import React from "react";
import SomeLoader from 'react-loader';

const Loader = ({ loaded, children }) => {
    return(
        <SomeLoader loaded={loaded} className="loader">
            {children}
        </SomeLoader>
    );
};

export default Loader;