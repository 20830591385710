import React from 'react';
import { Nav, Navbar } from '@themesberg/react-bootstrap';
import { NavProfile } from '.';

export default () => {
  return (
    <Navbar
      variant='dark'
      expanded
      className='pe-2'
    >
      <Nav className='align-items-center justify-content-between w-100 px-5'>
        <h4 className='h4 fw-bold'>
          Welcome to the Early Warning Early Response Dashboard
        </h4>
        <NavProfile />
      </Nav>
    </Navbar>
  );
};
