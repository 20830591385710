import React, { useState } from 'react';
import mergeImages from 'merge-images';
import { LayoutAlt } from '../../components/layout';
import { useParams } from 'react-router-dom';
import { resizeFile } from '../../utils';
import { Col, Form } from '@themesberg/react-bootstrap';
import { Loader } from '../../components/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import config from '../../config/config';
import { useGetSingleCampaignQuery } from '../../features/frames/campaigns.api';

const CampaignItem = ({ mergedImage, title, description, url }) => (
  <div
    className='align-items-center my-3 sm-flex-col'
    style={{ display: 'flex' }}
  >
    <Col
      className='justify-content-center'
      style={{ display: 'flex' }}
    >
      <a
        href={mergedImage}
        download={`${title}.png`}
        style={{ display: 'flex', flexDirection: 'column' }}
        className='align-items-center'
      >
        <p
          className='text-center px-3 py-1 rounded fw-bold'
          style={{ cursor: 'pointer' }}
        >
          Click to download
        </p>
        <img
          src={mergedImage}
          alt='banner'
          width={400}
        />
      </a>
    </Col>
    <Col className='sm-flex-col sm-justify-center'>
      <h3 className='fw-bold fs-1 sm-text-center'>{title}</h3>
      <p className='sm-text-center'>{description}</p>
      <div
        style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}
        className='justify-content-start sm-justify-center'
      >
        <FacebookShareButton
          url={url}
          title={title}
        >
          <FacebookIcon
            size={32}
            round
          />
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title={title}
        >
          <TwitterIcon
            size={32}
            round
          />
        </TwitterShareButton>
        <TelegramShareButton
          url={url}
          title={title}
        >
          <TelegramIcon
            size={32}
            round
          />
        </TelegramShareButton>
        <WhatsappShareButton
          url={url}
          title={title}
          separator=':: '
        >
          <WhatsappIcon
            size={32}
            round
          />
        </WhatsappShareButton>
      </div>
    </Col>
  </div>
);

const CreateCampaign = () => {
  const { id } = useParams();
  const { data: campaign } = useGetSingleCampaignQuery({ id });
  const [mergedImage, setMergedImage] = useState(null);
  const [upload, setUpload] = useState(null);

  if (upload) {
    const img = new Image();
    img.src = URL.createObjectURL(upload);
    img.decode().then(() => {
      resizeFile(upload, img.height, img.width).then(async (result) => {
        const image1 = new Image();
        image1.src = result;
        mergeImages(
          [
            {
              src: result,
              x: (1200 - image1.width) / 2,
              y: (1200 - image1.height) / 3,
            },
            { src: campaign.banner, x: 0, y: 0 },
          ],
          { crossOrigin: 'anonymous' }
        ).then((image) => {
          setMergedImage(image);
        });
      });
    });
  }

  if (!campaign) return <Loader />;

  return (
    <LayoutAlt>
      <Form
        className='p-5 align-items-center h-100 w-100 sm-p-1'
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <label
          id='campaignLabel'
          htmlFor='campaign'
          className='rounded w-75 position-relative mb-4'
          style={{
            display: 'block',
            zIndex: 0,
            height: 112,
            cursor: 'pointer',
            border: '1px dashed',
          }}
        >
          <input
            id='cover'
            type='file'
            name='cover'
            className='h-100 w-100 position-absolute'
            onChange={(e) => {
              if (e.target.files) {
                setUpload(e.target.files[0]);
              }
            }}
            style={{
              top: 0,
              left: 0,
              opacity: 0,
              cursor: 'pointer',
              zIndex: 15,
            }}
          />
          <div
            className='align-items-center justify-content-between px-5 position-absolute'
            style={{ inset: 0, display: 'flex', zIndex: 10 }}
          >
            <div
              className='align-items-center justify-content-between w-100 h-75 sm-flex-col sm-justify-center'
              style={{ display: 'flex', gap: '1.25rem' }}
            >
              <p className='mb-0'>
                Upload your photo for the campaign. Max size 10MB
              </p>
              <div className='border border-primary py-2 px-5 rounded'>
                Browse
              </div>
            </div>
          </div>
        </label>
        {!upload ? (
          <img
            src={campaign.banner}
            alt='campaign'
            className='mb-4 sm-h-auto'
            height={400}
          />
        ) : !mergedImage ? (
          <div
            style={{ display: 'flex' }}
            className='justify-content-center align-items-center mb-4 h-100 w-100'
          >
            <FontAwesomeIcon
              icon={faSpinner}
              size='sm'
            />
          </div>
        ) : (
          <CampaignItem
            url={`${config.siteUrl}/campaigns/${campaign.id}`}
            mergedImage={mergedImage}
            title={campaign.title}
            description={campaign.description}
          />
        )}
      </Form>
    </LayoutAlt>
  );
};

export default CreateCampaign;
