import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';

const authAdapter = createEntityAdapter();

const initialState = authAdapter.getInitialState({
    status: false,
    showModal: true,
});

const authSlice = createSlice({
    name:'auth',
    initialState,
    reducers: {
        authAdded: authAdapter.addOne,
        authDeleted: authAdapter.removeOne,
        authUpdated: authAdapter.updateOne,
        authStatusUpdated(state, action){
            state.status = !state.status
        },
        showModalUpdated(state, action){
            state.showModal = !state.showModal
        },
        authReset: authAdapter.removeAll,
        clearStorage(state, action){
            sessionStorage.removeItem('persist:root');
        }
    }
    
})

export const {
    authAdded,
    authDeleted,
    authUpdated,
    authStatusUpdated,
    showModalUpdated,
    authReset,
    clearStorage,
} = authSlice.actions;

export default authSlice.reducer;

export const { selectAll } = authAdapter.getSelectors(state => state.auth);

export const useUser = createSelector(selectAll, users => users[0]);

export const selectModalStatus = state => state.auth.showModal;
export const selectAuthStatus = state => state.auth.status;
