import { api } from '../../app';

const apiWithKeywordTags = api.enhanceEndpoints({ addTagTypes: ['Keyword'] });

const keywordApi = apiWithKeywordTags.injectEndpoints({
  endpoints: (builder) => ({
    createKeyword: builder.mutation({
      query: (body) => ({
        url: 'keywords',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Keyword'],
    }),
    getKeywords: builder.query({
      query: (params) => ({
        url: 'keywords',
        method: 'GET',
        params,
      }),
      providesTags: (data) =>
        data && data.results
          ? [...data.results.map(({ id }) => ({ type: 'Keyword', id })), { type: 'Keyword', id: 'PARTIAL-KEYWORD-LIST' }]
          : [{ type: 'Keyword', id: 'PARTIAL-KEYWORD-LIST' }],
    }),
    getSingleKeyword: builder.query({
      query: ({ id }) => ({
        url: `keywords/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => (result ? [{ type: 'Keyword', id: result.id }] : ['Keyword']),
    }),
    updateKeyword: builder.mutation({
      query: ({ id, body }) => ({
        url: `keywords/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Keyword', id: arg.id },
        { type: 'Keyword', id: 'PARTIAL-KEYWORD-LIST' },
      ],
    }),
    deleteKeyword: builder.mutation({
      query: ({ id }) => ({
        url: `keywords/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Keyword', id: arg.id },
        { type: 'Keyword', id: 'PARTIAL-KEYWORD-LIST' },
      ],
    }),
  }),
});

export const {
    useCreateKeywordMutation,
    useDeleteKeywordMutation,
    useGetKeywordsQuery,
    useGetSingleKeywordQuery,
    useUpdateKeywordMutation,
    useLazyGetKeywordsQuery
} = keywordApi;
export default keywordApi;
