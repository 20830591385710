import React from 'react';

const PDFViewer = ({ report }) => {
    
    return (
        <iframe src={report.pdf} title={report.title} style={{ width: "100%", height: "1000px" }}></iframe>
    );
}

export default PDFViewer;
