import React, { useEffect } from 'react';
import { Layout } from '../../components/layout';
import { IncidentsWithFilters, LineChart } from '../../components/incidents';
import { Container, Row } from '@themesberg/react-bootstrap';
import { useDispatch } from 'react-redux';
import { categoryFilterChanged } from '../../features/incidents/incidents.filter.slice';
import {
  initialDateFilterChanged,
  finalDateFilterChanged,
} from '../../features/incidents/incidents.dateFilters.slice';
import { subtractMonths } from '../../utils/reusableFunctions';
import { incidentsLegend } from '../../assets/img';

const Incidents = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const now = Date.now();
    dispatch(categoryFilterChanged('All'));
    dispatch(initialDateFilterChanged(subtractMonths(now, 6)));
    dispatch(finalDateFilterChanged(now));
  }, [dispatch]);

  return (
    <Layout>
      <Container
        fluid
        className='px-lg-5 pb-lg-5 px-0 bg-white'
      >
        <Row className='p-4 sm-p-1'>
          <LineChart />
          <img
            src={incidentsLegend}
            alt='legend'
            className='w-80 mx-auto'
          />
        </Row>
        <Row className='p-5 sm-p-1'>
          <IncidentsWithFilters />
        </Row>
      </Container>
    </Layout>
  );
};

export default Incidents;
