import React, { useState } from 'react';
import { LayoutAlt } from '../../components/layout';
import { Loader, MyPagination } from '../../components/common';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../config';
import { Card, Form, Row, Col, InputGroup } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useGetCampaignsQuery } from '../../features/frames/campaigns.api';

const Campaigns = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [title, setTitle] = useState('');
  const [limit, setLimit] = useState(10);
  const [timeoutID, setTimeoutID] = useState(0);
  const [skip, setSkip] = useState(false);
  const { data: frames } = useGetCampaignsQuery({ page, title }, { skip });

  const handlePageClicked = (page) => setPage(page);

  const handleKeyPress = (e) => {
    if (!skip) setSkip(true);
    if (e.key === 'Enter') {
      setSkip(false);
    } else {
      if (timeoutID) clearTimeout(timeoutID);
      setTimeoutID(() => setTimeout(() => setSkip(false), 300));
    }
  };

  if (!frames) return <Loader />;

  if (frames.totalResults === 0)
    return (
      <LayoutAlt>
        <div className='px-5 py-4'>
          <Form className='mb-4'>
            <Row>
              <Col>
                <Form.Group
                  id='frame-title'
                  className='mx-0'
                >
                  <Form.Label>Title of the campaign</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control
                      type='text'
                      value={title}
                      placeholder=' Search using the title of the campaign e.g. #ThinkB4UClick'
                      onChange={(e) => setTitle(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                  </InputGroup>
                </Form.Group>
              </Col>
              <Col xs='auto'>
                <Form.Group
                  id='page-limit'
                  className='mx-0'
                >
                  <Form.Label>Limit</Form.Label>
                  <Form.Control
                    type='number'
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <div className='bg-white text-black p-4'>
            <p>Sorry. We couldn't find a campaign for you.</p>
          </div>
        </div>
      </LayoutAlt>
    );

  const FrameItem = ({ id, banner }) => {
    return (
      <div
        className='position-relative p-3'
        style={{ cursor: 'pointer', zIndex: 0 }}
        onClick={() => history.push(`${Routes.Campaigns.path}/${id}`)}
      >
        <div
          className='w-100 justify-content-center align-items-center'
          style={{ display: 'flex' }}
        >
          <img
            src={banner}
            alt='frame'
            width={400}
            className='sm-w-auto'
          />
        </div>
        <div
          className='position-absolute justify-content-center align-items-center'
          style={{ inset: 0, display: 'flex', zIndex: 10 }}
        >
          <p
            className='text-black fw-bold fs-5 text-center sm-w-auto sm-p-1'
            style={{ width: 300 }}
          >
            Click to create a themed campaign!
          </p>
        </div>
      </div>
    );
  };

  return (
    <LayoutAlt>
      <MyPagination
        totPages={frames.totalPages}
        currentPage={page}
        pageClicked={(page) => {
          handlePageClicked(page);
        }}
      >
        <Card
          border='light'
          className='shadow-sm text-black px-5 sm-px-1'
        >
          <Card.Body className='py-4 sm-p-1'>
            <Form className='mb-4'>
              <Row>
                <Col>
                  <Form.Group
                    id='frame-title'
                    className='mx-0'
                  >
                    <Form.Label>Title of the campaign</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                      <Form.Control
                        type='text'
                        value={title}
                        placeholder=' Search using the title of the campaign e.g. #ThinkB4UClick'
                        onChange={(e) => setTitle(e.target.value)}
                        onKeyPress={handleKeyPress}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col xs='auto'>
                  <Form.Group
                    id='page-limit'
                    className='mx-0'
                  >
                    <Form.Label>Limit</Form.Label>
                    <Form.Control
                      type='number'
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <div className='mb-3 grid-container p-4 sm-p-0'>
              {frames.results.map((frame) => (
                <FrameItem
                  key={frame.id}
                  {...frame}
                />
              ))}
            </div>
          </Card.Body>
        </Card>
      </MyPagination>
    </LayoutAlt>
  );
};

export default Campaigns;
