/* eslint-disable react-hooks/rules-of-hooks */
import { createSelector } from '@reduxjs/toolkit';

import { api } from '../../app';
import {
  search,
  sanitizeFilters,
  filterByEnum,
} from '../../utils/reusableFunctions';

const apiWithUserTags = api.enhanceEndpoints({ addTagTypes: ['User'] });

const userApi = apiWithUserTags.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['User'],
    }),
    getUsers: builder.query({
      query: (params) => ({
        url: 'users',
        method: 'GET',
        params,
      }),
      providesTags: (data) =>
        data && data.results
          ? [
              ...data.results.map(({ id }) => ({ type: 'User', id })),
              { type: 'User', id: 'PARTIAL-USER-LIST' },
            ]
          : [{ type: 'User', id: 'PARTIAL-USER-LIST' }],
    }),
    getSingleUser: builder.query({
      query: ({ id }) => ({
        url: `users/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'User', id: result.id }] : ['User'],
    }),
    updateUser: builder.mutation({
      query: ({ id, body }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id },
        { type: 'User', id: 'PARTIAL-USER-LIST' },
      ],
    }),
    deleteUser: builder.mutation({
      query: ({ id }) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id },
        { type: 'User', id: 'PARTIAL-USER-LIST' },
      ],
    }),
    approveUser: builder.mutation({
      query: ({ id }) => ({
        url: `approve/${id}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id },
        { type: 'User', id: 'PARTIAL-USER-LIST' },
      ],
    }),
    blacklistUser: builder.mutation({
      query: ({ id }) => ({
        url: `approve/${id}`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id },
        { type: 'User', id: 'PARTIAL-USER-LIST' },
      ],
    }),
    changeUserRole: builder.mutation({
      query: ({ id, body }) => ({
        url: `approve/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.id },
        { type: 'User', id: 'PARTIAL-USER-LIST' },
      ],
    }),
  }),
});

// Selectors
// TODO: fix selectUsers > always returns undefined
export const primarySelector = userApi.endpoints.getUsers.select();
const emptyUsers = [];
export const selectUsers = createSelector(
  primarySelector,
  (response) => response.data?.results ?? emptyUsers
);
export const selectUserById = (id) =>
  userApi.endpoints.getSingleUser.select({ id });
export const selectUserFromList = (id) =>
  createSelector(selectUsers, (response) =>
    response.data?.results.find((user) => user.id === id)
  );

export const SelectFilteredUsers = createSelector(
  selectUsers,
  (state) => state.userFilters,
  (users, filters) => search(users, sanitizeFilters(filters))
);

export const SelectApprovedUsers = createSelector(selectUsers, (users) =>
  search(users, { approved: true })
);

export const SelectAdvancedUsers = createSelector(
  SelectApprovedUsers,
  (users) => filterByEnum(users, ['author', 'editor', 'admin'], 'role')
);

export const SelectAuthors = createSelector(selectUsers, (users) =>
  search(users, { role: 'author', approved: true })
);

export const {
  useCreateUserMutation,
  useGetUsersQuery,
  useGetSingleUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useApproveUserMutation,
  useBlacklistUserMutation,
  useChangeUserRoleMutation,
} = userApi;
export default userApi;

export const useCurrentUser = () => {
  let user = null;
  const id = localStorage.getItem('userId');
  if (id) {
    const { data } = useGetSingleUserQuery({ id });
    if (data) {
      user = data;
    }
  }
  return user;
};
