import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import {
  ProtectedRouteWithLoader,
  RouteWithLoader,
  RouteWithSidebar,
  Routes,
  restrictions,
} from '.';

// main pages
import { Home, Sentiment, ServerError, Hotspots, Map } from '../pages';

// categories
import {
  CategoriesTable,
  NewCategory,
  EditCategory,
} from '../pages/categories';

// incidents
import {
  EditIncident,
  Incidents,
  IncidentsTable,
  NewIncident,
  SingleIncident,
  IncidentList,
} from '../pages/incidents';

// keywords
import { EditKeyword, NewKeyword, KeywordsTable } from '../pages/keywords';

// regions
import { EditRegion, NewRegion, RegionsTable } from '../pages/regions';

// reports
import {
  EditReport,
  NewReport,
  ReportsTable,
  SingleReport,
  Reports,
} from '../pages/reports';

// users
import { EditUser, NewUser, UsersTable } from '../pages/users';

// summary
import {
  Summary,
  CreateSummary,
  EditSummary,
  ThreatSummary,
} from '../pages/summary';

import {
  Signin,
  Signup,
  Forbidden,
  ForgotPassword,
  Settings,
  ResetPassword,
  VerifyEmail,
  NotFound,
} from '../pages/auth';

// dashboard
import { DashboardOverview } from '../pages/dashboard';

// frames and campaigns
import {
  CampaignList,
  Campaigns,
  CreateCampaign,
  CreateTemplate,
  UpdateCampaign,
} from '../pages/campaigns';

const AppRouter = () => {
  return (
    <Switch>
      <RouteWithLoader
        exact
        path={Routes.Signup.path}
        component={Signup}
      />
      <RouteWithLoader
        exact
        path={Routes.Forbidden.path}
        component={Forbidden}
      />
      <RouteWithLoader
        exact
        path={Routes.Sentiment.path}
        component={Sentiment}
      />
      <RouteWithLoader
        exact
        path={Routes.Campaigns.path}
        component={Campaigns}
      />
      <RouteWithLoader
        exact
        path={Routes.CreateCampaign.path}
        component={CreateCampaign}
      />
      <RouteWithLoader
        exact
        path={Routes.Campaigns.path}
        component={Campaigns}
      />
      <RouteWithLoader
        exact
        path={Routes.SingleSummary.path}
        component={ThreatSummary}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.Home.path}
        component={Home}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.NotFound.path}
        component={NotFound}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.ServerError.path}
        component={ServerError}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.Map.path}
        component={Map}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.Reports.path}
        component={Reports}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.IncidentsList.path}
        component={IncidentList}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.Incidents.path}
        component={Incidents}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.Incident.path}
        component={SingleIncident}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.Hotspots.path}
        component={Hotspots}
        restrictedTo={restrictions.none}
      />
      <ProtectedRouteWithLoader
        exact
        path={Routes.Report.path}
        component={SingleReport}
        restrictedTo={restrictions.none}
      />
      <RouteWithLoader
        exact
        path={Routes.ForgotPassword.path}
        component={ForgotPassword}
      />
      <RouteWithLoader
        exact
        path={Routes.ResetPassword.path}
        component={ResetPassword}
      />
      <RouteWithLoader
        exact
        path={Routes.Signin.path}
        component={Signin}
      />
      <RouteWithLoader
        exact
        path={Routes.VerifyEmail.path}
        component={VerifyEmail}
      />

      <RouteWithSidebar
        exact
        path={Routes.DashboardOverview.path}
        component={DashboardOverview}
        restrictedTo={restrictions.none}
      />
      <RouteWithSidebar
        exact
        path={Routes.CreateCampaign.path}
        component={CreateCampaign}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.UpdateCampaign.path}
        component={UpdateCampaign}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.UpdateCampaign.path}
        component={UpdateCampaign}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.CreateTemplate.path}
        component={CreateTemplate}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.CampaignList.path}
        component={CampaignList}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.Settings.path}
        component={Settings}
        restrictedTo={restrictions.none}
      />
      <RouteWithSidebar
        exact
        path={Routes.Summary.path}
        component={Summary}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.CreateSummary.path}
        component={CreateSummary}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditSummary.path}
        component={EditSummary}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.IncidentsTable.path}
        component={IncidentsTable}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.UsersTable.path}
        component={UsersTable}
        restrictedTo={restrictions.admin}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditUser.path}
        component={EditUser}
        restrictedTo={restrictions.admin}
      />
      <RouteWithSidebar
        exact
        path={Routes.NewUser.path}
        component={NewUser}
        restrictedTo={restrictions.admin}
      />
      <RouteWithSidebar
        exact
        path={Routes.CategoriesTable.path}
        component={CategoriesTable}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditCategory.path}
        component={EditCategory}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.NewCategory.path}
        component={NewCategory}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.ReportsTable.path}
        component={ReportsTable}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditReport.path}
        component={EditReport}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.NewReport.path}
        component={NewReport}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.NewIncident.path}
        component={NewIncident}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditIncident.path}
        component={EditIncident}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.RegionsTable.path}
        component={RegionsTable}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.NewRegion.path}
        component={NewRegion}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditRegion.path}
        component={EditRegion}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.KeywordsTable.path}
        component={KeywordsTable}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.NewKeyword.path}
        component={NewKeyword}
        restrictedTo={restrictions.author}
      />
      <RouteWithSidebar
        exact
        path={Routes.EditKeyword.path}
        component={EditKeyword}
        restrictedTo={restrictions.author}
      />

      <Redirect to={Routes.NotFound.path} />
    </Switch>
  );
};

export default AppRouter;
