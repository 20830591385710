import React, { useState } from 'react';
import moment from 'moment';
import * as htmlToImage from 'html-to-image';
import { toast } from 'react-toastify';
import { Form, Card, Row, Col, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useCreateSummaryMutation } from '../../features/summary/summary.api';
import { useGroupThreatsQuery } from '../../features/summary/threats.api';
import { CustomToast, Loader } from '../../components/common';
import { chartMetaData } from '../../components/summaryCharts';
import StepWizard from 'react-step-wizard';
import {
  Step,
  InputStep,
  DatetimeStep,
  PreviewStep,
} from '../../components/wizard';
import { deleteEmptyStringProps } from '../../utils/reusableFunctions';
import DictionaryStep from '../../components/wizard/DictionaryStep';
import SummaryPDF from '../../components/PDF/SummaryPDF';
import { pdf } from '@react-pdf/renderer';
import { uploadToCloudinary } from '../../utils';

const CreateSummary = () => {
  const now = new Date();
  const [createSummary] = useCreateSummaryMutation();

  const [month, setMonth] = useState(now.getMonth());
  const [year, setYear] = useState(now.getFullYear());
  const [introduction, setIntroduction] = useState('');
  const [conclusion, setConclusion] = useState('');
  const [dictionary, setDictionary] = useState([]);

  // descriptions
  const [typeDescription, setTypeDescription] = useState('');
  const [viralityDescription, setViralityDescription] = useState('');
  const [believabilityDescription, setBelievabilityDescription] = useState('');
  const [factualnessDescription, setFactualnessDescription] = useState('');
  const [
    likelihoodToCauseHarmDescription,
    setLikelihoodToCauseHarmDescription,
  ] = useState('');
  const [languageDescription, setLanguageDescription] = useState('');
  const [platformDescription, setPlatformDescription] = useState('');
  const [additionalInformation, setAdditionalInformation] = useState('');

  // charts
  const [typeChart, setTypeChart] = useState(null);
  const [believabilityChart, setBelievabilityChart] = useState(null);
  const [viralityChart, setViralityChart] = useState(null);
  const [likelihoodToCauseHarmChart, setLikelihoodToCauseHarmChart] =
    useState(null);
  const [factualnessChart, setFactualnessChart] = useState(null);
  const [languageChart, setLanguageChart] = useState(null);
  const [platformChart, setPlatformChart] = useState(null);

  const PDFPreview = () => (
    <SummaryPDF
      typeChart={typeChart}
      believabilityChart={believabilityChart}
      likelihoodToCauseHarmChart={likelihoodToCauseHarmChart}
      factualnessChart={factualnessChart}
      viralityChart={viralityChart}
      languageChart={languageChart}
      platformChart={platformChart}
      year={year}
      month={month}
      introduction={introduction}
      conclusion={conclusion}
      additionalInformation={additionalInformation}
      dictionary={dictionary}
      typeDescription={typeDescription}
      viralityDescription={viralityDescription}
      likelihoodToCauseHarmDescription={likelihoodToCauseHarmDescription}
      factualnessDescription={factualnessDescription}
      believabilityDescription={believabilityDescription}
      platformDescription={platformDescription}
      languageDescription={languageDescription}
    />
  );

  const convertChartToImage = async (id) => {
    const node = document.getElementById(id);
    const response = await htmlToImage.toPng(node, { height: 400, width: 600 });
    return response;
  };

  const updateType = async () => {
    const chart = await convertChartToImage(chartMetaData.type.descId);
    setTypeChart(chart);
  };

  const updateBelievability = async () => {
    const chart = await convertChartToImage(chartMetaData.believability.descId);
    setBelievabilityChart(chart);
  };

  const updateVirality = async () => {
    const chart = await convertChartToImage(chartMetaData.virality.descId);
    setViralityChart(chart);
  };

  const updateFactualness = async () => {
    const chart = await convertChartToImage(chartMetaData.factualness.descId);
    setFactualnessChart(chart);
  };

  const updateLikelihoodToCauseHarm = async () => {
    const chart = await convertChartToImage(
      chartMetaData.likelihoodToCauseHarm.descId
    );
    setLikelihoodToCauseHarmChart(chart);
  };

  const updatePlatform = async () => {
    const chart = await convertChartToImage(chartMetaData.platform.descId);
    setPlatformChart(chart);
  };

  const updateLanguage = async () => {
    const chart = await convertChartToImage(chartMetaData.language.descId);
    setLanguageChart(chart);
  };

  const [isLastStep, setIsLastStep] = useState(false);

  const { data } = useGroupThreatsQuery({ month, year });

  const updateDictionary = (word, meaning) =>
    setDictionary([...dictionary, { word, meaning }]);

  const removeFromDictionary = (word) =>
    setDictionary(dictionary.filter((item) => item.word !== word));

  async function handleCreateSummary(e) {
    e.stopPropagation();
    e.preventDefault();
    const pdfBlob = await pdf(<PDFPreview />).toBlob();
    const cloudinaryUrl = await uploadToCloudinary(
      pdfBlob,
      `Monitoring report for ${moment({ year, month }).format('MMMM YYYY')}`
    );
    if (
      !introduction ||
      !conclusion ||
      !typeDescription ||
      !viralityDescription ||
      !believabilityDescription ||
      !factualnessDescription ||
      !likelihoodToCauseHarmDescription ||
      !platformDescription ||
      !languageDescription
    ) {
      toast.error('Please fill in all the fields');
    } else {
      await createSummary(
        deleteEmptyStringProps({
          month,
          year,
          introduction,
          conclusion,
          typeDescription,
          believabilityDescription,
          viralityDescription,
          factualnessDescription,
          likelihoodToCauseHarmDescription,
          platformDescription,
          languageDescription,
          additionalInformation,
          dictionary,
          pdf: cloudinaryUrl ?? '',
        })
      );
    }
  }

  if (!data) return <Loader />;
  if (data.type.count === 0)
    return (
      <p className='text-black'>
        Yay. No threats were reported for{' '}
        {moment({ year, month }).format('MMMM YYYY')}
      </p>
    );
  return (
    <>
      <CustomToast />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Body>
          <h5 className='mb-4'>Add Summary</h5>
          <Form>
            <StepWizard>
              <DatetimeStep
                step={1}
                month={month}
                year={year}
                setMonth={setMonth}
                setYear={setYear}
              />
              <InputStep
                value={introduction}
                setValue={setIntroduction}
                placeholder='Introduction statement'
                label='Introduction'
                step={2}
                setIsLastStep={setIsLastStep}
              />
              <Step
                description={typeDescription}
                setDescription={setTypeDescription}
                beforeNext={updateType}
                data={data.type.stats}
                chartId={chartMetaData.type.descId}
                label={chartMetaData.type.label}
                step={3}
              />
              <Step
                description={viralityDescription}
                setDescription={setViralityDescription}
                beforeNext={updateVirality}
                data={data.virality.stats}
                chartId={chartMetaData.virality.descId}
                label={chartMetaData.virality.label}
                step={4}
              />
              <Step
                description={believabilityDescription}
                setDescription={setBelievabilityDescription}
                beforeNext={updateBelievability}
                data={data.believability.stats}
                chartId={chartMetaData.believability.descId}
                label={chartMetaData.believability.label}
                inverseLikert={true}
                step={5}
              />
              <Step
                description={factualnessDescription}
                setDescription={setFactualnessDescription}
                beforeNext={updateFactualness}
                data={data.factualness.stats}
                chartId={chartMetaData.factualness.descId}
                label={chartMetaData.factualness.label}
                inverseLikert={true}
                step={6}
              />
              <Step
                description={likelihoodToCauseHarmDescription}
                setDescription={setLikelihoodToCauseHarmDescription}
                beforeNext={updateLikelihoodToCauseHarm}
                data={data.likelihoodToCauseHarm.stats}
                chartId={chartMetaData.likelihoodToCauseHarm.descId}
                label={chartMetaData.likelihoodToCauseHarm.label}
                step={7}
              />
              <Step
                description={languageDescription}
                setDescription={setLanguageDescription}
                beforeNext={updateLanguage}
                data={data.language.stats}
                chartId={chartMetaData.language.descId}
                label={chartMetaData.language.label}
                step={8}
              />
              <Step
                description={platformDescription}
                setDescription={setPlatformDescription}
                beforeNext={updatePlatform}
                data={data.platform.stats}
                chartId={chartMetaData.platform.descId}
                label={chartMetaData.platform.label}
                step={9}
              />
              <InputStep
                value={conclusion}
                setValue={setConclusion}
                placeholder='Conclusion statement'
                label='Conclusion'
                step={10}
                setIsLastStep={setIsLastStep}
              />
              <DictionaryStep
                step={11}
                dictionary={dictionary}
                updateDictionary={updateDictionary}
                removeFromDictionary={removeFromDictionary}
                setIsLastStep={setIsLastStep}
              />
              <InputStep
                value={additionalInformation}
                setValue={setAdditionalInformation}
                placeholder='Any additional information'
                label='Additional Information'
                step={12}
                required={false}
                setIsLastStep={setIsLastStep}
              />
              <PreviewStep
                step={13}
                preview={<PDFPreview />}
              />
            </StepWizard>
            <Row className='my-4'>
              <Col className='text-start'>
                <Button
                  variant='primary'
                  className='me-2'
                  type='submit'
                  disabled={!isLastStep}
                  onClick={handleCreateSummary}
                >
                  Create{' '}
                  <FontAwesomeIcon
                    icon={faPlus}
                    className='ms-2'
                  />
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default CreateSummary;
