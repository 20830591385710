import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button } from '@themesberg/react-bootstrap';

const WizardFooter = ({ previousStep, nextStep, step, totalSteps }) => {
  return (
    <Row className='my-4 align-items-center'>
      {step > 1 && (
        <Col className='text-start'>
          <Button
            variant='primary'
            size='sm'
            className='me-2'
            onClick={previousStep}
          >
            <FontAwesomeIcon
              icon={faLongArrowAltLeft}
              className='me-2'
            />
            Previous
          </Button>
        </Col>
      )}
      {step < totalSteps && (
        <Col className='text-end'>
          <Button
            variant='primary'
            size='sm'
            className='me-2'
            onClick={nextStep}
          >
            Continue
            <FontAwesomeIcon
              icon={faLongArrowAltRight}
              className='ms-2'
            />
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default WizardFooter;
