import { api } from '../../app';

const apiWithTilesetTags = api.enhanceEndpoints({ addTagTypes: ['Tileset'] });

const tilesetApi = apiWithTilesetTags.injectEndpoints({
  endpoints: (builder) => ({
    createTileset: builder.mutation({
      query: ({ id, body }) => ({
        url: `tilesets/${id}`,
        method: 'POST',
        body,
      }),
    }),
    updateTilesetSource: builder.mutation({
      query: () => ({
        url: `tilesets`,
        method: 'POST',
      }),
    }),
    updateRecipe: builder.mutation({
        query: ({ id, body }) => ({
          url: `tilesets/${id}`,
          method: 'PATCH',
          body,
        }),
    }),
    publishTileset: builder.mutation({
        query: ({ id }) => ({
          url: `tilesets/${id}`,
          method: 'PUT',
        }),
    }),
  }),
});

export const {
    useCreateTilesetMutation,
    useUpdateTilesetSourceMutation,
    useUpdateRecipeMutation,
    usePublishTilesetMutation
} = tilesetApi;
export default tilesetApi;
