import React from 'react';
import { Route } from 'react-router-dom';
import { CustomToast } from '../components/common';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <main className='bg-white'>
          <CustomToast />
          <Component {...props} />
        </main>
      )}
    />
  );
};

export default RouteWithLoader;
