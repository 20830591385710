import { Dropdown } from '@themesberg/react-bootstrap';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { selectCategoryById } from '../../features/categories/categories.slice';
import { useGetCategoriesQuery } from '../../features/categories/categories.api';
import {
  categoryFilterChanged,
  selectCategory,
} from '../../features/incidents/incidents.filter.slice';
import { Loader } from '../common';

const CategoryFilter = () => {
  const dispatch = useDispatch();
  const { categories } = useGetCategoriesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      categories: data ? data.results : [],
    }),
  });
  const category = useSelector(selectCategory);
  const categoryMetadata = useSelector((state) =>
    selectCategoryById(state, category)
  );

  const CategoryItem = (category) => {
    return (
      <Dropdown.Item
        className='text-black'
        eventKey={category.id}
      >
        {category.title}
      </Dropdown.Item>
    );
  };

  function changeCategory(eventKey, event) {
    dispatch(categoryFilterChanged(eventKey));
  }

  function viewAll() {
    dispatch(categoryFilterChanged('All'));
  }

  return (
    <Loader loaded={!!categories.length}>
      <Dropdown onSelect={changeCategory}>
        <Dropdown.Toggle className='p-3'>
          <small className='text-white h6'>
            {categoryMetadata ? categoryMetadata.title : 'Category'}
          </small>
          <FontAwesomeIcon
            icon={faCaretDown}
            className='text-secondary ms-2'
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className='dropdown-menu-center mt-2'>
          {categories.map((cat) => (
            <CategoryItem
              key={cat.id}
              {...cat}
            />
          ))}
          <Dropdown.Divider />
          <Dropdown.Item
            className='text-black'
            eventKey='All'
            onClick={viewAll}
          >
            View All
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Loader>
  );
};

export default CategoryFilter;
