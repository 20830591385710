import React, { useState, useEffect } from 'react';
import {
  Col,
  ListGroup,
  Card,
  Button,
  Row,
  Badge,
} from '@themesberg/react-bootstrap';
import { formatDate, getWords } from '../../utils/reusableFunctions';
import { useSelector } from 'react-redux';
import {
  SelectSortedFlattenedIncidents,
  useLazyGetIncidentsQuery,
} from '../../features/incidents/incidents.api';
import { Loader, MyPagination } from '../common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config';
import { CombinedFilters } from '../filters';
import { paginateList } from '../../utils/reusableFunctions';

const IncidentsWithFilters = () => {
  const [getIncidents] = useLazyGetIncidentsQuery();
  const filteredIncidents = useSelector(SelectSortedFlattenedIncidents);

  const itemsPerPage = 20;
  const [currentPage, setCurrentPage] = useState(1);
  let itemList = paginateList(filteredIncidents, currentPage, itemsPerPage);
  const totalPages = Math.ceil(filteredIncidents.length / itemsPerPage);

  useEffect(() => {
    if (filteredIncidents.length === 0) getIncidents();
  }, [filteredIncidents.length, getIncidents]);

  const handlePageClicked = (page) => {
    setCurrentPage(page);
  };

  const Incident = (props) => {
    const {
      title,
      description,
      id,
      incidentDate,
      deaths,
      wounded,
      peopleInvolved,
    } = props;

    return (
      <ListGroup.Item className='px-0'>
        <Row className='align-items-center justify-content-between mx-lg-5'>
          <Col md={6}>
            <h4>
              <Link
                to={`${Routes.Incidents.path}/${id}`}
                className='h6 mb-2 text-black'
              >
                {title}
              </Link>
            </h4>
            <small className='text-black'>
              {formatDate(incidentDate, ' ')}
            </small>
            <p className='text-black'>
              {getWords({ str: description, num: 25 })}...
            </p>
          </Col>
          <Col
            md={2}
            className='text-center mb-xs-4'
          >
            <Button variant='danger'>
              Deaths <Badge bg='primary'>{deaths}</Badge>
            </Button>
          </Col>
          <Col
            md={2}
            className='text-center mb-xs-4'
          >
            <Button variant='warning'>
              Wounded <Badge bg='primary'>{wounded}</Badge>
            </Button>
          </Col>
          <Col
            md={2}
            className='text-center'
          >
            <Button variant='info'>
              People Involved <Badge bg='primary'>{peopleInvolved || 0}</Badge>
            </Button>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Loader loaded={!!filteredIncidents.length}>
      <Card
        border='light'
        className='shadow-sm'
      >
        <Card.Header className='bg-tertiary text-black'>
          <CombinedFilters />
        </Card.Header>
        <Card.Body>
          <MyPagination
            totPages={totalPages}
            currentPage={currentPage}
            pageClicked={(page) => {
              handlePageClicked(page);
            }}
          >
            <ListGroup className='list-group-flush list my--3'>
              {itemList.map((i) => (
                <Incident
                  key={i.id}
                  {...i}
                />
              ))}
            </ListGroup>
          </MyPagination>
        </Card.Body>
      </Card>
    </Loader>
  );
};

export default IncidentsWithFilters;
