import { Container } from '@themesberg/react-bootstrap';
import React from 'react';
import IncidentListWidget from '../../pages/incidents/IncidentList';
import LineChart from './LineChart';

const SingleIncidentSidebar = () => {
  return (
    <Container className='px-0'>
      <IncidentListWidget len={5} />
      <h4 className='h6 text-black bg-secondary p-3 mb-0'>Chart View</h4>
      <LineChart />
    </Container>
  );
};

export default SingleIncidentSidebar;
