import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    category: '',
}

const reportFilterSlice = createSlice({
    name: 'reportFilters',
    initialState,
    reducers: {
        categoryFilterChanged(state, action){
            state.category = action.payload
        },
    }
})

export const { categoryFilterChanged } = reportFilterSlice.actions;

export default reportFilterSlice.reducer;
