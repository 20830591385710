import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ascending: false,
    sortKey: 'id',
}

const incidentSortingSlice = createSlice({
    name: 'incidentSorting',
    initialState,
    reducers: {
        sortingOrderChanged(state, action){
            state.ascending = !state.ascending
        },
        sortingKeyChanged(state, action){
            state.sortKey = action.payload
        }
    }
})

export const { sortingOrderChanged, sortingKeyChanged } = incidentSortingSlice.actions;

export default incidentSortingSlice.reducer;
