import React from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Form } from '@themesberg/react-bootstrap';
import WizardFooter from './WizardFooter';

const InputStep = ({
  value,
  setValue,
  label,
  placeholder,
  previousStep,
  nextStep,
  step,
  totalSteps,
  required = true,
  setIsLastStep,
}) => {
  const validate = () => {
    if (value === '' && required) {
      toast.error('Please fill all form fields before proceeding!');
    } else {
      setIsLastStep(step === totalSteps - 1);
      nextStep();
    }
  };

  return (
    <div className='my-4 bg-white'>
      <Row className='align-items-center'>
        <Col className='mb-3'>
          <Form.Group id='text'>
            <Form.Label>{label}</Form.Label>
            <Form.Control
              as='textarea'
              defaultValue={value}
              placeholder={placeholder}
              rows={10}
              onChange={(e) => setValue(e.target.value)}
              className='text-black'
              required={required}
            />
          </Form.Group>
        </Col>
      </Row>
      <WizardFooter
        previousStep={previousStep}
        nextStep={validate}
        step={step}
        totalSteps={totalSteps}
      />
    </div>
  );
};

export default InputStep;
