// import { configureStore } from '@reduxjs/toolkit';
// import {
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist';
// import storageSession from 'redux-persist/lib/storage/session'
// import { combineReducers } from "redux"; 
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
// eslint-disable-next-line import/no-cycle
import api from './api';

// middleware
import { rtkQueryErrorLogger } from './middleware';

// // reducers
import authReducer from '../features/auth/auth.slice';
import userReducer from '../features/users/users.slice';
import userFilterReducer from '../features/users/users.filter.slice';
import incidentReducer from '../features/incidents/incidents.slice';
import incidentsFilterReducer from '../features/incidents/incidents.filter.slice';
import incidentDateFilterReducer from '../features/incidents/incidents.dateFilters.slice';
import userSortingReducer from '../features/users/users.sorting.slice';
import incidentsSortingReducer from '../features/incidents/incidents.sorting.slice';
import reportReducer from '../features/reports/reports.slice';
import reportsFilterReducer from '../features/reports/reports.filter.slice';
import reportsSortingReducer from '../features/reports/reports.sorting.slice';
import popupsReducer from '../features/popups/popups.slice';
import categoryReducer from '../features/categories/categories.slice';
import regionReducer from '../features/regions/regions.slice';

// const rootReducer = combineReducers({
//   auth: authReducer,
//   users: userReducer,
//   userFilters: userFilterReducer,
//   userSorting: userSortingReducer,
//   incidents: incidentReducer,
//   incidentFilters: incidentsFilterReducer,
//   incidentSorting: incidentsSortingReducer,
//   incidentDateFilter: incidentDateFilterReducer,
//   reports: reportReducer,
//   reportFilters: reportsFilterReducer,
//   reportSorting: reportsSortingReducer,
//   popups: popupsReducer,
//   categories: categoryReducer,
//   regions: regionReducer,
// });

// const persistConfig = {
//   key: 'root',
//   version: 1,
//   storage: storageSession,
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// export const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// })

// reducers

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    auth: authReducer,
    users: userReducer,
    userFilters: userFilterReducer,
    userSorting: userSortingReducer,
    incidents: incidentReducer,
    incidentFilters: incidentsFilterReducer,
    incidentSorting: incidentsSortingReducer,
    incidentDateFilter: incidentDateFilterReducer,
    reports: reportReducer,
    reportFilters: reportsFilterReducer,
    reportSorting: reportsSortingReducer,
    popups: popupsReducer,
    categories: categoryReducer,
    regions: regionReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
});

setupListeners(store.dispatch);
