import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  Card,
  Button,
  Container,
  InputGroup,
} from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CustomToast, Loader } from '../../components/common';
import { useForgotPasswordMutation } from '../../features/auth/auth.api';

import { Routes } from '../../config/routes';

export default () => {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const [email, setEmail] = useState('');

  async function handleForgotPassword(e) {
    e.stopPropagation();
    e.preventDefault();

    if (email === '') {
      toast.error('Please add an email');
    } else {
      await forgotPassword({ email })
        .unwrap()
        .then(() => {
          toast.success('Check your email for a link to reset your password');
        });
    }
  }

  return (
    <main>
      <CustomToast />
      <Loader loaded={!isLoading}>
        <section className='vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center'>
          <Container>
            <Row className='justify-content-center'>
              <p className='text-center'>
                <Card.Link
                  as={Link}
                  to={Routes.Signin.path}
                  className='text-gray-700'
                >
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    className='me-2'
                  />{' '}
                  Back to sign in
                </Card.Link>
              </p>
              <Col
                xs={12}
                className='d-flex align-items-center justify-content-center'
              >
                <div className='signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500'>
                  <h3>Forgot your password?</h3>
                  <p className='mb-4'>
                    Don't fret! Just type in your email and we will send you a
                    code to reset your password!
                  </p>
                  <Form>
                    <div className='mb-4'>
                      <Form.Label htmlFor='email'>Your Email</Form.Label>
                      <InputGroup id='email'>
                        <Form.Control
                          required
                          autoFocus
                          type='email'
                          placeholder='john@company.com'
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </InputGroup>
                    </div>
                    <Button
                      variant='primary'
                      type='submit'
                      className='w-100'
                      onClick={handleForgotPassword}
                    >
                      Recover password
                    </Button>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Loader>
    </main>
  );
};
