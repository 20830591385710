import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  Button,
  Container,
  InputGroup,
} from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import Chartist from 'react-chartist';
import ChartistTooltip from 'chartist-plugin-tooltips-updated';
import ChartistAxisTitle from 'chartist-plugin-axistitle';

import { Loader, MyPagination } from '../components/common';
import { Tweet } from '../components';
import Api from '../utils/network';
import { useGetSightingsQuery } from '../features/sightings/sightings.api';
import { useGetTweetsQuery } from '../features/tweets/tweets.api';
import { LayoutAlt } from '../components/layout';

export default () => {
  const api = new Api();
  const [words, setWords] = useState('');
  const [score, setScore] = useState(0);
  const [hide, setHide] = useState(true);
  const [page, setPage] = useState(1);
  const [timeoutID, setTimeoutID] = useState(0);
  const [keywords, setKeywords] = useState('');
  const [username, setUsername] = useState('');
  const [skip, setSkip] = useState(false);
  const to = new Date('2023-06-10').getTime();
  const from = new Date('2023-02-10').getTime();
  const { data, isLoading } = useGetSightingsQuery({ to, from });
  const { data: tweetData, isLoading: isTweetLoading } = useGetTweetsQuery(
    { page, keywords, username },
    { skip }
  );

  const handlePageClicked = (page) => setPage(page);

  const handleKeyPress = (e) => {
    if (!skip) setSkip(true);
    if (e.key === 'Enter') {
      setSkip(false);
    } else {
      if (timeoutID) clearTimeout(timeoutID);
      setTimeoutID(() => setTimeout(() => setSkip(false), 300));
    }
  };

  if (!data || !tweetData) return <Loader />;

  console.log({ data });

  let labels = [];
  let series = [];
  data.results.forEach((stat) => {
    labels.push(stat.text);
    series.push(stat.frequency);
  });
  const chartData = { labels, series: [series] };

  const options = {
    low: 0,
    showArea: false,
    fullWidth: true,
    chartPadding: {
      right: 40,
      left: 40,
    },
    axisX: {
      position: 'end',
      showGrid: false,
    },
    axisY: {
      showGrid: true,
      showLabel: true,
      labelInterpolationFnc: (value) => value,
      onlyInteger: true,
    },
  };

  const plugins = [
    ChartistTooltip(),
    ChartistAxisTitle({
      axisX: {
        axisTitle: 'Words',
        axisClass: 'ct-axis-title',
        offset: {
          x: 10,
          y: 50,
        },
        textAnchor: 'middle',
      },
      axisY: {
        axisTitle: 'Frequency of use',
        axisClass: 'ct-axis-title',
        offset: {
          x: 10,
          y: 0,
        },
        textAnchor: 'middle',
        flipTitle: false,
      },
    }),
  ];

  async function analyze(e) {
    e.stopPropagation();
    e.preventDefault();

    if (words === '') {
      toast.error('Please type something first');
    } else {
      setHide(false);
      api
        .Sentiment()
        .analyze(words)
        .then((res) => {
          if (res.status === 200) {
            setScore(res.data.score);
            setHide(true);
            setWords('');
          }
        })
        .catch((error) => {
          if (error && error.response) {
            const { message } = error.response.data;
            setHide(true);
            toast.error(message);
          }
        });
    }
  }

  return (
    <LayoutAlt>
      <Loader loaded={hide || !isLoading || !isTweetLoading}>
        <section className='bg-white d-flex align-items-center'>
          <Container className='justify-content-center p-1 p-md-4 p-lg-5 w-100'>
            <Row>
              <h3 className='mb-4 text-center'>Sentiment Analysis</h3>
            </Row>
            <Row>
              <Form className='w-100'>
                <Row className='align-items-center'>
                  <Col
                    xs={12}
                    md={10}
                    className='mb-md-4'
                  >
                    <Form.Group id='sentiment'>
                      <Form.Label>
                        Add word(s) to check sentiment{' '}
                        {score > 0 ? (
                          <span
                            role='img'
                            aria-label='happy'
                          >
                            😄
                          </span>
                        ) : score < 0 ? (
                          <span
                            role='img'
                            aria-label='sad'
                          >
                            😡
                          </span>
                        ) : (
                          <span
                            role='img'
                            aria-label='neutral'
                          >
                            😐
                          </span>
                        )}
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          value={words}
                          type='text'
                          placeholder='words to analyze'
                          onChange={(e) => setWords(e.target.value)}
                        />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col
                    xs={12}
                    md={2}
                    className='mb-md-4 pt-3 pt-md-5'
                  >
                    <Button
                      variant='primary'
                      type='submit'
                      className='w-100 mb-3'
                      onClick={analyze}
                    >
                      Check
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Row>
            <Row className='mb-4 mx-1'>
              <h5 className='mb-4 text-center'>Frequently Used Words</h5>
              <div className='bg-white shadow-soft border rounded border-light mt-1 w-100'>
                <Chartist
                  data={chartData}
                  options={{ ...options, plugins }}
                  type='Bar'
                  className='ct-major-tenth p-5'
                />
              </div>
            </Row>
            <Row className='mb-4 mx-1'>
              <MyPagination
                totPages={tweetData.totalPages}
                currentPage={page}
                pageClicked={(page) => {
                  handlePageClicked(page);
                }}
              >
                <h5 className='mb-4 text-center'>Latest Tweets</h5>
                <Form>
                  <Row className='align-items-center mb-4'>
                    <Col
                      md={6}
                      className=''
                    >
                      <Form.Group
                        id='keywords'
                        className='mx-0'
                      >
                        <Form.Label>
                          Search using comma-separated words e.g. graffi,pede
                        </Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type='text'
                            placeholder='comma-separated search words e.g. graffi,pede'
                            onChange={(e) => setKeywords(e.target.value)}
                            onKeyPress={handleKeyPress}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col
                      md={6}
                      className=''
                    >
                      <Form.Group
                        id='username'
                        className='mx-0'
                      >
                        <Form.Label>Search using username</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <FontAwesomeIcon icon={faSearch} />
                          </InputGroup.Text>
                          <Form.Control
                            required
                            type='text'
                            placeholder='username'
                            onChange={(e) => {
                              setUsername(e.target.value);
                              setPage(1);
                            }}
                            onKeyPress={handleKeyPress}
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
                <div className='tweet-feed shadow-soft border rounded border-light'>
                  {tweetData &&
                    tweetData.results.map((tweet) => (
                      <Tweet
                        key={tweet.id}
                        avatar={tweet.avatar}
                        username={tweet.username}
                        text={tweet.text}
                        createdAt={tweet.createdAt}
                        likes={tweet.likes}
                        retweets={tweet.retweets}
                        url={`https://twitter.com/${
                          tweet.username ?? 'x'
                        }/status/${tweet.id}`}
                      />
                    ))}
                </div>
              </MyPagination>
              {tweetData && tweetData.totalResults === 0 && (
                <p className='text-black'>Sorry, couldn't find any tweets</p>
              )}
            </Row>
          </Container>
        </section>
      </Loader>
    </LayoutAlt>
  );
};
