import moment from 'moment-timezone';
/**
 *
 * @param {Array} value array to be inspected
 * @returns {Boolean} Returns true if every item in the array has an item length of more than one
 */
export const IsNotEmpty = (value) => {
  return Object.values(value).every((item) => item.length > 0);
};

/**
 *
 * @param {Object} obj1 first object
 * @param {Object} obj2 second object
 * @returns {Boolean} Returns true if two objects have the same number of keys and same values for each key
 */
export const shallowEquality = (obj1, obj2) => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  //checking if the number of keys are the same between the two objects
  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }
  return true;
};

/**
 * Filters an array according to a query object
 * @param {Array} arr array to be filtered
 * @param {Object} query object containing filter values
 * @returns {Array} filtered array
 * @example search(arr, { role: 'editor' })
 */
export const search = (arr, query) => {
  return arr.filter((item) => {
    for (var key in query) {
      if (item[key] === undefined || item[key] !== query[key]) return false;
    }
    return true;
  });
};

/**
 * Deletes properties with empty(or zero) values from an object
 * @param {Object} obj object to be filtered
 * @returns {Object} filtered object
 * @example deleteEmptyProps({ name: '', role: '', score: 0, category: 'food' })
 */
export const deleteEmptyProps = (obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([key, val]) => val !== '' || val !== 0 || val !== 'All'
    )
  );

/**
 * Deletes properties with empty string values from an object
 * @param {Object} obj object to be filtered
 * @returns {Object} filtered object
 * @example deleteEmptyProps({ name: '', role: '', category: 'food' })
 */
export const deleteEmptyStringProps = (obj) =>
  Object.fromEntries(
    Object.entries(obj).filter(([key, val]) => val.length !== 0)
  );

/**
 * Sorts array according to object key and sorting order
 * @param {Array} arr array to be sorted
 * @param {String} field object key to be used in sorting
 * @param {Boolean} ascending sorting order (ascending by default) e.g. { ascending: false }
 * @returns {Array} sorted Array
 * @example sortArray(arr, 'createdAt', { ascending: true })
 */
export const sortArray = (arr, field, { ascending = true } = {}) =>
  arr.sort((a, b) => (ascending ? a[field] - b[field] : b[field] - a[field]));

/**
 * Sorts array according to object key and sorting order. Key must be a date!
 * @param {Array} arr array to be sorted
 * @param {String} field object key to be used in sorting - must be a date
 * @param {Boolean} ascending sorting order (ascending by default) e.g. { ascending: false }
 * @returns {Array} sorted Array
 * @example sortArray(arr, 'createdAt', { ascending: true })
 */
export const sortArrayUsingDate = (arr, field, { ascending = true } = {}) =>
  arr.sort((a, b) =>
    ascending
      ? new Date(a[field]) - new Date(b[field])
      : new Date(b[field]) - new Date(a[field])
  );

/**
 * Filters objects using a query object and sorts based on a key and sorting order
 * @param {Array} arr array to be filtered and sorted
 * @param {Object} query object containing filter values
 * @param {Boolean} ascending sorting order (ascending by default) e.g. { ascending: false }
 * @param {String} sortKey object key to be used in sorting
 * @returns filtered and sorted array
 * @example searchAndSort(arr, { role: 'editor' }, { ascending: true, sortKey: 'createdAt' })
 */
export const searchAndSort = (
  arr,
  query,
  { ascending = true, sortKey = 'id' } = {}
) => {
  return arr
    .filter((item) => {
      for (var key in query) {
        if (item[key] === undefined || item[key] !== query[key]) return false;
      }
      return true;
    })
    .sort((a, b) =>
      ascending ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey]
    );
};

/**
 * Filters array objects using a date range and filterKey
 * @param {Array} arr array to be filtered
 * @param {Date} finalDate maximum date
 * @param {Date} initialDate minimum date
 * @param {String} filterKey Object key used for the filter. Optional (default = incidentDate)
 * @returns {Array} array filtered using date range
 * @example filterByDateRange(arr, { finalDate: 21-01-31, initialDate: 21-01-01, filterKey: 'incidentDate' })
 */
export const filterByDateRange = (
  arr,
  { finalDate = '', initialDate = '', filterKey = 'incidentDate' } = {}
) =>
  arr.filter(
    (item) =>
      new Date(item[filterKey]) <= new Date(finalDate) &&
      new Date(item[filterKey]) >= new Date(initialDate)
  );

/**
 * Shortens a string according to number of words
 * @param {*} str string to be shortened
 * @param {*} num number of words for new string [default=10]
 * @returns {String} shortened string
 * @example getWords({ str: description, num: 5 })
 */
export const getWords = ({ str = '', num = 10 } = {}) =>
  str.split(/\s+/).slice(0, num).join(' ') + '...';

/**
 * Gets rid of empty filters
 * @param {Object} filters
 * @returns {Object} sanitized filters
 */
export const sanitizeFilters = (filters) => {
  let clonedFilters = Object.assign({}, filters);
  for (const property in clonedFilters) {
    if (
      clonedFilters[property] === 0 ||
      clonedFilters[property] === '' ||
      clonedFilters[property] === 'All'
    ) {
      delete clonedFilters[property];
    } else if (clonedFilters[property] === 'true') {
      clonedFilters[property] = true;
    } else if (clonedFilters[property] === 'false') {
      clonedFilters[property] = false;
    }
  }
  return clonedFilters;
};

/**
 * Filter a list according to pagination criteria
 * @param {Array<Object>} List
 * @param {Number} currentPage
 * @param {Number} numberOfItemsPerPage
 * @returns {Array<Object>} filtered list
 * @example paginateList(categories, currentPage, 10 })
 */
export const paginateList = (list, currentPage, numberOfItemsPerPage) => {
  const start = (currentPage - 1) * numberOfItemsPerPage;
  const end = start + numberOfItemsPerPage;
  return list.slice(start, end);
};

/**
 * Groups array of objects according to property
 * @param {Array<Object>} objectArray Array of objects
 * @param {String} property
 * @returns {Object} object with property as keys and grouped objects in an array as values
 * @example groupBy(incidents, 'category')
 */
export const groupBy = (objectArray, property) =>
  objectArray.reduce(function (acc, obj) {
    var key = obj[property].title;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

/**
 * Gets time difference between 2 dates in months
 * @param {String} initialDate
 * @param {String} finalDate
 * @returns {Number}
 * @example findMonths('2021-11-19T07:55:58.739Z', '2021-01-19T07:55:58.739Z')
 */
export const findMonths = (initialDate, finalDate) =>
  new Date(finalDate).getMonth() -
  new Date(initialDate).getMonth() +
  (new Date(finalDate).getFullYear() - new Date(initialDate).getFullYear()) *
    12;

/**
 * Generates chart labels data
 * @param {*} initialDate
 * @param {*} finalDate
 * @returns {Array<String>} label chart data
 */
export const createLabel = (initialDate, finalDate) => {
  const allMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  let start = new Date(initialDate);
  let end = new Date(finalDate);
  let startYear = parseInt(start.getFullYear());
  let endYear = parseInt(end.getFullYear());
  let dates = [];

  for (let i = startYear; i <= endYear; i++) {
    let endMonth = i !== endYear ? 11 : end.getMonth();
    let startMon = i === startYear ? start.getMonth() + 1 : 0;
    for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      let month = j + 1;
      let displayMonth = allMonths[month - 1];
      dates.push(displayMonth);
    }
  }
  return dates;
};

/**
 * Generates chart series data
 * @param {Object} data
 * @returns {Array} chart series data
 */
export const createSeries = (
  data,
  numberofMonths,
  initialMonth,
  initialYear
) => {
  let series = [];
  for (const category in data) {
    let categoryData = data[category];
    let singleCatData = new Array(numberofMonths).fill({
      meta: category,
      value: 0,
    });
    for (const month in categoryData) {
      let monthlyData = categoryData[month];
      const index =
        monthlyData.month -
        initialMonth +
        (monthlyData.year - initialYear) * 12;
      let singlePoint = {};
      singlePoint.meta = category;
      singlePoint.value = monthlyData.frequency;
      singleCatData.splice(index, 1, singlePoint);
    }
    series.push(singleCatData);
  }
  return series;
};

/**
 * Generates chart data
 * @param {Array<Object>} objectArray
 * @param {String} property
 * @param {String} initialDate
 * @param {String} finalDate
 * @returns {Object<Array>} chart data
 */
export const generateChartData = (
  objectArray,
  property,
  initialDate,
  finalDate
) => {
  let data = {};
  let labels = createLabel(initialDate, finalDate);
  const months = findMonths(initialDate, finalDate);
  const initialMonth = moment(initialDate).month();
  const initialYear = moment(initialDate).year();
  const groupedObjects = groupBy(objectArray, property);
  var count = {};
  for (const prop in groupedObjects) {
    let categoryArr = groupedObjects[prop];
    count[prop] = categoryArr.reduce(function (acc, obj) {
      var keyDate = moment(obj['incidentDate']);
      var key = keyDate.month() + keyDate.year();
      if (!acc[key]) {
        acc[key] = {
          year: keyDate.year(),
          month: keyDate.month(),
          frequency: 0,
        };
      }
      acc[key]['frequency'] += 1;
      return acc;
    }, {});
  }
  let series = createSeries(count, months, initialMonth, initialYear);
  data['series'] = series;
  data['labels'] = labels;
  return data;
};

/**
 * Flattens category field in an ObjectArray
 * @param {Array<Object>} dataObj
 * @returns {Array<Object>} flattened array of objects
 */
export const flatten = (dataObj) =>
  dataObj.map((obj) => ({ ...obj, category: obj['category']['id'] }));

/**
 * Selects icon based on incident category
 * @param {String} category incident category
 * @returns icon
 */
export const selectIcon = (category) => {
  switch (category) {
    case '6138c0c665ca410021710e4f':
      return require('../assets/img/categories/resized/battle.png');
    case '6138c4d987be3200214f6bf2':
      return require('../assets/img/categories/resized/bomb.png');
    case '61936c888e15520021ba49e6':
      return require('../assets/img/categories/resized/tank.png');
    case '61936cc38e15520021ba49e7':
      return require('../assets/img/categories/resized/police-badge.png');
    case '6138c33a87be3200214f6bec':
      return require('../assets/img/categories/resized/flag.png');
    case '6138c22487be3200214f6be9':
    case '61936d3b8e15520021ba49e8':
      return require('../assets/img/categories/resized/pigeon.png');
    default:
      return require('../assets/img/marker.svg');
  }
};

/**
 * Gets a center coordinate from a list of incidents
 * @param {Array<Object>} incidents List of incidents
 * @returns {Array<Number>} center coordinates e.g. [8.52442, 7.52424]
 */
export const getCenter = (incidents) => {
  var x = incidents.map(function (incident) {
    return incident.location.coordinates[1];
  });
  var y = incidents.map(function (incident) {
    return incident.location.coordinates[0];
  });
  var minX = Math.min.apply(null, x);
  var maxX = Math.max.apply(null, x);
  var minY = Math.min.apply(null, y);
  var maxY = Math.max.apply(null, y);
  return [(minX + maxX) / 2, (minY + maxY) / 2];
};

/**
 * Filters array of objects using enum values
 * @param {Array<Object>} arr
 * @param {Array} enumValues
 * @param {String} key name of the field being queried
 * @returns {Array<Object>} filtered values
 */
export const filterByEnum = (arr, enumValues, key) =>
  arr.filter((item) => enumValues.includes(item[key]));

/**
 * Subtracts a given number of months from a date
 * @param {Date} date initial date
 * @param {number} months number of months to be subtracted from the initial date
 * @returns {number} initial date - months
 */
export const subtractMonths = (date, months) =>
  new Date(date).setMonth(new Date(date).getMonth() - months);

/**
 * Converts a date in number to readable string
 * @param {number} date
 * @param {string} separator
 * @returns {string} date
 */
export const formatDate = (date, separator) => {
  const month = String(new Date(date).getMonth() + 1);
  return `${new Date(date).getDate()}${separator}${month.padStart(
    2,
    '0'
  )}${separator}${new Date(date).getFullYear()}`;
};
