import React from 'react';
import { Image, Navbar, Nav, Col, Row } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from '../../config';
import { logo_180 } from '../../assets/img';
import NavProfile from './NavProfile';

const Header = () => {
  return (
    <Row className='align-items-center justify-content-between bg-tertiary px-3 py-1'>
      <Col
        xs={12}
        md={8}
        className='align-items-center'
      >
        <Navbar
          variant='light'
          expand='lg'
          className='navbar-transparent navbar-theme-primary sticky-top bg-tertiary'
        >
          <Navbar.Toggle aria-controls='navbar-default-primary' />
          <Navbar.Collapse id='navbar-default-primary'>
            <Nav className='navbar-nav-hover align-items-lg-center text-primary fw-bold h5'>
              <Nav.Link
                as={Link}
                to={Routes.Home.path}
                className='d-sm-none d-xl-inline text-primary'
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={Routes.Incidents.path}
                className='text-primary'
              >
                Incidents
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={Routes.Map.path}
                className='text-primary'
              >
                Map
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={Routes.Hotspots.path}
                className='text-primary'
              >
                Hotspots
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={Routes.Reports.path}
                className='text-primary'
              >
                Reports
              </Nav.Link>
              <Nav.Link
                as={Link}
                to={Routes.Sentiment.path}
                className='text-primary'
              >
                Portal
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Col>
      <Col
        xs={12}
        md={4}
        className='align-items-center text-xs-center text-end'
      >
        <Nav className='align-items-center justify-content-end justify-content-xs-around'>
          <Link to={Routes.Home.path}>
            <Image
              src={logo_180}
              className='headerImage'
            />
          </Link>
          <NavProfile Header />
        </Nav>
      </Col>
    </Row>
  );
};

export default Header;
