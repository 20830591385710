const getToken = (lengthBeforeToken) => {
    const urlHash = window.location.hash
    let params = urlHash.substr(lengthBeforeToken).split('&').reduce(function (res, item) {
        var parts = item.split('=');
        res[parts[0]] = parts[1];
        return res;
    }, {});
    return params.token
}

export default getToken;
