import React from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import { Chartlist } from '.';

const ChartlistWithInfo = ({ data, hideActiveShape }) => {
  return (
    <>
      <Row className='my-4'>
        <h5 className='text-black text-center mb-3'>Introduction</h5>
        <p>{data.introduction}</p>
      </Row>
      {data.dictionary && (
        <Row className='my-4'>
          <h5 className='text-center'>Keywords</h5>
          {data.dictionary.map((item, index) => (
            <Row
              key={`${item.word}-${index}`}
              className='align-items-center justify-content-between'
            >
              <Col>
                <p>
                  <strong>{item.word}</strong>: {item.meaning}
                </p>
              </Col>
            </Row>
          ))}
        </Row>
      )}
      <Row className='my-4'>
        <Chartlist
          data={data}
          hideActiveShape={hideActiveShape}
        />
      </Row>
      <Row className='my-4'>
        <h5 className='text-black text-center mb-3'>Conclusion</h5>
        <p>{data.conclusion}</p>
      </Row>
      {data.additionalInformation && (
        <Row className='my-4'>
          <h5 className='text-black text-center mb-3'>
            Additional Information
          </h5>
          <p>{data.additionalInformation}</p>
        </Row>
      )}
    </>
  );
};

export default ChartlistWithInfo;
