import { isFulfilled, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { formatErrorMessage } from '../utils';

// eslint-disable-next-line import/prefer-default-export
export const rtkQueryErrorLogger = () => (next) => (action) => {
  const TOAST_QUERY_ID = 'query-status';
  if (isPending(action)) {
    toast('Please wait...', {
      isLoading: true,
      type: toast.TYPE.DEFAULT,
      toastId: TOAST_QUERY_ID,
    });
  } else if (isFulfilled(action)) {
    if (action.meta.arg.type === 'mutation') {
      toast.update(TOAST_QUERY_ID, {
        render: 'Done',
        isLoading: false,
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      setTimeout(() => {
        toast.dismiss(TOAST_QUERY_ID);
      }, 3000);
    } else {
      toast.update(TOAST_QUERY_ID, {
        isLoading: false,
        type: toast.TYPE.SUCCESS,
        autoClose: 1000,
      });
      setTimeout(() => {
        toast.dismiss(TOAST_QUERY_ID);
      }, 1000);
    }
  } else if (isRejectedWithValue(action)) {
    toast.update(TOAST_QUERY_ID, {
      render: formatErrorMessage(action.payload.data),
      isLoading: false,
      type: toast.TYPE.ERROR,
    });
    setTimeout(() => {
      toast.dismiss(TOAST_QUERY_ID);
    }, 1000);
  }

  return next(action);
};
