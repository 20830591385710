import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { Card } from '@themesberg/react-bootstrap';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Loader } from '../common';
import { icon } from 'leaflet';
import { useSelector } from 'react-redux';
import {
  SelectFlattenedIncidentsByDateRange,
  useLazyGetIncidentsQuery,
} from '../../features/incidents/incidents.api';
import { Link } from 'react-router-dom';
import { Routes } from '../../config';
import config from '../../config/config';
import { CombinedFilters } from '../filters';
import { formatDate, selectIcon } from '../../utils/reusableFunctions';

const mapConfig = {
  maxZoom: 18,
  id: 'mapbox/light-v10',
  url: `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${config.mapBoxToken}`,
  attribution:
    'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
  accessToken: config.mapBoxToken,
};

const MapWithFilters = () => {
  const [getIncidents] = useLazyGetIncidentsQuery();
  const filteredIncidents = useSelector(SelectFlattenedIncidentsByDateRange);

  useEffect(() => {
    if (filteredIncidents.length === 0) getIncidents();
  }, [filteredIncidents.length, getIncidents]);

  return (
    <Loader loaded={!!filteredIncidents.length}>
      <Card>
        <div className='py-xs-3 py-3'>
          <CombinedFilters />
        </div>
        <MapContainer
          id='mapbox'
          center={[6.2245, 10.676]}
          zoom={8}
        >
          <TileLayer {...mapConfig} />

          {filteredIncidents.map((incident) => (
            <Marker
              key={`map-marker-${incident.id}`}
              position={[
                incident.location.coordinates[1],
                incident.location.coordinates[0],
              ]}
              icon={icon({
                iconUrl: selectIcon(incident.category),
                iconSize: [24, 24], // size of the icon
                shadowSize: [24, 24], // size of the shadow
                iconAnchor: [12, 24], // point of the icon which will correspond to marker's location
                shadowAnchor: [12, 24], // the same for the shadow
                popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
              })}
            >
              <Popup>
                <Link to={`${Routes.Incidents.path}/${incident.id}`}>
                  <Card className='card-article-wide border-0 flex-column no-gutters no-hover'>
                    <Card.Body className='d-flex flex-column justify-content-between col-12 py-0'>
                      <h5 className='fw-normal mb-2'>{incident.title}</h5>
                      <div className='d-flex'>
                        <div className='icon icon-xs icon-tertiary me-2'>
                          <FontAwesomeIcon icon={faClock} />
                        </div>
                        <div className='font-xs text-dark'>
                          {formatDate(incident.incidentDate, ' ')}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Link>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      </Card>
    </Loader>
  );
};

export default MapWithFilters;
