import { api } from '../../app';

const apiWithCampaignTags = api.enhanceEndpoints({ addTagTypes: ['Campaign'] });

const campaignApi = apiWithCampaignTags.injectEndpoints({
  endpoints: (builder) => ({
    createCampaign: builder.mutation({
      query: (body) => ({
        url: 'campaigns',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Campaign'],
    }),
    getCampaigns: builder.query({
      query: (params) => ({
        url: 'campaigns',
        method: 'GET',
        params,
      }),
      providesTags: (data) =>
        data && data.results
          ? [
              ...data.results.map(({ id }) => ({ type: 'Campaign', id })),
              { type: 'Campaign', id: 'PARTIAL-CAMPAIGN-LIST' },
            ]
          : [{ type: 'Campaign', id: 'PARTIAL-CAMPAIGN-LIST' }],
    }),
    getSingleCampaign: builder.query({
      query: ({ id }) => ({
        url: `campaigns/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'Campaign', id: result.id }] : ['Campaign'],
    }),
    updateCampaign: builder.mutation({
      query: ({ id, body }) => ({
        url: `campaigns/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Campaign', id: arg.id },
        { type: 'Campaign', id: 'PARTIAL-CAMPAIGN-LIST' },
      ],
    }),
    deleteCampaign: builder.mutation({
      query: ({ id }) => ({
        url: `campaigns/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Campaign', id: arg.id },
        { type: 'Campaign', id: 'PARTIAL-CAMPAIGN-LIST' },
      ],
    }),
  }),
});

export const {
  useCreateCampaignMutation,
  useDeleteCampaignMutation,
  useGetCampaignsQuery,
  useGetSingleCampaignQuery,
  useUpdateCampaignMutation,
} = campaignApi;
export default campaignApi;
