import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from '@themesberg/react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CustomToast, Loader } from '../../components/common';
import { Routes } from '../../config/routes';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { formatDate } from '../../utils/reusableFunctions';
import {
  useGetUsersQuery,
  SelectAdvancedUsers,
} from '../../features/users/users.api';
import { useCreateReportMutation } from '../../features/reports/reports.api';

const NewReport = () => {
  let history = useHistory();
  const [createReport, { isLoading: creating }] = useCreateReportMutation();
  const { users } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => ({
      users: data ? data.results : [],
    }),
  });
  const authors = useSelector(SelectAdvancedUsers);

  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [pdf, setPdf] = useState('');
  const [featuredImage, setFeaturedImage] = useState('');
  const [datePublished, setDatePublished] = useState(new Date());

  async function handleCreateReport(e) {
    e.stopPropagation();
    e.preventDefault();

    if (title === '' || author === '' || pdf === '' || featuredImage === '') {
      toast.error('Please fill in all the fields');
    } else {
      const body = { title, author, pdf, featuredImage, datePublished };
      await createReport(body);
      history.push(Routes.ReportsTable.path);
    }
  }

  return (
    <Loader loaded={!!users.length && !creating}>
      <CustomToast />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Body>
          <h5 className='mb-4'>Add Report</h5>
          <Form>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='title'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Enter the report title'
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='author'>
                  <Form.Label>Author</Form.Label>
                  <Form.Select onChange={(e) => setAuthor(e.target.value)}>
                    {authors.map((author) => (
                      <option value={author.id}>{author.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center mb-3'>
              <Col md={6}>
                <Form.Group id='datePublished'>
                  <Form.Label>Date Published</Form.Label>
                  <Datetime
                    timeFormat={false}
                    onChange={setDatePublished}
                    initialValue={formatDate(Date.now(), '/')}
                    renderInput={(props, openCalendar) => (
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </InputGroup.Text>
                        <Form.Control
                          required
                          type='text'
                          value={formatDate(datePublished, '/')}
                          placeholder='mm/dd/yyyy'
                          onFocus={openCalendar}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group id='pdf'>
                  <Form.Label>PDF Link</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='www.reportone.com'
                    onChange={(e) => setPdf(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center mb-3'>
              <Form.Group id='pdf'>
                <Form.Label>Featured Image Link</Form.Label>
                <Form.Control
                  required
                  type='text'
                  placeholder='www.featuredImage.com'
                  onChange={(e) => setFeaturedImage(e.target.value)}
                />
              </Form.Group>
            </Row>
            <div className='mt-3'>
              <Button
                variant='primary'
                type='submit'
                onClick={handleCreateReport}
              >
                Create
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Loader>
  );
};

export default NewReport;
