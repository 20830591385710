import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Routes } from './routes';
import { useGetSingleUserQuery } from '../features/users/users.api';
import { CustomToast, Loader } from '../components/common';

const ProtectedRouteWithLoader = ({
  component: Component,
  restrictedTo,
  ...rest
}) => {
  const id = localStorage.getItem('userId');
  const { data: user, isFetching } = useGetSingleUserQuery({ id });

  if (isFetching) return <Loader />;

  if (!isFetching && user === undefined)
    return <Redirect to={Routes.Signin.path} />;

  if (!isFetching && user !== undefined && !restrictedTo.includes(user.role)) {
    return <Redirect to={Routes.Forbidden.path} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <main className='bg-white'>
          <CustomToast />
          <Component {...props} />
        </main>
      )}
    />
  );
};

export default ProtectedRouteWithLoader;
