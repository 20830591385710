import { likertScale, inverseLikertScale, colors } from './constants';

export const formatLabel = (category) => {
  const isLikert = !!likertScale[category];
  if (isLikert) {
    return likertScale[category].label;
  }
  return category;
};

export const assignColor = (category, index, inverseLikert) => {
  const isLikert = !!likertScale[category];
  if (isLikert && inverseLikert) {
    return inverseLikertScale[category].color;
  }
  if (isLikert) {
    return likertScale[category].color;
  }
  return colors[index % colors.length];
};
