import {
  ListGroup,
  Image,
  Button,
  Row,
  Col,
} from '@themesberg/react-bootstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config/routes';
import { useGetReportsQuery } from '../../features/reports/reports.api';

const ReportImageList = () => {
  const { reports } = useGetReportsQuery(undefined, {
    selectFromResult: ({ data }) => ({
      reports: data ? data.results : [],
    }),
  });

  const reportList = reports.slice(0, 5);
  const Report = (props) => {
    const { featuredImage, id } = props;
    return (
      <ListGroup.Item className='p-3 mb-3 bg-quaternary zoom'>
        <Link to={`${Routes.Reports.path}/${id}`}>
          <Image src={featuredImage} />
        </Link>
      </ListGroup.Item>
    );
  };
  return (
    <Loader loaded={!!reports.length}>
      <ListGroup
        horizontal={'lg'}
        className='justify-content-around align-items-center mb-0'
      >
        {reportList.map((i) => (
          <Report
            key={i.id}
            {...i}
          />
        ))}
      </ListGroup>
      <Row
        className='justify-content-center align-items-center'
        noGutters
      >
        <Col className='text-center'>
          <Button
            as={Link}
            to={Routes.Reports.path}
            variant='outline-primary'
            className='ms-3 align-self-center'
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              className='me-1'
            />
            View All
          </Button>
        </Col>
      </Row>
    </Loader>
  );
};

export default ReportImageList;
