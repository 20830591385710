import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import styles from './styles';

const ChartSection = ({ chart, label, description }) => {
  return (
    <Page
      size='A4'
      style={styles.page}
    >
      <View style={styles.section}>
        <Text style={styles.title}>{label}</Text>
      </View>
      <View style={styles.centerSection}>
        <Image
          src={chart}
          style={styles.wrappedImage}
        />
      </View>
      <View style={styles.section}>
        <Text style={styles.text}>{description}</Text>
      </View>
    </Page>
  );
};

export default ChartSection;
