import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Card, Form, Button } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import { CustomToast, Loader } from '../../components/common';
import { Routes } from '../../config/routes';
import { useCreateRegionMutation } from '../../features/regions/regions.api';

const NewRegion = () => {
  let history = useHistory();
  const [createRegion, { isLoading: creating }] = useCreateRegionMutation();

  const [name, setName] = useState('');
  const [type, setType] = useState('Feature');
  const [id, setId] = useState('');
  const [incidenceLevel, setIncidenceLevel] = useState('average');

  async function handleCreateRegion(e) {
    e.stopPropagation();
    e.preventDefault();

    if (name === '' || type === '' || id === '' || incidenceLevel === '') {
      toast.error('Please fill in all the fields');
    } else {
      const body = {
        _id: id,
        type,
        properties: {
          name,
          incidenceLevel,
        },
      };

      await createRegion(body);
      history.push(Routes.RegionsTable.path);
    }
  }

  return (
    <Loader loaded={!creating}>
      <CustomToast />
      <Card
        border='light'
        className='bg-white shadow-sm mb-4'
      >
        <Card.Body>
          <h5 className='mb-4'>Add Region</h5>
          <Form>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Enter region name'
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='_id'>
                  <Form.Label>ID</Form.Label>
                  <Form.Control
                    required
                    type='text'
                    placeholder='Enter region OSM ID e.g. R123456'
                    onChange={(e) => setId(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='align-items-center'>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='type'>
                  <Form.Label>Type</Form.Label>
                  <Form.Select
                    onChange={(e) => setType(e.target.value)}
                    defaultValue='Feature'
                  >
                    <option value='Feature'>Feature</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='incidenceLevel'>
                  <Form.Label>Incidence Level</Form.Label>
                  <Form.Select
                    onChange={(e) => setIncidenceLevel(e.target.value)}
                    defaultValue='average'
                  >
                    <option value='very high'>Very High</option>
                    <option value='high'>High</option>
                    <option value='average'>Average</option>
                    <option value='low'>Low</option>
                    <option value='very low'>Very Low</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <div className='mt-3'>
              <Button
                variant='primary'
                type='submit'
                onClick={handleCreateRegion}
              >
                Create
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Loader>
  );
};

export default NewRegion;
