import React from 'react';
import { Navbar, Nav, Col, Row } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { Routes } from '../../config';

const HeaderAlt = () => {
  return (
    <header
      className='px-6 bg-tertiary sm-p-1'
      style={{
        top: 0,
        left: 0,
        position: 'fixed',
        width: '100%',
        zIndex: 99,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Row className='align-items-center justify-content-between'>
        <Col
          xs={8}
          className='align-items-center'
        >
          <Navbar
            variant='light'
            expand='lg'
            className='navbar-transparent navbar-theme-primary sticky-top bg-tertiary'
          >
            <Navbar.Toggle aria-controls='navbar-default-primary' />
            <Navbar.Collapse id='navbar-default-primary'>
              <Nav className='navbar-nav-hover align-items-lg-center text-primary fw-bold h5'>
                <Nav.Link
                  as={Link}
                  to={Routes.Sentiment.path}
                  className='d-sm-none d-xl-inline text-primary'
                >
                  Sentiment Analysis
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to={Routes.SingleSummary.path}
                  className='d-sm-none d-xl-inline text-primary'
                >
                  Monitoring Reports
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to={Routes.Campaigns.path}
                  className='d-sm-none d-xl-inline text-primary'
                >
                  Campaigns
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
        <Col
          xs={4}
          className='align-items-center text-xs-center text-end'
        >
          <Nav className='align-items-center justify-content-end justify-content-xs-around text-primary fw-bold h5'>
            <Nav.Link
              as={Link}
              to={Routes.Home.path}
              className='d-sm-none d-xl-inline text-primary'
            >
              Login
            </Nav.Link>
          </Nav>
        </Col>
      </Row>
    </header>
  );
};

export default HeaderAlt;
