import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Modal,
  Button,
  Card,
  Form,
  Row,
  Col,
} from '@themesberg/react-bootstrap';
import {
  useDeleteCampaignMutation,
  useGetSingleCampaignQuery,
  useUpdateCampaignMutation,
} from '../../features/frames/campaigns.api';
import { deleteEmptyStringProps } from '../../utils/reusableFunctions';
import { uploadToCloudinary } from '../../utils';
import { Routes } from '../../config';
import { Loader } from '../../components/common';

const UpdateCampaign = () => {
  const { id } = useParams();
  const { data: campaign } = useGetSingleCampaignQuery({ id });
  const [updateCampaign] = useUpdateCampaignMutation();
  const [deleteCampaign] = useDeleteCampaignMutation();
  let history = useHistory();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleUpdateCampaign(e) {
    e.stopPropagation();
    e.preventDefault();

    if (uploadedImage) {
      uploadToCloudinary(uploadedImage).then(async (banner) => {
        const body = deleteEmptyStringProps({ title, description, banner });
        await updateCampaign({ id, body });
      });
    }
  }

  async function handleDeleteCampaign() {
    handleClose();
    history.push(Routes.CampaignList.path);
    await deleteCampaign({ id });
  }

  const CheckDelete = () => (
    <Modal
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Are you sure you want to delete {campaign.title}?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Deleting a campaign is an irreversible process. You cannot recover the
        information once a campaign is deleted
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-between'>
        <Button
          variant='secondary'
          size='sm'
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          variant='danger'
          size='sm'
          onClick={handleDeleteCampaign}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (!campaign) return <Loader />;

  return (
    <Card
      border='light'
      className='bg-white shadow-sm mb-4 text-black'
    >
      <CheckDelete />
      <Card.Body>
        <h5 className='mb-4'>Update campaign</h5>
        <Form>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <label
                htmlFor='frame'
                className='rounded w-100 position-relative '
                style={{
                  display: 'block',
                  zIndex: 0,
                  height: 400,
                  cursor: 'pointer',
                  border: '1px dashed black',
                }}
              >
                <input
                  id='cover'
                  type='file'
                  name='cover'
                  className='h-100 w-100 position-absolute'
                  onChange={(e) => {
                    if (e.target.files) {
                      setUploadedImage(e.target.files[0]);
                    }
                  }}
                  style={{
                    top: 0,
                    left: 0,
                    opacity: 0,
                    cursor: 'pointer',
                    zIndex: 15,
                  }}
                />
                <div
                  className='align-items-center justify-content-between px-5 position-absolute'
                  style={{ inset: 0, display: 'flex', zIndex: 10 }}
                >
                  <div
                    className='align-items-center justify-content-center w-100 h-75'
                    style={{ display: 'flex', gap: '1.25rem' }}
                  >
                    {uploadedImage ? (
                      <div
                        className='h-100 w-50 rounded justify-content-center align-items-center bg-white'
                        style={{ display: 'flex' }}
                      >
                        <img
                          src={URL.createObjectURL(uploadedImage)}
                          alt='frame'
                          className='h-100 rounded'
                        />
                      </div>
                    ) : (
                      <div
                        className='h-100 w-50 rounded justify-content-center align-items-center bg-white'
                        style={{ display: 'flex' }}
                      >
                        <img
                          src={campaign.banner}
                          alt='frame'
                          className='h-100 rounded'
                        />
                      </div>
                    )}
                  </div>
                </div>
              </label>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <Form.Group id='title'>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue={campaign.title}
                  placeholder='Enter category title'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center'>
            <Col className='mb-3'>
              <Form.Group id='description'>
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as='textarea'
                  placeholder='Describe the campaign'
                  defaultValue={campaign.description}
                  rows={3}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-between'>
            <Col className='text-start'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='danger'
                type='button'
                onClick={handleShow}
              >
                Delete
              </Button>
            </Col>
            <Col className='ps-md-0 text-end'>
              <Button
                className='mt-3 me-2'
                size='sm'
                variant='primary'
                type='submit'
                onClick={handleUpdateCampaign}
              >
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default UpdateCampaign;
