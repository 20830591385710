import React, { useState } from 'react';
import { Col, Row, Form, Button } from '@themesberg/react-bootstrap';
import WizardFooter from './WizardFooter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

const DictionaryStep = ({
  dictionary,
  updateDictionary,
  removeFromDictionary,
  previousStep,
  nextStep,
  step,
  totalSteps,
  setIsLastStep,
}) => {
  const [word, setWord] = useState('');
  const [meaning, setMeaning] = useState('');

  const validate = () => {
    setIsLastStep(step === totalSteps - 1);
    nextStep();
  };

  const reset = () => {
    setWord('');
    setMeaning('');
  };

  return (
    <div className='my-4 bg-white'>
      <h6>Dictionary</h6>
      {dictionary.length > 0 && (
        <div className='mb-3'>
          {dictionary.map((item, index) => (
            <Row
              key={`${item.word}-${index}`}
              className='align-items-center justify-content-between'
            >
              <Col xs='10'>
                <p className='text-black'>
                  <strong>{item.word}</strong>: {item.meaning}
                </p>
              </Col>
              <Col xs='2'>
                <Button
                  size='sm'
                  variant='outline-danger'
                >
                  <FontAwesomeIcon
                    icon={faTrash}
                    onClick={() => removeFromDictionary(item.word)}
                  />
                </Button>
              </Col>
            </Row>
          ))}
        </div>
      )}
      <Row className='align-items-center'>
        <Col className='mb-3'>
          <Form.Group id='word'>
            <Form.Label>Word</Form.Label>
            <Form.Control
              type='text'
              value={word}
              placeholder='Custom word to be defined for your report'
              onChange={(e) => setWord(e.target.value)}
              className='text-black'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className='align-items-center'>
        <Col className='mb-3'>
          <Form.Group id='meaning'>
            <Form.Label>Meaning</Form.Label>
            <Form.Control
              as='textarea'
              value={meaning}
              placeholder='Meaning of the word'
              rows={3}
              onChange={(e) => setMeaning(e.target.value)}
              className='text-black'
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col className='mb-3 text-start'>
          <Button
            variant='primary'
            size='sm'
            onClick={() => {
              reset();
              updateDictionary(word, meaning);
            }}
          >
            Add to Dictionary
            <FontAwesomeIcon
              icon={faPlus}
              className='ms-2'
            />
          </Button>
        </Col>
      </Row>
      <WizardFooter
        previousStep={previousStep}
        nextStep={validate}
        step={step}
        totalSteps={totalSteps}
      />
    </div>
  );
};

export default DictionaryStep;
