import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faFilter } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Card,
  Button,
  Table,
  Form,
  Accordion,
} from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  nameFilterChanged,
  selectApprovedFilter,
  selectIsEmailVerifiedFilter,
  selectNameFilter,
  selectRoleFilter,
  roleFilterChanged,
  isEmailVerifiedFilterChanged,
  approvedFilterChanged,
} from '../../features/users/users.filter.slice';
import { CustomToast, Loader, MyPagination } from '../../components/common';
import { Link } from 'react-router-dom';
import { Routes } from '../../config/routes';
import { paginateList } from '../../utils/reusableFunctions';
import { useGetUsersQuery } from '../../features/users/users.api';

const UsersTable = () => {
  const dispatch = useDispatch();
  const { users } = useGetUsersQuery(undefined, {
    selectFromResult: ({ data }) => ({
      users: data ? data.results : [],
    }),
  });
  const nameFilter = useSelector(selectNameFilter);
  const roleFilter = useSelector(selectRoleFilter);
  const isEmailVerifiedFilter = useSelector(selectIsEmailVerifiedFilter);
  const approvedFilter = useSelector(selectApprovedFilter);
  const usersPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  let userList = paginateList(users, currentPage, usersPerPage);

  const handlePageClicked = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(users.length / usersPerPage);

  const FilterAccordion = () => (
    <Accordion>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          <FontAwesomeIcon
            icon={faFilter}
            className='me-2'
          />
        </Accordion.Header>
        <Accordion.Body>
          <Form>
            <Row>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={nameFilter}
                    placeholder='Enter name'
                    onChange={(e) =>
                      dispatch(nameFilterChanged(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='role'>
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    defaultValue={roleFilter}
                    onChange={(e) =>
                      dispatch(roleFilterChanged(e.target.value))
                    }
                  >
                    <option value='All'>All</option>
                    <option value='user'>User</option>
                    <option value='author'>Author</option>
                    <option value='editor'>Editor</option>
                    <option value='admin'>Admin</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='emailVerified'>
                  <Form.Label>Email Verified?</Form.Label>
                  <Form.Select
                    defaultValue={isEmailVerifiedFilter}
                    onChange={(e) =>
                      dispatch(isEmailVerifiedFilterChanged(e.target.value))
                    }
                  >
                    <option value='All'>All</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col
                md={6}
                className='mb-3'
              >
                <Form.Group id='approved'>
                  <Form.Label>Approved?</Form.Label>
                  <Form.Select
                    defaultValue={approvedFilter}
                    onChange={(e) =>
                      dispatch(approvedFilterChanged(e.target.value))
                    }
                  >
                    <option value='All'>All</option>
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {/* <div className="mt-3">
                            <Button variant="primary" type="submit" onClick={handleFilter}>Filter</Button>
                        </div> */}
          </Form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );

  const Header = () => (
    <div className='table-settings mb-4 mt-4'>
      <Row className='justify-content-between align-items-center'>
        <Col>
          <FilterAccordion />
        </Col>
        <Col className='ps-md-0 text-end'>
          <Button
            variant='primary'
            size='sm'
            className='me-2'
            as={Link}
            to={Routes.NewUser.path}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className='me-2'
            />
            New User
          </Button>
        </Col>
      </Row>
    </div>
  );

  const TableRow = (props) => {
    const { id, name, email, role, isEmailVerified, approved } = props;
    const isEmailVerifiedVariant =
      isEmailVerified === false ? 'danger' : 'success';
    const approvedVariant = approved === false ? 'danger' : 'success';

    return (
      <>
        <tr>
          <td>
            <span className='fw-normal'>{name}</span>
          </td>
          <td>
            <span className='fw-normal'>{email}</span>
          </td>
          <td>
            <span className='fw-normal'>{role}</span>
          </td>
          <td>
            <span className={`fw-normal text-${isEmailVerifiedVariant}`}>
              {isEmailVerified.toString()}
            </span>
          </td>
          <td>
            <span className={`fw-normal text-${approvedVariant}`}>
              {approved.toString()}
            </span>
          </td>
          <td>
            <Button
              variant='primary'
              type='submit'
              as={Link}
              to={`${Routes.UserEdit.path}/${id}`}
            >
              <FontAwesomeIcon
                icon={faEdit}
                className='me-2'
              />{' '}
              Edit
            </Button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <Loader loaded={!!users.length}>
      <Header />
      <CustomToast />
      <MyPagination
        totPages={totalPages}
        currentPage={currentPage}
        pageClicked={(page) => {
          handlePageClicked(page);
        }}
      >
        <Card
          border='light'
          className='table-wrapper table-responsive shadow-sm'
        >
          <Card.Body className='pt-0'>
            <Table
              hover
              className='user-table align-items-center text-black'
            >
              <thead>
                <tr>
                  <th className='border-bottom'>Name</th>
                  <th className='border-bottom'>Email</th>
                  <th className='border-bottom'>Role</th>
                  <th className='border-bottom'>Email Verified?</th>
                  <th className='border-bottom'>Approved?</th>
                  <th className='border-bottom'>Action</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((r) => (
                  <TableRow
                    key={`user-${r.id}`}
                    {...r}
                  />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </MyPagination>
    </Loader>
  );
};

export default UsersTable;
