import React from 'react';
import { Container, Row, Col, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faHeart, faRetweet } from '@fortawesome/free-solid-svg-icons';

import placeholder from '../assets/img/avatar.png';

const formatDate = (date) => {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dateObj = date ? new Date(date) : new Date();
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth();
    const day = dateObj.getDate();
    return `${months[month]} ${day}, ${year}`;
};

const colors = {
    pink: '#f91880',
    lightBlue: '#1d9bf0',
    grey: '#536471',
};

const Tweet = ({ avatar, username, text, createdAt, likes, retweets, url }) => {

  const goToUser = () => {
    window.open(`https://twitter.com/${username}`, '_blank');
  };
  
  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
        <Container className="bg-white p-2">
            <Row xs="auto" className="justify-content-between align-items-center mb-1">
                <Row xs="auto" className="align-items-end">
                    <Col className='pe-0'>
                        <img width="30px" src={avatar ?? placeholder} alt="avatar" className='rounded-circle' />
                    </Col>
                    <Col>
                        <div className='text-left mb-0' style={{ fontSize: 14, color: colors.grey }}>{username ? <Button variant="link" className="p-0 pb-1 m-0 bg-white" onClick={goToUser}><span style={{ fontSize: 14, color: colors.grey }}>@{username}</span></Button> : 'No username'} . <span className='fw-bold' style={{ color: colors.grey }}>{formatDate(createdAt)}</span></div>
                    </Col>
                </Row>
                <Col>
                    <FontAwesomeIcon icon={faTwitter} color={colors.lightBlue} />
                </Col>
            </Row>
            <Row className='mb-0'>
                <p className="mb-1 text-black" style={{ fontSize: 14 }}>{text}</p>
            </Row>
            <Row xs="auto" className="mb-1 justify-content-start align-items-center">
                <Col>
                    <FontAwesomeIcon icon={faHeart} color={colors.pink} />
                    {likes > 0 && (<span style={{ fontSize: 14, color: colors.grey }}> {likes}</span>)} 
                </Col>
                <Col>
                    <FontAwesomeIcon icon={faRetweet} color={colors.lightBlue} />
                    {retweets > 0 && (<span style={{ fontSize: 14, color: colors.grey }}> {retweets}</span>)}
                </Col>
            </Row>
            <hr className='mt-3 mb-1' />
        </Container>
    </a>
  )  
}

export default Tweet