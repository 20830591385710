import React from 'react';
import { Col, Row, Container } from '@themesberg/react-bootstrap';

import { LineChart, UnfilteredIncidentList } from '../../components/incidents';
import { ReportList } from '../../components/reports';

export default () => {
  return (
    <Container className='mx-2'>
      <Row className='my-4'>
        <LineChart />
      </Row>
      <Row className='my-4 justify-content-center'>
        <Col md={6}>
          <UnfilteredIncidentList />
        </Col>
        <Col md={6}>
          <ReportList dashboard />
        </Col>
      </Row>
    </Container>
  );
};
