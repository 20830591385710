import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    ascending: true,
    sortKey: 'id',
}

const userSortingSlice = createSlice({
    name: 'userSorting',
    initialState,
    reducers: {
        sortingOrderChanged(state, action){
            state.ascending = !state.ascending
        },
        sortingKeyChanged(state, action){
            state.sortKey = action.payload
        }
    }
})

export const { sortingOrderChanged, sortingKeyChanged } = userSortingSlice.actions;

export default userSortingSlice.reducer;
