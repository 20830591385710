import { api } from '../../app';

const apiWithThreatTags = api.enhanceEndpoints({ addTagTypes: ['Threat'] });

const threatApi = apiWithThreatTags.injectEndpoints({
  endpoints: (builder) => ({
    createThreat: builder.mutation({
      query: (body) => ({
        url: 'threats',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Threat'],
    }),
    getThreats: builder.query({
      query: (params) => ({
        url: 'threats',
        method: 'GET',
        params,
      }),
      providesTags: (data) =>
        data && data.results
          ? [
              ...data.results.map(({ id }) => ({ type: 'Threat', id })),
              { type: 'Threat', id: 'PARTIAL-THREAT-LIST' },
            ]
          : [{ type: 'Threat', id: 'PARTIAL-THREAT-LIST' }],
    }),
    groupThreats: builder.query({
      query: (params) => ({
        url: 'group',
        method: 'GET',
        params,
      }),
    }),
    getSingleThreat: builder.query({
      query: ({ id }) => ({
        url: `threats/${id}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result ? [{ type: 'Threat', id: result.id }] : ['Threat'],
    }),
    updateThreat: builder.mutation({
      query: ({ id, body }) => ({
        url: `threats/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Threat', id: arg.id },
        { type: 'Threat', id: 'PARTIAL-THREAT-LIST' },
      ],
    }),
    deleteThreat: builder.mutation({
      query: ({ id }) => ({
        url: `threats/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'Threat', id: arg.id },
        { type: 'Threat', id: 'PARTIAL-THREAT-LIST' },
      ],
    }),
  }),
});

export const {
  useCreateThreatMutation,
  useGetThreatsQuery,
  useGetSingleThreatQuery,
  useUpdateThreatMutation,
  useDeleteThreatMutation,
  useLazyGetThreatsQuery,
  useGroupThreatsQuery,
} = threatApi;
export default threatApi;
