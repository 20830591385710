import React, { useState } from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { useGetSummaryByDateQuery } from '../../features/summary/summary.api';
import { Loader } from '../../components/common';
// import { ChartlistWithInfo } from '../../components/summaryCharts';
import { Row, Col, Button } from '@themesberg/react-bootstrap';
import { LayoutAlt } from '../../components/layout';
import { august2023Report, september2023Report } from '../../assets/pdf';

const ThreatSummary = () => {
  const [month, setMonth] = useState(8);
  const [year, setYear] = useState(2023);
  // const [showPDF, setShowPDF] = useState(false);
  // const [hideActiveShape, setHideActiveShape] = useState(false);

  const { data, isLoading } = useGetSummaryByDateQuery({ year, month });

  if (!data && isLoading) return <Loader />;
  return (
    <LayoutAlt>
      {/* <div className='bg-white px-6 py-4 text-black'>
        {!data || data.type.count === 0 ? (
          <p className='text-black'>
            No threats were reported for{' '}
            {moment({ year, month }).format('MMMM YYYY')}
          </p>
        ) : (
          <div className='my-4'>
            <h2 className='mt-5 h4 text-center'>
              Summary Statistics for{' '}
              {moment({ year, month }).format('MMMM YYYY')}
            </h2>
            <Row className='my-4 justify-content-between align-items-center'>
              <Col className='text-start'>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => setShowPDF((prev) => !prev)}
                >
                  {showPDF ? 'Show Charts' : 'Show PDF'}
                </Button>
              </Col>
              <Col>
                <Datetime
                  dateFormat='YYYY-MM'
                  value={moment({ year, month })}
                  timeFormat={false}
                  onChange={(date) => {
                    setMonth(date.month());
                    setYear(date.year());
                  }}
                  renderInput={(props, openCalendar, closeCalendar) => (
                    <Button
                      onClick={openCalendar}
                      className='primary'
                      size='sm'
                    >
                      {`Click to select month and year. Current results are for: ${moment(
                        { year, month }
                      ).format('MMMM YYYY')}`}
                    </Button>
                  )}
                />
              </Col>
              <Col className='text-end'>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => setHideActiveShape((prev) => !prev)}
                >
                  Toggle Active Chart
                </Button>
              </Col>
            </Row>
            {showPDF ? (
              <Row className='my-4'>
                <iframe
                  src={data.pdf}
                  title={`Summary Statistics for ${moment({
                    year,
                    month,
                  }).format('MMMM YYYY')}`}
                  style={{ width: '100%', height: '1000px' }}
                ></iframe>
              </Row>
            ) : (
              <ChartlistWithInfo
                data={data}
                hideActiveShape={hideActiveShape}
              />
            )}
          </div>
        )}
      </div> */}
      <div
        className='bg-white px-6 py-4 text-black sm-px-1 sm-py-1'
        style={{ minHeight: '50vh' }}
      >
        <Row className='my-4 justify-content-start'>
          <Col className='mb-1'>
            <Datetime
              dateFormat='YYYY-MM'
              value={moment({ year, month })}
              timeFormat={false}
              onChange={(date) => {
                setMonth(date.month());
                setYear(date.year());
              }}
              renderInput={(props, openCalendar, closeCalendar) => (
                <Button
                  onClick={openCalendar}
                  className='primary'
                  size='sm'
                >
                  {`Click to select month and year. Current results are for: ${moment(
                    { year, month }
                  ).format('MMMM YYYY')}`}
                </Button>
              )}
            />
          </Col>
        </Row>
        {month === 7 && year === 2023 ? (
          <iframe
            src={august2023Report}
            title={`Summary Statistics for ${moment({
              year,
              month,
            }).format('MMMM YYYY')}`}
            style={{ width: '100%', height: '1000px' }}
          ></iframe>
        ) : month === 8 && year === 2023 ? (
          <iframe
            src={september2023Report}
            title={`Summary Statistics for ${moment({
              year,
              month,
            }).format('MMMM YYYY')}`}
            style={{ width: '100%', height: '1000px' }}
          ></iframe>
        ) : (
          <p className='text-black'>
            No threats were reported for{' '}
            {moment({ year, month }).format('MMMM YYYY')}
          </p>
        )}
      </div>
    </LayoutAlt>
  );
};

export default ThreatSummary;
